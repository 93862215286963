import { printThis } from './main';
import { ajaxPromise, checkChosenChanged, displayNotification, dtColumnIndicies, getDateFilter, htmlToElement, initDateFilter, logerror, toggleSlide } from './utils';

export let historyTable = null;

const initHistoryPageTable = () => {
	const { historyFilters } = JSON.parse(atob($('#history_table').data('js')));

	$('#history_filters_container .chosen').chosen({ width: '100%' });

	toggleSlide($('#history_filters_toggle'), $('#history_filters_container'));
	initDateFilter('#history_filters_date');

	let filtersChangeHandler = true;
	$('#history_filters_container').on('change', () => {
		if (!filtersChangeHandler) return;
		checkFiltersChosenChanged();
		historyTable.ajax.reload();
	});

	$('#history_filters_reset')
		.off('click')
		.on('click', () => {
			filtersChangeHandler = false;
			$('#history_filters_container').trigger('reset');
			$('#history_filters_container .chosen').trigger('change').trigger('chosen:updated');
			checkFiltersChosenChanged();
			historyTable.ajax.reload(null, false);
			filtersChangeHandler = true;
		});

	const checkFiltersChosenChanged = () => $('#history_filters_container select.chosen').each((_, element) => checkChosenChanged($(element)));

	const columns: DataTables.ColumnSettings[] = [
		{
			title: 'User',
			data: 'audit_username',
		},
		...(!historyFilters
			? [
					{
						title: 'Vendor',
						data: 'vend_name',
						render: (val) => (val === null ? '<i>N/A</i>' : val),
					},
			  ]
			: []),
		{
			title: 'Type',
			data: 'audit_type',
		},
		{
			title: 'Action',
			data: 'audit_action',
		},
		{
			title: 'Date',
			data: 'audit_date_nice',
			searchable: false,
		},
		{
			title: 'Actions',
			orderable: false,
			searchable: false,
			data: 'actions',
			defaultContent: '<button class="show-details btn btn-primary btn-sm">Show More</button>',
		},
	];
	const columnIndicies = dtColumnIndicies(columns);
	const tableOptions: DataTables.Settings = {
		ajax: {
			url: '/data/history_load',
			data: (postData: any) => {
				postData.filters = {
					system: $('#history_filters_system').is(':checked') ? 1 : 0,
					user: $('#history_filters_user').val(),
					date: getDateFilter('#history_filters_date'),
					...historyFilters,
				};
				if (!historyFilters || !historyFilters.type) postData.filters.type = $('#history_filters_type').val();
				if (!historyFilters) postData.filters.vendor = $('#history_filters_vendor').val();
				return postData;
			},
		},
		columns,
		columnDefs: [{ responsivePriority: 1, targets: columnIndicies.actions }],
		order: [[columnIndicies.audit_date_nice, 'desc']],
		pageLength: 25,
		responsive: { details: false },
	};

	const theadHtml = `
		<thead>
			<tr>
				${columns.map(({ title }) => `<th>${title}</th>`).join('')}
			</tr>
		</thead>
	`;
	historyTable = $('#history_table').append(theadHtml).DataTable(tableOptions);

	const displayFormat = ({ history, overview, detailsType, detailsHeaders, details, footer }) => {
		const displayDetails = () => {
			switch (detailsType) {
				case 'NORMAL':
					const showOld = details.find((detail) => detail.oldVal !== undefined);
					return (
						<>
							<h4>Details</h4>
							<table className="table-details">
								<thead className="text-info">
									<tr>
										<th>{detailsHeaders[0]}</th>
										<th>{detailsHeaders[1]}</th>
										{showOld ? <th>{detailsHeaders[2]}</th> : ''}
									</tr>
								</thead>
								<tbody>
									{details.map(({ name, newVal, oldVal }) => {
										if (oldVal === null) oldVal = '';
										if (newVal === null) newVal = '';
										return (
											<tr>
												<td>
													<b>{name}</b>
												</td>
												<td className="text-primary">{htmlToElement(newVal)}</td>
												{showOld ? <td className="text-danger">{htmlToElement(oldVal)}</td> : ''}
											</tr>
										);
									})}
								</tbody>
							</table>
							<br />
						</>
					);
				case 'TABLES':
					return (
						<>
							<h4>Details</h4>
							<div>
								{details.map(({ section, table }) => {
									const showOld = table.find((row) => row.oldVal !== undefined);
									return (
										<div>
											{section ? (
												<h5>
													<b>{htmlToElement(section)}</b>
												</h5>
											) : (
												''
											)}
											<table className="table-details">
												<thead className="text-info">
													<tr>
														<th>Field</th>
														<th>New</th>
														{showOld ? <th>Prev</th> : ''}
													</tr>
												</thead>
												<tbody>
													{table.map(({ name, newVal, oldVal }) => {
														if (oldVal === null) oldVal = '';
														if (newVal === null) newVal = '';
														return (
															<tr>
																<td>
																	<b>{htmlToElement(name)}</b>
																</td>
																<td className="text-primary">{htmlToElement(newVal)}</td>
																{showOld ? <td class="text-danger">{htmlToElement(oldVal)}</td> : ''}
															</tr>
														);
													})}
												</tbody>
											</table>
											<br />
										</div>
									);
								})}
							</div>
						</>
					);
				case 'LIST':
					return (
						<>
							<h4>{details.title}</h4>
							<table class="table-details">
								<thead class="text-info">
									<tr>
										<td>
											<b>{htmlToElement(details.header)}</b>
										</td>
									</tr>
								</thead>
								<tbody>
									{details.val.map((val) => (
										<tr>
											<td>{htmlToElement(val)}</td>
										</tr>
									))}
								</tbody>
							</table>
							<br />
						</>
					);
			}
		};
		return (
			<div>
				<span className="pull-right" style="margin-right:5px;">
					<button className="btn btn-xs btn-primary history_print">Print</button>
				</span>
				<h4>Overview</h4>
				<table className="table-details">
					<tbody>
						<tr>
							<td>
								<b>Name</b>
							</td>
							<td>{history.audit_username}</td>
						</tr>
						<tr>
							<td>
								<b>Type</b>
							</td>
							<td>{history.audit_type}</td>
						</tr>
						<tr>
							<td>
								<b>Action</b>
							</td>
							<td>{history.audit_action}</td>
						</tr>
						<tr>
							<td>
								<b>Date</b>
							</td>
							<td>{history.audit_datetime_nice}</td>
						</tr>
						{overview.map(({ name, val }) => (
							<tr>
								<td>
									<b>{name}</b>
								</td>
								<td>{htmlToElement(val)}</td>
							</tr>
						))}
					</tbody>
				</table>
				<br />
				{details && Object.keys(details).length ? displayDetails() : ''}
				{footer ? (
					<>
						<div>{htmlToElement(footer)}</div>
						<br />
					</>
				) : (
					''
				)}
			</div>
		);
	};

	$('#history_table').on('click', '.history_print', ({ target }) => {
		const $tr = $(target).closest('tr');
		const rowData = historyTable.row($tr.prev()).data();

		const config = {
			title: 'History Actions',
			callback: () => {
				const $trClone = $tr.clone();
				$trClone.find('.history_print').remove();
				$trClone.append($('#copyright_footer').clone().css('text-align', 'center'));
				return $trClone;
			},
		};
		printThis(config);

		const postData = {
			type: 'history_print',
			data: { audit_id: rowData.audit_id },
		};
		ajaxPromise('/form/submit', postData).catch((error) => {
			logerror('history print', error);
		});
	});

	$('#history_table tbody').on('click', 'button.show-details', (event) => {
		const $btn = $(event.target);
		const $tr = $btn.closest('tr');
		const row = historyTable.row($tr);
		const history = row.data();
		$btn.text('Show Less');

		if (row.child.isShown()) {
			// This row is already open - close it
			row.child.hide();
			$btn.text('Show More');
			$tr.removeClass('shown');
			return;
		}

		ajaxPromise('/data/history_details_get', { audit_id: history.audit_id })
			.then((res) => {
				const details = displayFormat({ history, ...res });

				// Open this row
				row.child(details).show();
				$tr.addClass('shown');
			})
			.catch((error) => {
				displayNotification('History', 'An error occured while loading audit details', 'danger');
				logerror('audit details error', error);
			});
	});
};

if ($('#history_table').length == 1) initHistoryPageTable();
