import { CONTRACT_STAGE_ACTIVE, CONTRACT_STAGE_CANCELED, CONTRACT_STAGE_EXPIRED, CONTRACT_STAGE_PENDING, CONTRACT_STAGE_RENEWED, CONTRACT_STAGE_TERMINATED, CONTRACT_STATUS_ACTIVE, CONTRACT_STATUS_ARCHIVED, CONTRACT_STATUS_DRAFT, CONTRACT_STATUS_EXPIRED, CONTRACT_STATUS_TERMINATED, CONTRACT_TYPE_PERPETUAL, CONTRACT_TYPE_STANDARD, CONTRACT_TYPE_SUBSCRIPTION, DATATABLE_ALL, DATATABLE_EXPORT, FIELD_COUNTRY, FIELD_CURRENCY, FIELD_DATE, FIELD_NUMBER, FIELD_SELECT, FIELD_STATE, FIELD_TEXT, RATING_SLA, REPORT_TYPE_CONTRACTS, REPORT_TYPE_RELATIONSHIPS, REPORT_TYPE_VENDORS, VENDOR_ACTIVE, VENDOR_ARCHIVED, VENDOR_PENDING, VEND_COMPLETE, VEND_IN_PROGRESS, VEND_NOT_STARTED } from './constants';
import { buildCharts } from './main';
import { ajaxPromise, confirmDialog, displayNotification, getDateFilter, getDtRowData, htmlEsc, logerror, post, setCollapse, setDateFilter, validUrl } from './utils';

const initReporting = () => {
	// Javascript base64 decode string
	const {transVersions, answerRatings, operatorsList, operatorsTimeList, usStates, caStates, countryList} = JSON.parse(atob($('#report_form').data('js')));

	// Reporting
	const reportDt: Record<number, DataTables.Api | null> = {
		[REPORT_TYPE_VENDORS]: null,
		[REPORT_TYPE_RELATIONSHIPS]: null,
		[REPORT_TYPE_CONTRACTS]: null,
	};

	const $reportTables: Record<number, $> = {
		[REPORT_TYPE_VENDORS]: $('#report_table_vendors'),
		[REPORT_TYPE_RELATIONSHIPS]: $('#report_table_relationships'),
		[REPORT_TYPE_CONTRACTS]: $('#report_table_contracts'),
	};
	const getReportType = () => +$('#report_form').find('.tab-pane.active').data('type');

	type columnInfo = DataTables.ColumnSettings & {
		/** The main unique row identifier */
		name: string;

		/** The JQuery checkbox that controls this column's visibility */
		$field?: $;

		/** Optional title override for everything that isn't the datatables */
		displayTitle?: string;

		class?: string;
		colType?: string;
	};
	const columnInfo: Record<number, columnInfo[]> = {
		[REPORT_TYPE_VENDORS]: [
			{
				name: 'vend_name',
				title: 'Name',
				data: 'vend_name',
			},
			{
				name: 'status',
				title: 'Status',
				data: 'nice_vend_status',
				searchable: false,
				$field: $('#report_fields_vendor_status'),
			},
			{
				name: 'category',
				title: 'Category',
				data: 'cat_name',
				searchable: false,
				$field: $('#report_fields_vendor_category'),
			},
			{
				name: 'catstatus',
				title: 'Category Status',
				data: 'nice_cat_status',
				searchable: false,
				$field: $('#report_fields_vendor_category_status'),
			},
			{
				name: 'vendordesc',
				title: 'Description',
				data: 'vend_description',
				$field: $('#report_fields_vendor_description'),
			},
			{
				name: 'idnumber',
				title: 'ID Number',
				data: 'vend_idnumber',
				$field: $('#report_fields_vendor_idnumber'),
			},
			{
				name: 'noncontract',
				title: 'Non-Contract',
				data: 'vend_noncontract',
				searchable: false,
				$field: $('#report_fields_vendor_noncontract'),
			},
			{
				name: 'taxid',
				title: 'Tax ID',
				data: 'vend_taxid',
				$field: $('#report_fields_vendor_taxid'),
			},
			{
				name: 'website',
				title: 'Website',
				data: 'vend_website',
				$field: $('#report_fields_vendor_website'),
				render: (data) => {
					if (validUrl(data)) return `<a href="${data}" target="_blank">${data}</a>`;
					else return data;
				},
			},
			{
				name: 'vendstate',
				title: 'HQ State',
				data: 'vend_state',
				searchable: false,
				$field: $('#report_fields_vendor_state'),
			},
			{
				name: 'vendcountry',
				title: 'Country',
				data: 'vend_country',
				searchable: false,
				$field: $('#report_fields_vendor_country'),
			},
			{
				name: 'office_countries',
				title: 'Office Countries',
				data: 'nice_office_countries',
				searchable: false,
				$field: $('#report_fields_vendor_office_countries'),
			},
			{
				name: 'offices',
				title: 'Office States',
				data: 'nice_offices',
				searchable: false,
				$field: $('#report_fields_vendor_offices'),
			},
			{
				name: 'businessunit',
				title: 'Business Unit',
				data: 'nice_vend_businessunit',
				searchable: false,
				$field: $('#report_fields_vendor_businessunit'),
			},
			{
				name: 'fourthparty',
				title: 'Fourth Party',
				data: 'nice_fourthparty',
				$field: $('#report_fields_vendor_fourthparty'),
			},
			{
				name: 'fourthparty_of',
				title: 'Third Party Of',
				data: 'nice_fourthparty_of',
				$field: $('#report_fields_vendor_fourthparty_of'),
			},
			{
				name: 'attributes',
				title: 'Attributes',
				data: 'nice_vend_attr',
				$field: $('#report_fields_vendor_attributes'),
			},
			{
				name: 'on_status',
				title: 'Onboarding Status',
				data: 'on_status',
				searchable: false,
				$field: $('#report_fields_vendor_on_status'),
			},
			{
				name: 'off_status',
				title: 'Offboarding Status',
				data: 'off_status',
				searchable: false,
				$field: $('#report_fields_vendor_off_status'),
			},
			{
				name: 'num_contracts',
				title: '# of Contracts',
				data: 'num_contracts',
				searchable: false,
				$field: $('#report_fields_vendor_num_contracts'),
			},
			{
				name: 'contact_name',
				title: 'Contact Name',
				data: 'vcontact_name',
				$field: $('#report_fields_vendor_contact_name'),
			},
			{
				name: 'contact_title',
				title: 'Contact Title',
				data: 'vcontact_title',
				$field: $('#report_fields_vendor_contact_title'),
			},
			{
				name: 'contact_phone',
				title: 'Contact Phone',
				data: 'vcontact_phone',
				$field: $('#report_fields_vendor_contact_phone'),
			},
			{
				name: 'contact_email',
				title: 'Contact Email',
				data: 'vcontact_email',
				$field: $('#report_fields_vendor_contact_email'),
			},
			{
				name: 'opscenter_name',
				title: 'Center Name',
				data: 'opscenter_name',
				$field: $('#report_fields_vendor_opscenter_name'),
			},
			{
				name: 'opscenter_desc',
				title: 'Center Description',
				data: 'opscenter_desc',
				$field: $('#report_fields_vendor_opscenter_desc'),
			},
			{
				name: 'opscenter_address1',
				title: 'Center Address 1',
				data: 'opscenter_address1',
				$field: $('#report_fields_vendor_opscenter_address1'),
			},
			{
				name: 'opscenter_address2',
				title: 'Center Address 2',
				data: 'opscenter_address2',
				$field: $('#report_fields_vendor_opscenter_address2'),
			},
			{
				name: 'opscenter_city',
				title: 'Center City',
				data: 'opscenter_city',
				$field: $('#report_fields_vendor_opscenter_city'),
			},
			{
				name: 'opscenter_state',
				title: 'Center State',
				data: 'opscenter_state',
				searchable: false,
				$field: $('#report_fields_vendor_opscenter_state'),
			},
			{
				name: 'opscenter_zip',
				title: 'Center Zip',
				data: 'opscenter_zip',
				$field: $('#report_fields_vendor_opscenter_zip'),
			},
			{
				name: 'opscenter_country',
				title: 'Center Country',
				data: 'opscenter_country',
				searchable: false,
				$field: $('#report_fields_vendor_opscenter_country'),
			},
			{
				name: 'parent',
				title: 'Parent Vendor',
				data: 'parent_vend_name',
				$field: $('#report_fields_vendor_parent'),
			},
			{
				name: 'children',
				title: 'Child Vendors',
				data: 'nice_vend_children',
				$field: $('#report_fields_vendor_children'),
			},
			{
				name: 'relowner_primary',
				title: 'Primary Relationship Owner',
				data: 'nice_vend_relowner_primary',
				$field: $('#report_fields_vendor_relowner_primary'),
			},
			{
				name: 'relowners',
				title: 'Secondary Relationship Owners',
				data: 'nice_vend_relowners',
				$field: $('#report_fields_vendor_relowners'),
			},
			{
				name: 'relowners_analyst',
				title: 'Analyst Relationship Owners',
				data: 'nice_vend_relowners_analyst',
				$field: $('#report_fields_vendor_relowners_analyst'),
			},
			{
				name: 'createdate',
				title: 'Date Added',
				data: 'nice_vend_createdate',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_createdate'),
			},
			{
				name: 'moddate',
				title: 'Modified Date',
				data: 'nice_vend_moddate',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_moddate'),
			},
			{
				name: 'modby',
				title: 'Modified By',
				data: 'nice_vend_modby',
				$field: $('#report_fields_vendor_modby'),
			},
			{
				name: 'archivedate',
				title: 'Archive Date',
				data: 'nice_vend_archivedate',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_archivedate'),
			},
			{
				name: 'progress_vend',
				title: 'Vendor Progress',
				data: 'vend_complete',
				searchable: false,
				$field: $('#report_fields_vendor_progress_vend'),
			},
			{
				name: 'progress_ir',
				title: 'Inherent Risk Progress',
				data: 'vend_complete_ir',
				searchable: false,
				$field: $('#report_fields_vendor_progress_ir'),
			},
			{
				name: 'progress_dd',
				title: 'Due Diligence Progress',
				data: 'vend_complete_dd',
				searchable: false,
				$field: $('#report_fields_vendor_progress_dd'),
			},
			{
				name: 'progress_rd',
				title: 'Required Documents Progress',
				data: 'vend_complete_rd',
				searchable: false,
				$field: $('#report_fields_vendor_progress_rd'),
			},
			{
				name: 'progress_sr',
				title: 'Contract Progress',
				data: 'vend_complete_sr',
				searchable: false,
				$field: $('#report_fields_vendor_progress_sr'),
			},
			{
				name: 'reqdoc_type',
				title: 'Document Type',
				data: 'doct_name',
				searchable: false,
				$field: $('#report_fields_vendor_reqdoc_type'),
			},
			{
				name: 'reqdoc_createdate',
				title: 'Document Last Added Date',
				data: 'doc_createdate_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_reqdoc_createdate'),
			},
			{
				name: 'reqdoc_last',
				title: 'Document Last Effective Date',
				data: 'nice_vreqdoc_lastdate',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_reqdoc_last'),
			},
			{
				name: 'reqdoc_next',
				title: 'Document Next Due Date',
				data: 'nice_vreqdoc_nextdate',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_reqdoc_next'),
			},
			{
				name: 'reqdoc_days',
				title: 'Document # of Days Due',
				data: 'vreqdoc_nextdate_days',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_reqdoc_days'),
			},
			{
				name: 'reqdoc_title',
				title: 'Document Last Added Title',
				data: 'vreqdoc_title',
				$field: $('#report_fields_vendor_reqdoc_title'),
			},
			{
				name: 'reqdoc_exception',
				title: 'Document Exception',
				data: 'vreqdoc_exception',
				searchable: false,
				$field: $('#report_fields_vendor_reqdoc_exception'),
			},
			{
				name: 'critical',
				title: 'Critical',
				data: 'vend_critical',
				searchable: false,
				$field: $('#report_fields_vendor_critical'),
			},
			{
				name: 'inherent',
				title: 'Inherent',
				data: 'inherent_title',
				searchable: false,
				$field: $('#report_fields_vendor_inherent'),
			},
			{
				name: 'residual',
				title: 'Residual',
				data: 'residual_title',
				searchable: false,
				$field: $('#report_fields_vendor_residual'),
			},
			{
				name: 'managedate',
				title: 'Last Assessed',
				data: 'nice_vend_managedate',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_managedate'),
			},
			{
				name: 'lastreview',
				title: 'Last Review',
				data: 'nice_vend_lastreview',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_lastreview'),
			},
			{
				name: 'nextreview',
				title: 'Next Review',
				data: 'nice_vend_nextreview',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_vendor_nextreview'),
			},
			{
				name: 'daystoreview',
				title: 'Days to Next Review',
				data: 'nice_vend_daystonextreview',
				searchable: false,
				$field: $('#report_fields_vendor_daystoreview'),
			},
			{
				name: 'sla',
				title: 'SLA',
				data: 'sla_title',
				searchable: false,
				$field: $('#report_fields_vendor_sla'),
			},
			{
				name: 'kpi',
				title: 'KPI',
				data: 'kpi_title',
				searchable: false,
				$field: $('#report_fields_vendor_kpi'),
			},
			{
				name: 'sla_aggregate',
				title: 'SLA Aggregate',
				data: 'sla_aggregate_title',
				searchable: false,
				$field: $('#report_fields_vendor_sla_aggregate'),
			},
			{
				name: 'kpi_aggregate',
				title: 'KPI Aggregate',
				data: 'kpi_aggregate_title',
				searchable: false,
				$field: $('#report_fields_vendor_kpi_aggregate'),
			},
			{
				name: 'vendvalue',
				title: 'Vendor Value',
				data: 'vendvalue_title',
				searchable: false,
				$field: $('#report_fields_vendor_vendorvalue'),
			},
		],
		[REPORT_TYPE_RELATIONSHIPS]: [
			{
				name: 'vend_name',
				title: 'Vendor',
				data: 'vend_name',
			},
			{
				name: 'vendcat',
				title: 'Category',
				data: 'cat_name',
				$field: $('#report_fields_relations_vendcat'),
			},
			{
				name: 'owner',
				title: 'Owner',
				data: 'username',
			},
			{
				name: 'rank',
				title: 'Relationship',
				data: 'vrel_rank',
				$field: $('#report_fields_relations_rank'),
			},
			{
				name: 'role',
				title: 'User Role',
				data: 'usr_role',
				$field: $('#report_fields_relations_role'),
			},
			{
				name: 'email',
				title: 'Email',
				data: 'usr_email',
				$field: $('#report_fields_relations_email'),
			},
			{
				name: 'phone',
				title: 'Phone',
				data: 'usr_phone',
				$field: $('#report_fields_relations_phone'),
			},
		],
		[REPORT_TYPE_CONTRACTS]: [
			{
				name: 'vendor',
				title: 'Vendor',
				data: 'vend_name',
				$field: $('#report_fields_contract_vend'),
			},
			{
				name: 'vendstatus',
				title: 'Vendor Status',
				data: 'vend_status_nice',
				searchable: false,
				$field: $('#report_fields_contract_vend_status'),
			},
			{
				name: 'vendorcat',
				title: 'Vendor Category',
				data: 'cat_name',
				searchable: false,
				$field: $('#report_fields_contract_vend_cat'),
			},
			{
				name: 'vendordesc',
				title: 'Vendor Description',
				data: 'vend_description',
				$field: $('#report_fields_contract_vend_desc'),
			},
			{
				name: 'idnumber',
				title: 'Vendor ID Number',
				data: 'vend_idnumber',
				$field: $('#report_fields_contract_vend_idnumber'),
			},
			{
				name: 'relowners',
				title: 'Relationship Owners',
				data: 'relowners',
				searchable: false,
				$field: $('#report_fields_contract_relowners'),
			},
			{
				name: 'vendstate',
				title: 'State',
				data: 'vend_state',
				searchable: false,
				$field: $('#report_fields_contract_vend_state'),
			},
			{
				name: 'vendcountry',
				title: 'Country',
				data: 'vend_country',
				searchable: false,
				$field: $('#report_fields_contract_vend_country'),
			},
			{
				name: 'num_contracts',
				title: '# of Contracts',
				data: 'num_contracts',
				searchable: false,
				$field: $('#report_fields_contract_num_contracts'),
			},
			{
				name: 'title',
				title: 'Contract Title',
				data: 'contract_title',
			},
			{
				name: 'contract_type',
				title: 'Contract Type',
				data: 'contract_type_nice',
				searchable: false,
				$field: $('#report_fields_contract_type'),
			},
			{
				name: 'status',
				title: 'Contract Status',
				data: 'contract_status_nice',
				searchable: false,
				$field: $('#report_fields_contract_status'),
			},
			{
				name: 'stage',
				title: 'Contract Stage',
				data: 'contract_stage_nice',
				searchable: false,
				$field: $('#report_fields_contract_stage'),
			},
			{
				name: 'category',
				title: 'Contract Category',
				data: 'service_name',
				searchable: false,
				$field: $('#report_fields_contract_category'),
			},
			{
				name: 'description',
				title: 'Contract Description',
				data: 'contract_description',
				$field: $('#report_fields_contract_description'),
			},
			{
				name: 'refnum',
				title: 'Contract Ref Num',
				data: 'contract_refnum',
				$field: $('#report_fields_contract_refnum'),
			},
			{
				name: 'importance',
				title: 'Importance',
				data: 'weight_title',
				searchable: false,
				$field: $('#report_fields_contract_importance'),
			},
			{
				name: 'costcenter',
				title: 'Cost Center',
				data: 'costcenter_name',
				searchable: false,
				$field: $('#report_fields_contract_costcenter'),
			},
			{
				name: 'annual',
				title: 'Annual Contract Value',
				data: 'contract_annual',
				searchable: false,
				$field: $('#report_fields_contract_annual'),
			},
			{
				name: 'contractowners',
				title: 'Contract Owners',
				data: 'contractowners',
				searchable: false,
				$field: $('#report_fields_contract_contractowners'),
			},
			{
				name: 'cset',
				title: 'Checklist Set',
				data: 'cset',
				searchable: false,
				$field: $('#report_fields_contract_cset'),
			},
			{
				name: 'checklist',
				title: 'Checklist Status',
				data: 'checklist',
				searchable: false,
				$field: $('#report_fields_contract_checklist'),
			},
			{
				name: 'risk_req',
				title: 'Risk Required',
				data: 'risk_req',
				searchable: false,
				$field: $('#report_fields_contract_risk_req'),
			},
			{
				name: 'risk',
				title: 'Risk',
				data: 'risk',
				searchable: false,
				$field: $('#report_fields_contract_risk'),
			},
			{
				name: 'autorenew',
				title: 'Autorenew',
				data: 'contract_autorenew_nice',
				searchable: false,
				$field: $('#report_fields_contract_autorenew'),
			},
			{
				name: 'startdate',
				title: 'Effective',
				data: 'contract_startdate_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_contract_startdate'),
			},
			{
				name: 'daystermination',
				title: 'Days to Termination',
				data: 'contract_daystermination',
				searchable: false,
				$field: $('#report_fields_contract_daystermination'),
			},
			{
				name: 'enddate',
				title: 'Termination',
				data: 'contract_enddate_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_contract_enddate'),
			},
			{
				name: 'termcancel',
				title: 'Contract Terms to Cancel/Renew',
				data: 'contract_daystocancel',
				searchable: false,
				$field: $('#report_fields_contract_termcancel'),
			},
			{
				name: 'dayscancel',
				title: 'Days left to Cancel/Renew',
				data: 'contract_dayscancel',
				searchable: false,
				$field: $('#report_fields_contract_dayscancel'),
			},
			{
				name: 'deadline',
				title: 'Cancel/Renew Date',
				data: 'contract_canceldate_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_contract_deadlinedate'),
			},
			{
				name: 'notifcanceldays',
				title: 'Extended Termination Days',
				data: 'contract_notif_cancel',
				searchable: false,
				$field: $('#report_fields_contract_notifcanceldays'),
			},
			{
				name: 'notifcanceldate',
				title: 'Extended Termination Date',
				data: 'contract_notif_cancel_date_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_contract_notifcanceldate'),
			},
			{
				name: 'critical',
				title: 'Critical',
				data: 'vend_critical',
				searchable: false,
				$field: $('#report_fields_contract_critical'),
			},
			{
				name: 'inherent',
				title: 'Inherent',
				data: 'inherent_title',
				searchable: false,
				$field: $('#report_fields_contract_inherent'),
			},
			{
				name: 'residual',
				title: 'Residual',
				data: 'residual_title',
				searchable: false,
				$field: $('#report_fields_contract_residual'),
			},
			{
				name: 'lastreview',
				title: 'Last Review',
				data: 'vend_lastreview_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_contract_lastreview'),
			},
			{
				name: 'nextreview',
				title: 'Next Review',
				data: 'vend_nextreview_nice',
				class: 'nowrap',
				searchable: false,
				$field: $('#report_fields_contract_nextreview'),
			},
			{
				name: 'daystoreview',
				title: 'Days to Next Review',
				data: 'daystoreview',
				searchable: false,
				$field: $('#report_fields_contract_daystoreview'),
			},
			{
				name: 'sla',
				title: 'SLA Latest',
				data: 'sla_title',
				searchable: false,
				$field: $('#report_fields_contract_sla'),
			},
			{
				name: 'kpi',
				title: 'KPI Latest',
				data: 'kpi_title',
				searchable: false,
				$field: $('#report_fields_contract_kpi'),
			},
			{
				name: 'sla_aggregate',
				title: 'SLA Aggregate',
				data: 'sla_aggregate_title',
				searchable: false,
				$field: $('#report_fields_contract_sla_aggregate'),
			},
			{
				name: 'kpi_aggregate',
				title: 'KPI Aggregate',
				data: 'kpi_aggregate_title',
				searchable: false,
				$field: $('#report_fields_contract_kpi_aggregate'),
			},
			{
				name: 'vendvalue',
				title: 'Vendor Value',
				data: 'vendvalue_title',
				searchable: false,
				$field: $('#report_fields_contract_vendorvalue'),
			},
		],
	};

	type columnInfoNameLookup = {
		[reportType: number]: {
			[name: string]: columnInfo,
		}
	};
	const getColumnInfoNameLookup = (): columnInfoNameLookup => Object.keys(columnInfo).reduce(
		(obj, type) => ({
			...obj,
			[+type]: columnInfo[+type].reduce((obj, col) => ({
				...obj,
				[col.name]: col,
			}),
			{}),
		}),
		{}
	);
	let columnInfoNameLookup = getColumnInfoNameLookup();
	const columnInfoWasUpdated = () => {
		columnInfoNameLookup = getColumnInfoNameLookup();
	};
	const currentColOrder: Record<number, string[] | null> = { [REPORT_TYPE_VENDORS]: null, [REPORT_TYPE_RELATIONSHIPS]: null, [REPORT_TYPE_CONTRACTS]: null };

	let reportReloadCheck: boolean = false;
	let reportReloadTimeout: ReturnType<typeof setTimeout> | null = null;
	const reportReload = () => {
		const type = getReportType();
		if (reportDt[type] !== null && reportReloadCheck && !loadingBookmark) {
			reportReloadCheck = false;
			reportPreview();
		}
		reportReloadCheck = false;
	};

	const reportReloadPrep = () => {
		reportReloadCheck = true;
		if (reportReloadTimeout !== null) clearTimeout(reportReloadTimeout);
		reportReloadTimeout = setTimeout(reportReload, 1500);
	};

	$('#report_form a[data-toggle="tab"]').on('shown.bs.tab', () => {
		const type = getReportType();
		$('.report_table').hide();
		$reportTables[type].show();
		if (!loadingBookmark && reportDt[type] === null) reportPreview();
	});

	$('#report_form select.chosen').chosen({ width: '100%' });
	$('#report_form').on('change', 'select.chosen', () => {
		if (!loadingBookmark) reportReloadPrep();
	});

	$('#report_form').on('change', '.bootstrap-toggle', () => {
		if (!loadingBookmark) reportReloadPrep();
	});

	$('#header_showrelationships').on('change', () => reportPreview());

	$('#report_filter_contract_annual_first, #report_filter_contract_annual_second').number(true, 2);

	let selectedTransVersion = null;
	const transQuestionMap: Map<HTMLSelectElement, any> = new Map();
	const transCustomColMap: Map<HTMLSelectElement, any> = new Map();
	const setSelectedTransVersion = (reportType, transId) => {
		columnInfo[reportType] = columnInfo[reportType].filter((col) => !['GENERAL', 'SLA', 'CUSTOM'].includes(col.colType));
		$('#report_fields_contract_trans_questions').empty();
		$('#report_fields_contract_trans_custom').empty();
		$('#report_filteron_contract_trans_questions').empty();
		$('#report_filteron_contract_trans_custom').empty();
		$('#report_filteron_contract_trans_date').prop('checked', false);
		$('#report_filter_contract_trans_date_cont').hide();
		$('#report_filter_contract_trans_date').val('-1').trigger('change').trigger('chosen:updated');
		$('#report_filter_contract_trans_questions').empty();
		$('#report_filter_contract_trans_custom').empty();
		transQuestionMap.clear();
		transCustomColMap.clear();

		selectedTransVersion = transId ? transVersions.find((vers) => vers.id === transId) : null;
		if (!selectedTransVersion) {
			$('#report_contract_trans_version').val('-1');
			$('#report_fields_contract_details').hide();
			$('#report_filteron_contract_trans_cont').hide();
			columnInfoWasUpdated();
			return;
		}

		$('#report_contract_trans_version').val(transId);
		$('#report_fields_contract_details').show();
		$('#report_filteron_contract_trans_cont').show();

		columnInfo[reportType].push({
			name: 'trans_date',
			title: 'Transaction Date',
			data: 'trans_date_nice',
			$field: $('#report_fields_contract_trans_date'),
			colType: 'GENERAL',
		});

		selectedTransVersion.qs_data.forEach((question, index) => {
			const name = `_q${index}`;
			const shortDisplayName = `SLA #${index + 1}`;

			const $questionFilterCheckbox = $(
				<p className="filteron_topic">
					<label className="labelcheckbox">
						<input id={`report_filteron_contract${name}`} type="checkbox" />
						<span style="margin-left: 5px;">{shortDisplayName}</span>
					</label>
				</p>
			);
			$('#report_filteron_contract_trans_questions').append($questionFilterCheckbox);

			const $questionFilter = $(
				<div className="form-group" style="display: none;">
					<label for={`report_filter_contract${name}`}>{shortDisplayName}</label>
					<br />
					<select id={`report_filter_contract${name}`} className="chosen form-control" data-placeholder="Choose" multiple>
						<option value="0">No rating</option>
						<option disabled>--</option>
						{answerRatings[RATING_SLA].map((rating) => (
							<option value={rating.id}>{rating.title}</option>
						))}
					</select>
				</div>
			);
			const $questionSelect = $questionFilter.find('select.chosen');
			transQuestionMap.set($questionSelect[0] as HTMLSelectElement, {name, display: shortDisplayName, text: question.text});
			$questionSelect.chosen({ width: '100%' });
			$('#report_filter_contract_trans_questions').append($questionFilter);

			const $questionField = $(
				<p>
					<label className="labelcheckbox">
						<input type="checkbox" checked />
						<span style="margin-left: 5px;">{question.text}</span>
					</label>
				</p>
			);
			const $input = $questionField.find('input');
			columnInfo[reportType].push({
				name,
				displayTitle: shortDisplayName,
				title: `
					<div class="text-nowrap">
						${shortDisplayName}
						<button
							type="button"
							class="btn btn-primary btn-xs header-tooltip"
							data-toggle="tooltip"
							title="${question.text}"
							data-originalTitle="${question.text}"
							data-container="body"
							style="display: inline-block; margin-left: 5px; border-radius: 50%; width: 25.5px;">
							<i class="fa fa-info"></i>
						</button>
					</div>
				`,
				data: name,
				$field: $input,
				searchable: false,
				colType: 'SLA',
			});
			$('#report_fields_contract_trans_questions').append($questionField);
		});

		selectedTransVersion.cols.forEach((col) => {
			const name = `_cc${col.id}`;
			const displayName = col.name;

			const addFilter = col.type != FIELD_TEXT;
			if (addFilter) {
				const $customColFilterCheckbox = $(
					<p className="filteron_topic">
						<label className="labelcheckbox">
							<input id={`report_filteron_contract${name}`} type="checkbox" />
							<span style="margin-left: 5px;">{displayName}</span>
						</label>
					</p>
				);
				$('#report_filteron_contract_trans_custom').append($customColFilterCheckbox);

				let $customColFilter = null;
				switch (+col.type) {
					case FIELD_STATE:
						$customColFilter = $(
							<div className="form-group" style="display: none;">
								<label for={`report_filter_contract${name}`}>{displayName}</label>
								<br />
								<select id={`report_filter_contract${name}`} className="chosen form-control" data-placeholder="Choose" multiple>
									<optgroup label="United States">
										{Object.keys(usStates).map((abbr) => (
											<option value={abbr}>{usStates[abbr]}</option>
										))}
									</optgroup>
									<optgroup label="Canada">
										{Object.keys(caStates).map((abbr) => (
											<option value={abbr}>{caStates[abbr]}</option>
										))}
									</optgroup>
								</select>
							</div>
						);
						break;
					case FIELD_COUNTRY:
						$customColFilter = $(
							<div className="form-group" style="display: none;">
								<label for={`report_filter_contract${name}`}>{displayName}</label>
								<br />
								<select id={`report_filter_contract${name}`} className="chosen form-control" data-placeholder="Choose" multiple>
									{Object.keys(countryList).map((abbr) => (
										<option value={abbr}>{countryList[abbr]}</option>
									))}
								</select>
							</div>
						);
						break;
					case FIELD_SELECT:
						$customColFilter = $(
							<div className="form-group" style="display: none;">
								<label for={`report_filter_contract${name}`}>{displayName}</label>
								<br />
								<select id={`report_filter_contract${name}`} className="chosen form-control" data-placeholder="Choose" multiple>
									{col.options.map((val) => (
										<option value={val}>{val}</option>
									))}
								</select>
							</div>
						);
						break;
					case FIELD_NUMBER:
						$customColFilter = $(
							<div className="form-group" style="display: none;">
								<label for={`report_filter_contract${name}`}>{displayName}</label>
								<br />
								<select id={`report_filter_contract${name}`} className="chosen form-control operator" data-placeholder="Choose">
									<option value="-1" selected>No filter</option>
									<option value="" disabled>--</option>
									{operatorsList.map(({val, display}) => (
										<option value={val}>{display}</option>
									))}
								</select>
								<div className="well well-sm mb-0" style="display: none;">
									<div className="form-group" style="display: none;">
										<div className="input-group">
											<input id={`report_filter_contract${name}_first`} type="number" className="form-control range first" />
										</div>
									</div>
									<div className="form-group mb-0" style="display: none;">
										<div className="input-group">
											<input id={`report_filter_contract${name}_second`} type="number" className="form-control range second" />
										</div>
									</div>
								</div>
							</div>
						);
						break;
					case FIELD_CURRENCY:
						$customColFilter = $(
							<div className="form-group" style="display: none;">
								<label for={`report_filter_contract${name}`}>{displayName}</label>
								<br />
								<select id={`report_filter_contract${name}`} className="chosen form-control operator" data-placeholder="Choose">
									<option value="-1" selected>No filter</option>
									<option value="" disabled>--</option>
									{operatorsList.map(({val, display}) => (
										<option value={val}>{display}</option>
									))}
								</select>
								<div className="well well-sm mb-0" style="display: none;">
									<div className="form-group" style="display: none;">
										<div className="input-group">
											<div className="input-group-addon">$</div>
											<input id={`report_filter_contract${name}_first`} type="number" className="form-control range first" />
										</div>
									</div>
									<div className="form-group mb-0" style="display: none;">
										<div className="input-group">
											<div className="input-group-addon">$</div>
											<input id={`report_filter_contract${name}_second`} type="number" className="form-control range second" />
										</div>
									</div>
								</div>
							</div>
						);
						break;
					case FIELD_DATE:
						$customColFilter = $(
							<div className="form-group" style="display: none;">
								<label for={`report_filter_contract${name}`}>{displayName}</label>
								<br />
								<select id={`report_filter_contract${name}`} className="chosen form-control operator" data-placeholder="Choose">
									<option value="-1" selected>No filter</option>
									<option value="" disabled>--</option>
									{operatorsTimeList.map(({val, display}) => (
										<option value={val}>{display}</option>
									))}
								</select>
								<div className="well well-sm mb-0" style="display: none;">
									<div className="form-group" style="display: none;">
										<input id={`report_filter_contract${name}_first`} type="date" min="1000-01-01" max="9999-12-31" className="form-control range first" />
									</div>
									<div className="form-group mb-0" style="display: none;">
										<input id={`report_filter_contract${name}_second`} type="date" min="1000-01-01" max="9999-12-31" className="form-control range second" />
									</div>
								</div>
							</div>
						);
						break;
				}
				const $customColSelect = $customColFilter.find('select.chosen');
				transCustomColMap.set($customColSelect[0], {type: col.type, name, displayName});
				$customColSelect.chosen({ width: '100%' });
				$('#report_filter_contract_trans_custom').append($customColFilter);
			}

			const $customColField = $(
				<p>
					<label className="labelcheckbox">
						<input type="checkbox" checked />
						<span style="margin-left: 5px;">{displayName}</span>
					</label>
				</p>
			);
			const $input = $customColField.find('input');
			columnInfo[reportType].push({
				name,
				title: displayName,
				data: name,
				searchable: col.type == FIELD_TEXT,
				$field: $input,
				colType: 'CUSTOM',
			});
			$('#report_fields_contract_trans_custom').append($customColField);
		});

		columnInfoWasUpdated();
	};
	$('#report_contract_trans_version').on('change', (event) => {
		const transId = $('#report_contract_trans_version').val();
		setSelectedTransVersion(REPORT_TYPE_CONTRACTS, transId);
		event.stopPropagation();
	});

	type reportFilters = Record<string, any>;
	const getReportFilters = (type: number): reportFilters => {
		const getCommaSeparatedFilter = (target: string) => ({
			value: $(target).val(),
			row_type: $(`${target}_row_type`).is(':checked') ? 1 : 0,
			col_type: $(`${target}_col_type`).is(':checked') ? 1 : 0,
		});

		switch (type) {
			case REPORT_TYPE_VENDORS:
				return {
					category: $('#report_filter_vendor_category').val(),
					status: $('#report_filter_vendor_status').val(),
					catstatus: $('#report_filter_vendor_category_status').val() !== null ? $('#report_filter_vendor_category_status').val() : -1,
					vendname: $('#report_filter_vendor_vendorname').val(),
					fourthparty: getCommaSeparatedFilter('#report_filter_vendor_fourthparty'),
					fourthparty_of: getCommaSeparatedFilter('#report_filter_vendor_fourthparty_of'),
					attributes: getCommaSeparatedFilter('#report_filter_vendor_attributes'),
					on_status: $('#report_filter_vendor_on_status').val(),
					off_status: $('#report_filter_vendor_off_status').val(),
					num_contracts: getDateFilter('#report_filter_vendor_num_contracts'),
					parent: $('#report_filter_vendor_parent').val(),
					children: getCommaSeparatedFilter('#report_filter_vendor_children'),
					relowner_primary: $('#report_filter_vendor_relowner_primary').val(),
					relowners: getCommaSeparatedFilter('#report_filter_vendor_relowners'),
					relowners_analyst: getCommaSeparatedFilter('#report_filter_vendor_relowners_analyst'),
					critical: $('#report_filter_vendor_critical').val() !== null ? $('#report_filter_vendor_critical').val() : -1,
					businessunit: getCommaSeparatedFilter('#report_filter_vendor_businessunit'),
					vendstate: $('#report_filter_vendor_state').val(),
					vendcountry: $('#report_filter_vendor_country').val(),
					office_countries: getCommaSeparatedFilter('#report_filter_vendor_office_countries'),
					offices: getCommaSeparatedFilter('#report_filter_vendor_offices'),
					progress_vend: $('#report_filter_vendor_progress_vend').val() !== null ? $('#report_filter_vendor_progress_vend').val() : -1,
					progress_ir: $('#report_filter_vendor_progress_ir').val() !== null ? $('#report_filter_vendor_progress_ir').val() : -1,
					progress_dd: $('#report_filter_vendor_progress_dd').val() !== null ? $('#report_filter_vendor_progress_dd').val() : -1,
					progress_rd: $('#report_filter_vendor_progress_rd').val() !== null ? $('#report_filter_vendor_progress_rd').val() : -1,
					progress_sr: $('#report_filter_vendor_progress_sr').val() !== null ? $('#report_filter_vendor_progress_sr').val() : -1,
					inherent: $('#report_filter_vendor_inherent').val(),
					residual: $('#report_filter_vendor_residual').val(),
					vendvalue: $('#report_filter_vendor_vendorvalue').val(),
					contact: $('#report_filter_vendor_contact').val(),
					opscenter: $('#report_filter_vendor_opscenter').val(),
					opscenter_state: $('#report_filter_vendor_opscenter_state').val(),
					opscenter_country: $('#report_filter_vendor_opscenter_country').val(),
					noncontract: $('#report_filter_vendor_noncontract').val() !== null ? $('#report_filter_vendor_noncontract').val() : -1,
					sla: $('#report_filter_vendor_sla').val(),
					kpi: $('#report_filter_vendor_kpi').val(),
					sla_aggregate: $('#report_filter_vendor_sla_aggregate').val(),
					kpi_aggregate: $('#report_filter_vendor_kpi_aggregate').val(),
					modby: $('#report_filter_vendor_modby').val(),
					reqdoc: $('#report_filter_vendor_reqdoc').val(),
					reqdoc_type: $('#report_filter_vendor_reqdoc_type').val(),
					reqdoc_exception: $('#report_filter_vendor_reqdoc_exception').val() !== null ? $('#report_filter_vendor_reqdoc_exception').val() : -1,
					printtitle: $('#report_filter_vendor_print_title').val(),
					printcomment: $('#report_filter_vendor_print_comment').val(),
					printcharts: $('#report_filter_vendor_print_charts').val(),
					performance_scope: {
						// to be removed?
						mode: $('#chart_option_scope').val(),
						range: $('#chart_option_range').val(),
					},
					createdate: getDateFilter('#report_filter_vendor_createdate'),
					moddate: getDateFilter('#report_filter_vendor_moddate'),
					managedate: getDateFilter('#report_filter_vendor_managedate'),
					archivedate: getDateFilter('#report_filter_vendor_archivedate'),
					lastreview: getDateFilter('#report_filter_vendor_lastreview'),
					nextreview: getDateFilter('#report_filter_vendor_nextreview'),
					daystoreview: getDateFilter('#report_filter_vendor_daystoreview'),
					reqdoc_createdate: getDateFilter('#report_filter_vendor_reqdoc_createdate'),
					reqdoc_last: getDateFilter('#report_filter_vendor_reqdoc_last'),
					reqdoc_next: getDateFilter('#report_filter_vendor_reqdoc_next'),
					reqdoc_days: getDateFilter('#report_filter_vendor_reqdoc_days'),
				};
			case REPORT_TYPE_RELATIONSHIPS:
				return {
					category: $('#report_filter_relations_categories').val(),
					relowners: $('#report_filter_relations_relowners').val(),
					role: $('#report_filter_relations_role').val() !== '' ? $('#report_filter_relations_role').val() : -1,
					printtitle: $('#report_filter_relations_print_title').val(),
					printcomment: $('#report_filter_relations_print_comment').val(),
				};
			case REPORT_TYPE_CONTRACTS:
				const filters: reportFilters = {
					service: $('#report_filter_contract_service').val(),
					category: $('#report_filter_contract_category').val(),
					contract_type: $('#report_filter_contract_type').val() !== '' ? $('#report_filter_contract_type').val() : -1,
					status: $('#report_filter_contract_status').val(),
					stage: $('#report_filter_contract_stage').val(),
					vendname: $('#report_filter_contract_vendorname').val(),
					vendcat: $('#report_filter_contract_vendcat').val(),
					vendstatus: $('#report_filter_contract_vend_status').val(),
					relowners: getCommaSeparatedFilter('#report_filter_contract_relowners'),
					critical: $('#report_filter_contract_critical').val() !== '' ? $('#report_filter_contract_critical').val() : -1,
					vendstate: $('#report_filter_contract_vendor_state').val(),
					vendcountry: $('#report_filter_contract_vendor_country').val(),
					inherent: $('#report_filter_contract_inherent').val(),
					residual: $('#report_filter_contract_residual').val(),
					vendvalue: $('#report_filter_contract_vendorvalue').val(),
					contractowners: getCommaSeparatedFilter('#report_filter_contract_contractowners'),
					cset: $('#report_filter_contract_cset').val(),
					checklist: $('#report_filter_contract_checklist').val(),
					risk_req: $('#report_filter_contract_risk_req').val() !== '' ? $('#report_filter_contract_risk_req').val() : -1,
					risk: $('#report_filter_contract_risk').val(),
					sla: $('#report_filter_contract_sla').val(),
					kpi: $('#report_filter_contract_kpi').val(),
					sla_aggregate: $('#report_filter_contract_sla_aggregate').val(),
					kpi_aggregate: $('#report_filter_contract_kpi_aggregate').val(),
					importance: $('#report_filter_contract_importance').val(),
					costcenter: $('#report_filter_contract_costcenter').val(),
					autorenew: $('#report_filter_contract_autorenew').val() !== '' ? $('#report_filter_contract_autorenew').val() : -1,
					printtitle: $('#report_filter_contract_print_title').val(),
					printcomment: $('#report_filter_contract_print_comment').val(),
					printcharts: $('#report_filter_contract_print_charts').val(),
					performance_scope: {
						// to be removed?
						mode: $('#chart_option_scope').val(),
						range: $('#chart_option_range').val(),
					},
					num_contracts: getDateFilter('#report_filter_contract_num_contracts'),
					startdate: getDateFilter('#report_filter_contract_startdate'),
					enddate: getDateFilter('#report_filter_contract_enddate'),
					deadline: getDateFilter('#report_filter_contract_deadline'),
					annual: getDateFilter('#report_filter_contract_annual'),
					lastreview: getDateFilter('#report_filter_contract_lastreview'),
					nextreview: getDateFilter('#report_filter_contract_nextreview'),
					daystoreview: getDateFilter('#report_filter_contract_daystoreview'),
					termcancel: getDateFilter('#report_filter_contract_termcancel'),
					dayscancel: getDateFilter('#report_filter_contract_dayscancel'),
					daystermination: getDateFilter('#report_filter_contract_daystermination'),
					notifcanceldays: getDateFilter('#report_filter_contract_notifcanceldays'),
					notifcanceldate: getDateFilter('#report_filter_contract_notifcanceldate'),
				};
				if (selectedTransVersion) {
					filters.trans_date = getDateFilter('#report_filter_contract_trans_date');
					transQuestionMap.forEach((question, questionEl) => {
						filters[question.name] = $(questionEl).val();
					});
					transCustomColMap.forEach((col, selectEl) => {
						switch (+col.type) {
							case FIELD_CURRENCY:
							case FIELD_NUMBER:
							case FIELD_DATE:
								filters[col.name] = getDateFilter(`#${selectEl.id}`);
								break;
							case FIELD_STATE:
							case FIELD_COUNTRY:
							case FIELD_SELECT:
								filters[col.name] = $(`#${selectEl.id}`).val();
								break;
						}
					});
				}
				return filters;
			default:
				return {};
		}
	};

	const getReportFields = (type: number) => {
		const fields: any = {};
		columnInfo[type].forEach((col) => {
			if (col.$field) fields[col.name] = col.$field.is(':checked') ? 1 : 0;
		});
		if (type === REPORT_TYPE_CONTRACTS) {
			const tversId = $('#report_contract_trans_version').val();
			fields.trans_version = tversId == -1 ? null : tversId;
		}
		return fields;
	};

	const listTransVersions: Record<string, string> = {};
	$('#report_contract_trans_version option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listTransVersions[val] = $option.text();
	});

	const listVendors: Record<string, string> = {};
	$('#report_filter_vendor_vendorname option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listVendors[val] = $option.text();
	});

	const listCategories: Record<string, string> = {};
	$('#report_filter_vendor_category option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listCategories[val] = $option.text();
	});

	const listContractCategories: Record<string, string> = {};
	$('#report_filter_contract_category option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listContractCategories[val] = $option.text();
	});

	const listFourthParties: Record<string, string> = {};
	$('#report_filter_vendor_fourthparty option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listFourthParties[val] = $option.text();
	});

	const listAttributes: Record<string, string> = {};
	$('#report_filter_vendor_attributes option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listAttributes[val] = $option.text();
	});

	const listOnboarding: Record<string, string> = {};
	$('#report_filter_vendor_on_status option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listOnboarding[val] = $option.text();
	});

	const listOffboarding: Record<string, string> = {};
	$('#report_filter_vendor_off_status option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listOffboarding[val] = $option.text();
	});

	const listChecklistSets: Record<string, string> = {};
	$('#report_filter_contract_cset option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listChecklistSets[val] = $option.text();
	});

	const listContractChecklist: Record<string, string> = {};
	$('#report_filter_contract_checklist option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listContractChecklist[val] = $option.text();
	});

	const listContractRisk: Record<string, string> = {};
	$('#report_filter_contract_risk option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listContractRisk[val] = $option.text();
	});

	const listParents: Record<string, string> = {};
	$('#report_filter_vendor_parent option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listParents[val] = $option.text();
	});

	const listChildren: Record<string, string> = {};
	$('#report_filter_vendor_children option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listChildren[val] = $option.text();
	});

	const listPrimaryRelowners: Record<string, string> = {};
	$('#report_filter_vendor_relowner_primary option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listPrimaryRelowners[val] = $option.text();
	});

	const listRelowners: Record<string, string> = {};
	$('#report_filter_vendor_relowners option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listRelowners[val] = $option.text();
	});

	const listAnalystRelowners: Record<string, string> = {};
	$('#report_filter_vendor_relowners_analyst option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listAnalystRelowners[val] = $option.text();
	});

	const listBusinessunit: Record<string, string> = {};
	$('#report_filter_vendor_businessunit option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listBusinessunit[val] = $option.text();
	});

	const listStates: Record<string, string> = {};
	$('#report_filter_vendor_state option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listStates[val] = $option.text();
	});

	const listCountries: Record<string, string> = {};
	$('#report_filter_vendor_country option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listCountries[val] = $option.text();
	});

	const listOfficeCountries: Record<string, string> = {};
	$('#report_filter_vendor_office_countries option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listOfficeCountries[val] = $option.text();
	});

	const listOffices: Record<string, string> = {};
	$('#report_filter_vendor_offices option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listOffices[val] = $option.text();
	});

	const listImportances: Record<string, string> = {};
	$('#report_filter_contract_importance option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listImportances[val] = $option.text();
	});

	const listCostcenters: Record<string, string> = {};
	$('#report_filter_contract_costcenter option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listCostcenters[val] = $option.text();
	});

	const listUserRoles: Record<string, string> = {};
	$('#report_filter_relations_role option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listUserRoles[val] = $option.text();
	});

	const listInherentRatings: Record<string, string> = {};
	$('#report_filter_vendor_inherent option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listInherentRatings[val] = $option.text();
	});

	const listResidualRatings: Record<string, string> = {};
	$('#report_filter_vendor_residual option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listResidualRatings[val] = $option.text();
	});

	const listVendorValueRatings: Record<string, string> = {};
	$('#report_filter_vendor_vendorvalue option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listVendorValueRatings[val] = $option.text();
	});

	const listSlaRatings: Record<string, string> = {};
	$('#report_filter_vendor_sla option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listSlaRatings[val] = $option.text();
	});

	const listKpiRatings: Record<string, string> = {};
	$('#report_filter_vendor_kpi option').each((_, element) => {
		const $option = $(element);
		const val = $option.val();
		listKpiRatings[val] = $option.text();
	});

	let loadingBookmark = false;

	setCollapse($('#report_filter_vendor_open'), $('#report_filter_vendor_container'));
	setCollapse($('#report_fields_vendor_open'), $('#report_fields_vendor_container'));
	setCollapse($('#report_filter_relations_open'), $('#report_filter_relations_container'));
	setCollapse($('#report_fields_relations_open'), $('#report_fields_relations_container'));
	setCollapse($('#report_filter_contract_open'), $('#report_filter_contract_container'));
	setCollapse($('#report_fields_contract_open'), $('#report_fields_contract_container'));
	setCollapse($('#report_filter_audit_open'), $('#report_filter_audit_container'));
	setCollapse($('#report_fields_audit_open'), $('#report_fields_audit_container'));

	$('.report_reset').off('click').on('click', async () => {
		const type = getReportType();
		const confirmed = await confirmDialog({
			dialogTitle: 'Report Reset',
			bodyText: 'Are you sure you would like to reset the report and reload?',
			confirmText: 'Reset',
			confirmStyle: 'warning',
		});
		if (!confirmed) return;
		post('/ui/reporting', { start: type });
	});

	$('[id^=report_filter_]').each((_, element) => {
		const $filter = $(element);
		if ($filter.data('default') == undefined) $filter.data('default', $filter.val());
	});

	const filterOnToggle = ($element: $, allowReload: boolean) => {
		const $target = $('#' + $element.attr('id')!.slice(0, 13) + $element.attr('id')!.slice(15));
		const $tp = $target.parent();
		if ($element.is(':checked')) {
			$tp.show();
			$tp.find('.bootstrap-toggle').bootstrapToggle('off');
			if (allowReload) loadingBookmark = false;
		} else {
			$tp.find(':input').trigger('change');
			$tp.find('.first, .second').val('');
			$tp.find('.operator').trigger('change');
			$target.val($target.data('default')).trigger('chosen:updated').trigger('change');
			$tp.hide();
			$tp.find('.bootstrap-toggle').bootstrapToggle('off');
		}
	};

	$('.report_filteron_set').on('change', 'input:checkbox', ({ target }) => filterOnToggle($(target), true));

	$('#report_form').on('change', '.tab-content select, .tab-content input.form-control.range', ({target}) => {
		if ($(target.closest('.report_table')).length) return;
		if (!loadingBookmark) reportReloadPrep();
	});
	$('#report_form').on('change', '.tab-content .report_fields input:checkbox', () => {
		if (!loadingBookmark) reportReloadPrep();
	});

	$('#report_form').on('change', '.operator', ({ target }) => {
		const $cont = $(target).closest('.form-group');
		switch ($(target).val()) {
			case 'less':
			case 'greater':
				$cont.find('.well').show();
				$cont.find('.first').closest('.form-group').show();
				$cont.find('.second').closest('.form-group').hide();
				break;
			case 'between':
				$cont.find('.well').show();
				$cont.find('.first').closest('.form-group').show();
				$cont.find('.second').closest('.form-group').show();
				break;
			default:
				$cont.find('.well').hide();
				$cont.find('.first').closest('.form-group').hide();
				$cont.find('.second').closest('.form-group').hide();
				break;
		}
	});

	type reportFiltersHtmlParams = { filters: reportFilters; reportType: number };
	const reportFiltersHtml = ({ filters, reportType = REPORT_TYPE_VENDORS }: reportFiltersHtmlParams) => {
		let list: string[] = [];

		type dateFilter = {
			operator: string;
			first: string;
			second: string;
		};
		const operatorDateDisplay = (heading: string, value: dateFilter) => {
			switch (value.operator) {
				case 'less':
					if (value.first == '') return '';
					return `<b>${heading}:</b> Before ${value.first}`;
				case 'greater':
					if (value.first == '') return '';
					return `<b>${heading}:</b> After ${value.first}`;
				case 'between':
					if (value.first == '' || value.second == '') return '';
					return `<b>${heading}:</b> Between ${value.first} and ${value.second}`;
				case 'tweek':
					return `<b>${heading}:</b> This Week`;
				case 'lweek':
					return `<b>${heading}:</b> Last Week`;
				case 'tmonth':
					return `<b>${heading}:</b> This Month`;
				case 'lmonth':
					return `<b>${heading}:</b> Last Month`;
				case 'tyear':
					return `<b>${heading}:</b> This Year`;
				case 'lyear':
					return `<b>${heading}:</b> Last Year`;
				default:
					return '';
			}
		};

		const operatorIntDisplay = (heading: string, value: dateFilter) => {
			switch (value.operator) {
				case 'less':
					if (value.first == '') return '';
					return `<b>${heading}:</b> Less than ${value.first}`;
				case 'greater':
					if (value.first == '') return '';
					return `<b>${heading}:</b> Greater than ${value.first}`;
				case 'between':
					if (value.first == '' || value.second == '') return '';
					return `<b>${heading}:</b> Between than ${value.first} and ${value.second}`;
				default:
					return '';
			}
		};

		const listVendorStatus = { [VENDOR_ACTIVE]: 'Active', [VENDOR_PENDING]: 'Pending', [VENDOR_ARCHIVED]: 'Archived' };
		const valueActiveArchived = { 0: 'Active', 1: 'Archived' };
		const valueServiceStage = {
			[CONTRACT_STAGE_ACTIVE]: 'Active',
			[CONTRACT_STAGE_PENDING]: 'Pending',
			[CONTRACT_STAGE_RENEWED]: 'Renewed',
			[CONTRACT_STAGE_CANCELED]: 'Canceled',
			[CONTRACT_STAGE_TERMINATED]: 'Terminated',
			[CONTRACT_STAGE_EXPIRED]: 'Expired',
		};
		const valueProgressVend = { [VEND_NOT_STARTED]: 'Not Started', [VEND_IN_PROGRESS]: 'In Progress', [VEND_COMPLETE]: 'Complete' };
		const valueProgressCom = { 0: 'Not Complete', 1: 'Complete' };
		const valueContractStatus = {
			[CONTRACT_STATUS_ACTIVE]: 'Active',
			[CONTRACT_STATUS_DRAFT]: 'Draft',
			[CONTRACT_STATUS_TERMINATED]: 'Terminated',
			[CONTRACT_STATUS_EXPIRED]: 'Expired',
			[CONTRACT_STATUS_ARCHIVED]: 'Archive',
		};
		const listContractType = {
			[CONTRACT_TYPE_STANDARD]: 'Standard',
			[CONTRACT_TYPE_SUBSCRIPTION]: 'Subscription',
			[CONTRACT_TYPE_PERPETUAL]: 'Perpetual',
		};

		const rowTypeLabel = '<span class="label label-primary" style="display: inline-block margin-left: 5px">Must match all attributes</span>';
		const colTypeLabel = '<span class="label label-primary" style="display: inline-block margin-left: 5px">Only show selected attributes</span>';
		let display;
		$.each(filters, (index, value: any) => {
			if (value == null || (!value.length && (!value.value || !value.value.length) && !value.hasOwnProperty('operator'))) return;
			switch (index) {
				default: // Error handling
					break;
				case 'trans_date':
					display = operatorDateDisplay('Transaction Date', value);
					if (display.length > 0) list.push(display);
					break;
				case 'printtitle': // Already displayed
				case 'printcomment':
				case 'printcharts':
					break;
				case 'autorenew':
					if (parseInt(value) != -1) {
						list.push(`<b>Autorenew:</b> ${parseInt(value) ? 'Yes' : 'No'}`);
					}
					break;
				case 'vendname':
					display = value.map((val) => listVendors[val]).join(', ');
					list.push(`<b>Vendor:</b> ${display}`);
					break;
				case 'vendcat':
					display = value.map((val) => listCategories[val]).join(', ');
					list.push(`<b>Vendor Categories:</b> ${display}`);
					break;
				case 'service':
					list.push(`<b>Contracts:</b> ${value.join(', ')}`);
					break;
				case 'contract_type':
					if (parseInt(value) != -1) {
						let contractTypeVal = listContractType[value];
						list.push(`<b>Contract Type:</b> ${contractTypeVal}`);
					}
					break;
				case 'stage':
					display = value.map((val) => valueServiceStage[val]).join(', ');
					list.push(`<b>Contract Stages:</b> ${display}`);
					break;
				case 'status':
					if (reportType === REPORT_TYPE_VENDORS) {
						display = value.map((status) => listVendorStatus[status]).join(', ');
						list.push(`<b>Vendor Status:</b> ${display}`);
					} else if (reportType === REPORT_TYPE_CONTRACTS) {
						display = value.map((val) => valueContractStatus[val]).join(', ');
						list.push(`<b>Contract Status:</b> ${display}`);
					}
					break;
				case 'vendstatus':
					display = value.map((status) => listVendorStatus[status]).join(', ');
					list.push(`<b>Vendor Status:</b> ${display}`);
					break;
				case 'catstatus':
					if (parseInt(value) != -1) {
						list.push(`<b>Category Status:</b> ${valueActiveArchived[parseInt(value)]}`);
					}
					break;
				case 'category':
					if (reportType === REPORT_TYPE_VENDORS) {
						display = value.map((val) => listCategories[val]).join(', ');
						list.push(`<b>Vendor Categories:</b> ${display}`);
					} else if (reportType === REPORT_TYPE_RELATIONSHIPS) {
						display = value.map((id) => listCategories[id]).join(', ');
						list.push(`<b>Categories:</b> ${display}`);
					} else if (reportType === REPORT_TYPE_CONTRACTS) {
						display = value.map((id) => listContractCategories[id]).join(', ');
						list.push(`<b>Contract Categories:</b> ${display}`);
					}
					break;
				case 'fourthparty':
					let fpVal = value.value.map((id) => listFourthParties[id]).join(', ');
					if (value.row_type) fpVal += rowTypeLabel;
					if (value.col_type) fpVal += colTypeLabel;
					list.push(`<b>Fourth Party:</b> ${fpVal}`);
					break;
				case 'fourthparty_of':
					let fpOfVal = value.value.map((id) => listFourthParties[id]).join(', ');
					if (value.row_type) fpOfVal += rowTypeLabel;
					if (value.col_type) fpOfVal += colTypeLabel;
					list.push(`<b>Third Party Of:</b> ${fpOfVal}`);
					break;
				case 'attributes':
					let attributesVal = value.value.map((id) => listAttributes[id]).join(', ');
					if (value.row_type) attributesVal += rowTypeLabel;
					if (value.col_type) attributesVal += colTypeLabel;
					list.push(`<b>Attributes:</b> ${attributesVal}`);
					break;
				case 'on_status':
					display = value.map((id) => listOnboarding[id]).join(', ');
					list.push(`<b>Onboarding Status:</b> ${display}`);
					break;
				case 'off_status':
					display = value.map((id) => listOffboarding[id]).join(', ');
					list.push(`<b>Offboarding Status:</b> ${display}`);
					break;
				case 'num_contracts':
					list.push(`<b># of Contracts:</b> ${value}`);
					break;
				case 'parent':
					display = value.map((id) => listParents[id]).join(', ');
					list.push(`<b>Parent Vendor:</b> ${display}`);
					break;
				case 'children':
					let childrenVal = value.value.map((id) => listChildren[id]).join(', ');
					if (value.row_type) childrenVal += rowTypeLabel;
					if (value.col_type) childrenVal += colTypeLabel;
					list.push(`<b>Child Vendors:</b> ${childrenVal}`);
					break;
				case 'relowner_primary':
					display = value.map((id) => listPrimaryRelowners[id]).join(', ');
					list.push(`<b>Primary Relationship Owner:</b> ${display}`);
					break;
				case 'relowners':
					if (reportType === REPORT_TYPE_VENDORS) {
						let relownersVal = value.value.map((id) => listRelowners[id]).join(', ');
						if (value.row_type) relownersVal += rowTypeLabel;
						if (value.col_type) relownersVal += colTypeLabel;
						list.push(`<b>Secondary Relationship Owners:</b> ${relownersVal}`);
					} else {
						let relownersVal = value.value.map((id) => listRelowners[id]).join(', ');
						if (value.row_type) relownersVal += rowTypeLabel;
						if (value.col_type) relownersVal += colTypeLabel;
						list.push(`<b>Relationship Owners:</b> ${relownersVal}`);
					}
					break;
				case 'relowners_analyst':
					let relownersAnalystVal = value.value.map((id) => listAnalystRelowners[id]).join(', ');
					if (value.row_type) relownersAnalystVal += rowTypeLabel;
					if (value.col_type) relownersAnalystVal += colTypeLabel;
					list.push(`<b>Analyst Relationship Owners:</b> ${relownersAnalystVal}`);
					break;
				case 'contractowners':
					let ownersVal = value.value.map((id) => listRelowners[id]).join(', ');
					if (value.row_type) ownersVal += rowTypeLabel;
					if (value.col_type) ownersVal += colTypeLabel;
					list.push(`<b>Contract Owners:</b> ${ownersVal}`);
					break;
				case 'cset':
					display = value.map((id) => listChecklistSets[id]).join(', ');
					list.push(`<b>Contract Checklist Set:</b> ${display}`);
					break;
				case 'checklist':
					display = value.map((id) => listContractChecklist[id]).join(', ');
					list.push(`<b>Contract Checklist Status:</b> ${display}`);
					break;
				case 'risk_req':
					if (+value !== -1) {
						list.push(`<b>Risk Rating Required:</b> ${+value ? 'Yes' : 'No'}`);
					}
					break;
				case 'risk':
					display = value.map((id) => listContractRisk[id]).join(', ');
					list.push(`<b>Contract Risk Rating:</b> ${display}`);
					break;
				case 'importance':
					display = value.map((id) => listImportances[id]).join(', ');
					list.push(`<b>Importance:</b> ${display}`);
					break;
				case 'costcenter':
					display = value.map((id) => listCostcenters[id]).join(', ');
					list.push(`<b>Cost Centers:</b> ${display}`);
					break;
				case 'critical':
					if (parseInt(value) !== -1) {
						list.push(`<b>Vendor Criticality:</b> ${parseInt(value) ? 'Critical' : 'Not critical'}`);
					}
					break;
				case 'businessunit':
					let bunitVal = value.value.map((id) => listBusinessunit[id]).join(', ');
					if (value.row_type) bunitVal += rowTypeLabel;
					if (value.col_type) bunitVal += colTypeLabel;
					list.push(`<b>Business Unit:</b> ${bunitVal}`);
					break;
				case 'vendstate':
					display = value.map((id) => listStates[id]).join(', ');
					list.push(`<b>State:</b> ${display}`);
					break;
				case 'vendcountry':
					display = value.map((id) => listCountries[id]).join(', ');
					list.push(`<b>Country:</b> ${display}`);
					break;
				case 'office_countries':
					let officeCountriesVal = value.value.map((id) => listOfficeCountries[id]).join(', ');
					if (value.row_type) officeCountriesVal += rowTypeLabel;
					if (value.col_type) officeCountriesVal += colTypeLabel;
					list.push(`<b>Office Countries:</b> ${officeCountriesVal}`);
					break;
				case 'offices':
					let officesVal = value.value.map((id) => listOffices[id]).join(', ');
					if (value.row_type) officesVal += rowTypeLabel;
					if (value.col_type) officesVal += colTypeLabel;
					list.push(`<b>Office States:</b> ${officesVal}`);
					break;
				case 'noncontract':
					if (parseInt(value) != -1) {
						list.push(`<b>Non-Contract:</b> ${parseInt(value) ? 'Yes' : 'No'}`);
					}
					break;
				case 'inherent':
					let inherentVal = value.map((id) => htmlEsc(listInherentRatings[id])).join(', ');
					list.push(`<b>Inherent Risk Ratings:</b> ${inherentVal}`);
					break;
				case 'residual':
					let residualVal = value.map((id) => htmlEsc(listResidualRatings[id])).join(', ');
					list.push(`<b>Residual Risk Ratings:</b> ${residualVal}`);
					break;
				case 'vendvalue':
					let vendValueVal = value.map((id) => htmlEsc(listVendorValueRatings[id])).join(', ');
					list.push(`<b>Vendor Value Ratings:</b> ${vendValueVal}`);
					break;
				case 'contact':
					if (parseInt(value) != -1) {
						list.push(`<b>Contact:</b> ${parseInt(value) ? 'Yes' : 'No'}`);
					}
					break;
				case 'opscenter':
					if (parseInt(value) != -1) {
						list.push(`<b>Operation Center:</b> ${parseInt(value) ? 'Yes' : 'No'}`);
					}
					break;
				case 'opscenter_state':
					display = value.map((id) => listStates[id]).join(', ');
					list.push(`<b>Operation Center State:</b> ${display}`);
					break;
				case 'opscenter_country':
					display = value.map((id) => listCountries[id]).join(', ');
					list.push(`<b>Operation Center Country:</b> ${display}`);
					break;
				case 'sla':
					let slaVal = value.map((id) => htmlEsc(listSlaRatings[id])).join(', ');
					list.push(`<b>SLA Latest:</b> ${slaVal}`);
					break;
				case 'kpi':
					let kpiVal = value.map((id) => htmlEsc(listKpiRatings[id])).join(', ');
					list.push(`<b>KPI Latest:</b> ${kpiVal}`);
					break;
				case 'sla_aggregate':
					let slaAggregateVal = value.map((id) => htmlEsc(listSlaRatings[id])).join(', ');
					list.push(`<b>SLA Aggregate:</b> ${slaAggregateVal}`);
					break;
				case 'kpi_aggregate':
					let kpiAggregateVal = value.map((id) => htmlEsc(listKpiRatings[id])).join(', ');
					list.push(`<b>KPI Aggregate:</b> ${kpiAggregateVal}`);
					break;
				case 'progress_vend':
					if (parseInt(value) != -1) {
						list.push(`<b>Vendor Progress:</b> ${valueProgressVend[parseInt(value)]}`);
					}
					break;
				case 'progress_ir':
					if (parseInt(value) != -1) {
						list.push(`<b>Inherent Risk Progress:</b> ${valueProgressCom[parseInt(value)]}`);
					}
					break;
				case 'progress_dd':
					if (parseInt(value) != -1) {
						list.push(`<b>Due Diligence Progress:</b> ${valueProgressCom[parseInt(value)]}`);
					}
					break;
				case 'progress_rd':
					if (parseInt(value) != -1) {
						list.push(`<b>Required Documents Progress:</b> ${valueProgressCom[parseInt(value)]}`);
					}
					break;
				case 'progress_sr':
					if (parseInt(value) != -1) {
						list.push(`<b>Contract Progress:</b> ${valueProgressCom[parseInt(value)]}`);
					}
					break;
				case 'createdate':
					display = operatorDateDisplay('Date Added', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'moddate':
					display = operatorDateDisplay('Modified Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'managedate':
					display = operatorDateDisplay('Last Assessed', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'startdate':
					display = operatorDateDisplay('Effective Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'enddate':
					display = operatorDateDisplay('Termination Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'deadline':
					display = operatorDateDisplay('Cancellation Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'archivedate':
					display = operatorDateDisplay('Archive Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'annual':
					switch (value.operator) {
						case 'less':
							if (value.first != '') {
								list.push(`<b>Annual Dollars:</b> Less than $${value.first}`);
							}
							break;
						case 'greater':
							if (value.first != '') {
								list.push(`<b>Annual Dollars:</b> Greater than $${value.first}`);
							}
							break;
						case 'between':
							if (value.first != '' && value.second != '') {
								list.push(`<b>Annual Dollars:</b> Between $${value.first} and $${value.second}`);
							}
							break;
					}
					break;
				case 'lastreview':
					display = operatorDateDisplay('Last Review', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'nextreview':
					display = operatorDateDisplay('Next Review', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'daystoreview':
					display = operatorIntDisplay('Days to Next Review', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'termcancel':
					display = operatorIntDisplay('Contract Terms to Cancel/Renew', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'dayscancel':
					display = operatorIntDisplay('Days left to Cancel/Renew', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'daystermination':
					display = operatorIntDisplay('Days to Termination', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'notifcanceldays':
					display = operatorIntDisplay('Extended Termination Days', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'notifcanceldate':
					display = operatorDateDisplay('Extended Termination Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'reqdoc':
					if (parseInt(value) != -1) {
						list.push(`<b>Document:</b> ${parseInt(value) ? 'Yes' : 'No'}`);
					}
					break;
				case 'reqdoc_type':
					list.push(`<b>Document Type:</b> ${value.join(', ')}`);
					break;
				case 'reqdoc_createdate':
					display = operatorDateDisplay('Document Last Added Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'reqdoc_last':
					display = operatorDateDisplay('Document Last Effective Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'reqdoc_next':
					display = operatorDateDisplay('Document Next Due Date', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'reqdoc_days':
					display = operatorIntDisplay('Document # of Days Due', value);
					if (display.length > 0) {
						list.push(display);
					}
					break;
				case 'reqdoc_exception':
					if (parseInt(value) != -1) {
						list.push(`<b>Document Exception:</b> ${valueProgressCom[parseInt(value)]}`);
					}
					break;
				case 'role':
					display = value.map((id) => listUserRoles[id]).join(', ');
					list.push(`<b>User Role:</b> ${display}`);
					break;
			}
		});
		if (reportType == REPORT_TYPE_CONTRACTS) {
			transQuestionMap.forEach((question) => {
				if (!filters[question.name] || !filters[question.name].length) return;
				display = filters[question.name].map((val) => htmlEsc(answerRatings[RATING_SLA].find((rating) => rating.id == val)?.title)).join(', ');
				list.push(`<b>${question.display}:</b> ${display}`);
			});
			transCustomColMap.forEach((col) => {
				switch (+col.type) {
					case FIELD_NUMBER:
					case FIELD_CURRENCY:
						if (!filters[col.name] || !filters[col.name].hasOwnProperty('operator')) return;
						display = operatorIntDisplay(col.displayName, filters[col.name]);
						if (display.length > 0) list.push(display);
						break;
					case FIELD_DATE:
						if (!filters[col.name] || !filters[col.name].hasOwnProperty('operator')) return;
						display = operatorDateDisplay(col.displayName, filters[col.name]);
						if (display.length > 0) list.push(display);
						break;
					case FIELD_STATE:
						if (!filters[col.name] || !filters[col.name].length) return;
						display = filters[col.name].map((val) => listStates[val] ?? val).join(', ');
						list.push(`<b>${col.displayName}:</b> ${display}`);
						break;
					case FIELD_COUNTRY:
						if (!filters[col.name] || !filters[col.name].length) return;
						display = filters[col.name].map((val) => listCountries[val] ?? val).join(', ');
						list.push(`<b>${col.displayName}:</b> ${display}`);
						break;
					case FIELD_SELECT:
						if (!filters[col.name] || !filters[col.name].length) return;
						display = filters[col.name].join(', ');
						list.push(`<b>${col.displayName}:</b> ${display}`);
						break;
				}
			});
		}

		let html = '';
		if (list.length > 0) {
			html += '<ul>';
			list.forEach((val) => (html += `<li>${val}</li>`));
			html += '</ul>';
		}

		return html;
	};

	const reportPreview = () => {
		const type = getReportType();
		if (!type) return;

		$('#report_charts').hide();

		const filters = getReportFilters(type);
		const fields = getReportFields(type);

		// Submit the form
		$('#report_form_submit').prop('disabled', true);
		$('#report_form_spinner').show();

		const dtOptions: DataTables.Settings = {
			ajax: {
				url: '/data/report_load',
				data: (postData) => ({
					...postData,
					type,
					filters,
					fields,
				}),
				dataSrc: (responseData) => {
					if (responseData.hasOwnProperty('charts')) {
						buildCharts(responseData.charts);
						$('#report_charts').show();
					} else {
						$('#report_charts').hide();
					}
					return responseData.data;
				},
			},
			columns: [],
			columnDefs: [{ targets: '_all', render: (val) => htmlEsc(val) }],
			colReorder: true,
			order: [[0, 'asc']],
			pageLength: reportDt[type] ? reportDt[type].page.len() : 25,
			responsive: false,
			scrollX: true,
		};

		if (reportDt[type] !== null) {
			reportDt[type]!.destroy();
			reportDt[type] = null;
		}

		setTimeout(() => {
			try {
				// Does the current column order align with the default order?
				const inDefaultOrder = () => {
					if (!currentColOrder[type]) return true;
					const defaultColOrder = Object.keys(columnInfoNameLookup[type]);

					// Columns are out of order if any column's index in the default order is greater then the next column's index
					const colOutOfOrder = currentColOrder[type].find((colName, index, order) => {
						if (index === order.length - 1) return false;
						const colIndex = defaultColOrder.indexOf(colName);
						const nextColIndex = defaultColOrder.indexOf(currentColOrder[type][index + 1]);
						return colIndex > nextColIndex;
					});
					return colOutOfOrder === undefined;
				};

				// Figure out which columns to use and in which order
				if (!currentColOrder[type] || inDefaultOrder()) { // Generate new order
					currentColOrder[type] = columnInfo[type]
						.filter((col) => {
							return !col.$field || (col.$field && fields[col.name]);
						})
						.map((col) => col.name);
				} else { // Merge with existing order
					// Find which columns need to be added or removed from our current order
					let colsToAdd = [];
					let colsToRemove = [];
					columnInfo[type].forEach((col) => {
						if (!col.$field) return;
						if (fields[col.name] && !currentColOrder[type].includes(col.name)) colsToAdd.push(col.name);
						if (!fields[col.name] && currentColOrder[type].includes(col.name)) colsToRemove.push(col.name);
					});

					// Remove columns from current order
					currentColOrder[type] = currentColOrder[type].filter((colName) => !colsToRemove.includes(colName) && columnInfoNameLookup[type][colName]);

					// Add any new columns to the end of the order
					currentColOrder[type] = [...currentColOrder[type], ...colsToAdd];
				}

				const columns = currentColOrder[type].map((colName) => columnInfoNameLookup[type][colName]);
				const headers = columns.map((col) => col.title);
				dtOptions.columns = columns;
				const $table = $reportTables[type].find('.table');

				reportDt[type] = $table
					.html(`<thead>${headers.map((text) => `<th>${text}</th>`).join('')}</thead>`)
					.off('init.dt draw.dt column-reorder.dt')
					.on('init.dt', () => {
						const $tooltip = $reportTables[type].find('.header-tooltip');
						$tooltip.tooltip();
						$tooltip.on('click', (event) => {
							event.stopPropagation();
						});

						const $scroll = $reportTables[type].find('.dataTables_scroll');
						$scroll.find('.dataTables_scrollBody').doubleScroll({ $insertBefore: $scroll });
						if (type == REPORT_TYPE_CONTRACTS) {
							reportDt[type].columns().every((index) => {
								const colHeader = $(reportDt[type].column(index).header());
								const headerText = colHeader.text().trim();
								if (headerText.substring(0, 9) == 'quarterly' || headerText.substring(0, 7) == 'monthly') {
									colHeader.text(reportDt[type].ajax.json().datelabels[headerText]);
								}
							});
						}
					})
					.on('draw.dt column-reorder.dt', () => {
						currentColOrder[type] = [];
						reportDt[type].columns().every((index) => {
							const col = reportDt[type].column(index);
							Object.keys(columnInfoNameLookup[type]).forEach((colName) => {
								if (columnInfoNameLookup[type][colName].data === col.dataSrc()) currentColOrder[type].push(colName);
							});
						});
					})
					.DataTable(dtOptions);
			} catch (error) {
				logerror('report submit', error);
			}

			$('report_form_spinner').hide();
			setTimeout(() => $('#report_form_submit').prop('disabled', false), 1000);
		}, 100);
	};

	const reportSearch = ($tab: $) => {
		const helpCats = $tab
			.find('.filteron_category')
			.toArray()
			.map((element) => {
				const $cat = $(element);
				const catName = $cat.find('h4').first().text();
				const topics = $cat
					.find('.filteron_topic')
					.toArray()
					.map((element) => {
						const $topic = $(element);
						const topicText = catName + ' ' + $topic.text().trim().toLowerCase();
						return { $topic, topicText };
					});
				return { $cat, topics };
			});

		$tab.find('.filteron_search_input').on('keyup', ({ target }) => {
			const searchTerm = $(target).val().toString().trim().toLowerCase();
			searchHelp(searchTerm);
		});

		const $searchResult = $tab.find('.filteron_search_result');

		const searchHelp = (searchTerm: string) => {
			const isSearching = searchTerm.length > 0;
			if (!isSearching) {
				helpCats.forEach(({ $cat, topics }) => {
					$cat.show();
					topics.forEach(({ $topic }) => $topic.show());
				});
				$searchResult.html('Showing all filters.');
				return;
			}

			const searchList = searchTerm.match(/[a-z]+/gi);

			let displayCount = 0;
			helpCats.forEach(({ $cat, topics }) => {
				$cat.hide();
				topics.forEach(({ $topic, topicText }) => {
					$topic.hide();
					const matchesWordInSearch = searchList.find((word) => topicText.includes(word));
					if (matchesWordInSearch) {
						displayCount++;
						$topic.show();
						$cat.show();
					}
				});
			});
			if (displayCount) $searchResult.html(`Showing ${displayCount} filters.`);
			else $searchResult.html('Showing no filters. Clear the search bar above.');
		};
	};

	reportSearch($('#tab-vendor'));
	reportSearch($('#tab-contract'));

	$('#report_form_save')
		.off('click')
		.on('click', () => {
			const reportType = getReportType();
			if (!reportType) return;

			const filters = getReportFilters(reportType);
			const fields = getReportFields(reportType);

			const filtersHtml = reportFiltersHtml({ filters, reportType });
			const filtersJsx = (
				<>
					<p>
						<b>Title:</b> {filters.printtitle}
					</p>
					{filters.printcomment != '' ? (
						<p>
							<b>Comment:</b> {filters.printcomment}
						</p>
					) : (
						''
					)}
					{filters.hasOwnProperty('printcharts') ? (
						<p>
							<b>Display Charts:</b> {filters.printcharts ? 'Yes' : 'No'}
						</p>
					) : (
						''
					)}
					{fields.trans_version ? (
						<p>
							<b>Transaction Version:</b> {listTransVersions[fields.trans_version]}
						</p>
					) : (
						''
					)}
					<p>
						<b>Filters:</b>
					</p>
					{filtersHtml}
					<p>
						<b>Fields:</b>
					</p>
					<ul>
						{currentColOrder[reportType].map((colName) => {
							const colInfo = columnInfoNameLookup[reportType][colName];
							return <li>{colInfo.displayTitle || colInfo.title}</li>;
						})}
					</ul>
				</>
			);
			$('#report_bookmark_save_detail').empty().append(filtersJsx);

			$('#report_bookmark_save_confirm')
				.off('click')
				.on('click', (event) => {
					event.preventDefault();
					const $btn = $(event.target);
					$btn.prop('disabled', true);
					$('#spinner').show();

					const postData = {
						type: 'report_bookmark_save',
						data: {
							filters: {
								...filters,
								privacy: $('#report_bookmark_save_public').is(':checked') ? 1 : 0, // 0 private, 1 public
								reporttype: reportType,
							},
							fields,
							colOrder: currentColOrder[reportType],
						},
					};
					setTimeout(() => {
						ajaxPromise('/form/submit', postData)
							.then((res) => {
								if (res.rc == 'OK') {
									$('#report_bookmark_save').modal('hide');
									displayNotification('Report', 'Report saved.', 'success');
								} else {
									displayNotification('Report', 'An error occurred while sending this request.', 'danger');
								}
							})
							.catch((error) => {
								logerror('report_bookmark_save', error);
							});

						$('#spinner').hide();
						setTimeout(() => $btn.prop('disabled', false), 1000);
					}, 100);
				});

			$('#report_bookmark_save').modal();
		});

	let bookmarksDt: DataTables.Api | null = null;

	$('#report_form_load')
		.off('click')
		.on('click', () => {
			if (bookmarksDt !== null) {
				bookmarksDt.destroy();
				bookmarksDt = null;
			}

			const dtOptions: DataTables.Settings = {
				ajax: {
					url: '/data/report_bookmarks_load',
				},
				columns: [
					{
						title: 'Name',
						data: 'report_title',
					},
					{
						title: 'Type',
						data: 'report_type_name',
						searchable: false,
					},
					{
						title: 'Author',
						data: 'username',
					},
					{
						title: 'Date',
						data: 'report_createdate_nice',
						className: 'nowrap',
					},
					{
						title: 'Private',
						data: 'privacy',
						searchable: false,
						render: null,
					},
					{
						title: 'Actions',
						data: 'buttons',
						className: 'text-right',
						orderable: false,
						searchable: false,
						render: null,
					},
				],
				columnDefs: [
					{ targets: 0, responsivePriority: 1 },
					{ targets: -1, responsivePriority: 2 },
					{ targets: '_all', render: (val) => htmlEsc(val) },
				],
				order: [[3, 'desc']],
			};

			bookmarksDt = $('#reportbookmarks_table').DataTable(dtOptions);

			$('#reportbookmarks_table')
				.off('click')
				.on('click', '.report_edit', ({ target }) => {
					const rowData = getDtRowData(bookmarksDt, target);
					loadBookmark(rowData);
				})
				.on('click', '.report_delete', ({ target }) => {
					const rowData = getDtRowData(bookmarksDt, target);
					deleteBookmark(rowData);
					$('#report_bookmark_load').animate({ scrollTop: 0 }, 'fast');
				});

			$('#report_bookmark_load').modal();
		});

	const deleteBookmark = (bookmark) => {
		$('#report_bookmark_delete_text').html(`Are you sure you would like to delete the report <em>"${htmlEsc(bookmark.report_title)}"</em>?`);
		$('#report_bookmark_delete_container').collapse('show');
		$('#report_bookmark_delete_confirm')
			.off('click')
			.on('click', () => {
				$('#report_bookmark_delete_confirm').prop('disabled', true);
				$('#spinner').show();

				setTimeout(() => {
					ajaxPromise('/form/submit', { type: 'report_bookmark_delete', data: bookmark.report_id })
						.then((res) => {
							if (res.rc !== 'OK') {
								displayNotification('Report', 'An error occurred while sending this request.', 'danger');
								return;
							}

							$('#report_bookmark_delete_container').collapse('hide');
							if (bookmarksDt !== null) bookmarksDt.ajax.reload();
							displayNotification('Report', 'Report Bookmark deleted.', 'success');
						})
						.catch((error) => {
							logerror('bookmark delete submit', error);
						});

					$('#spinner').hide();
					setTimeout(() => $('#report_bookmark_delete_confirm').prop('disabled', false), 1000);
				}, 100);
			});
		$('#report_bookmark_delete_cancel')
			.off('click')
			.on('click', () => $('#report_bookmark_delete_container').collapse('hide'));
		$('#report_bookmark_delete_container')
			.off('hidden.bs.collapse')
			.on('hidden.bs.collapse', () => $('#report_bookmark_delete_text').html(''));
	};

	const loadBookmark = (bookmarkRowData) => {
		loadingBookmark = true;
		const bookmark = JSON.parse(bookmarkRowData.report_data);
		const type = parseInt(bookmarkRowData.report_type);
		const _loadBookmarkFilterOperator = (targetroot, filters, key) => {
			// Only to be used on filters with operators.
			let $check = $(targetroot.slice(0, 14) + 'on' + targetroot.slice(14));

			if (filters.hasOwnProperty(key) && filters[key]) {
				setDateFilter(targetroot, filters[key]);
				$(targetroot).parent().show();
				$check.prop('checked', 1);
			} else {
				setDateFilter(targetroot, null);
				$(targetroot).parent().hide();
				$check.prop('checked', 0);
			}
		};

		const _loadBookmarkFilterCommaSeparated = (target, book, key) => {
			const $check = $(target.slice(0, 14) + 'on' + target.slice(14));
			if (book.filters.hasOwnProperty(key) && book.filters[key].hasOwnProperty('value') && book.filters[key].value.length) {
				const filter = book.filters[key];
				$(target).val(filter.value);
				$(`${target}_row_type`).bootstrapToggle(filter.row_type == 0 ? 'off' : 'on');
				$(`${target}_col_type`).bootstrapToggle(filter.col_type == 0 ? 'off' : 'on');
				$(target).parent().show();
				$check.prop('checked', 1);
			} else {
				$(target).val([]);
				$(`${target}_row_type`).bootstrapToggle('off');
				$(`${target}_col_type`).bootstrapToggle('off');
				$(target).parent().hide();
				$check.prop('checked', 0);
			}
		};

		function _loadBookmarkFilter(targetroot, book, def, key) {
			// Only to be used on simple filters.
			let $check = $(targetroot.slice(0, 14) + 'on' + targetroot.slice(14));

			if (book.filters.hasOwnProperty(key) && book.filters[key] != def.filters[key]) {
				//logme(key + ': √ ' + book.filters[key] + ' / ' + def.filters[key])
				$(targetroot).val(book.filters[key]);
				$(targetroot).parent().show();
				$check.prop('checked', 1);
			} else {
				//logme(key + ': X ' + book.filters[key] + ' / ' + def.filters[key])
				$(targetroot).val(def.filters[key]); // use default value
				$(targetroot).parent().hide();
				$check.prop('checked', 0);
			}
		}

		let defaults;
		let data;
		if (type == REPORT_TYPE_CONTRACTS) {
			const transId = bookmark.fields.trans_version || null;
			setSelectedTransVersion(REPORT_TYPE_CONTRACTS, transId);
		}
		switch (type) {
			case REPORT_TYPE_VENDORS: // Vendors
				// start with defaults & map bookmark choices over top.
				defaults = {
					filters: {
						status: -1,
						catstatus: -1,
						critical: -1,
						progress_vend: -1,
						progress_ir: -1,
						progress_dd: -1,
						progress_rd: -1,
						progress_sr: -1,
						noncontract: -1,
						reqdoc: -1,
						reqdoc_exception: -1,
						opscenter: -1,
						contact: -1,
					},
				};
				data = { ...defaults, ...bookmark };
				_loadBookmarkFilter('#report_filter_vendor_category', bookmark, defaults, 'category');
				_loadBookmarkFilter('#report_filter_vendor_status', bookmark, defaults, 'status');
				_loadBookmarkFilter('#report_filter_vendor_category_status', bookmark, defaults, 'catstatus');
				_loadBookmarkFilter('#report_filter_vendor_vendorname', bookmark, defaults, 'vendname');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_fourthparty', bookmark, 'fourthparty');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_fourthparty_of', bookmark, 'fourthparty_of');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_attributes', bookmark, 'attributes');
				_loadBookmarkFilter('#report_filter_vendor_on_status', bookmark, defaults, 'on_status');
				_loadBookmarkFilter('#report_filter_vendor_off_status', bookmark, defaults, 'off_status');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_num_contracts', bookmark, 'num_contracts');
				_loadBookmarkFilter('#report_filter_vendor_parent', bookmark, defaults, 'parent');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_children', bookmark, 'children');
				_loadBookmarkFilter('#report_filter_vendor_relowner_primary', bookmark, defaults, 'relowner_primary');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_relowners', bookmark, 'relowners');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_relowners_analyst', bookmark, 'relowners_analyst');
				_loadBookmarkFilter('#report_filter_vendor_critical', bookmark, defaults, 'critical');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_businessunit', bookmark, 'businessunit');
				_loadBookmarkFilter('#report_filter_vendor_state', bookmark, defaults, 'vendstate');
				_loadBookmarkFilter('#report_filter_vendor_country', bookmark, defaults, 'vendcountry');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_office_countries', bookmark, 'office_countries');
				_loadBookmarkFilterCommaSeparated('#report_filter_vendor_offices', bookmark, 'offices');
				_loadBookmarkFilter('#report_filter_vendor_progress_vend', bookmark, defaults, 'progress_vend');
				_loadBookmarkFilter('#report_filter_vendor_progress_ir', bookmark, defaults, 'progress_ir');
				_loadBookmarkFilter('#report_filter_vendor_progress_dd', bookmark, defaults, 'progress_dd');
				_loadBookmarkFilter('#report_filter_vendor_progress_rd', bookmark, defaults, 'progress_rd');
				_loadBookmarkFilter('#report_filter_vendor_progress_sr', bookmark, defaults, 'progress_sr');
				_loadBookmarkFilter('#report_filter_vendor_inherent', bookmark, defaults, 'inherent');
				_loadBookmarkFilter('#report_filter_vendor_residual', bookmark, defaults, 'residual');
				_loadBookmarkFilter('#report_filter_vendor_vendorvalue', bookmark, defaults, 'vendvalue');
				_loadBookmarkFilter('#report_filter_vendor_contact', bookmark, defaults, 'contact');
				_loadBookmarkFilter('#report_filter_vendor_opscenter', bookmark, defaults, 'opscenter');
				_loadBookmarkFilter('#report_filter_vendor_opscenter_state', bookmark, defaults, 'opscenter_state');
				_loadBookmarkFilter('#report_filter_vendor_opscenter_country', bookmark, defaults, 'opscenter_country');
				_loadBookmarkFilterOperator('#report_filter_vendor_lastreview', data.filters, 'lastreview');
				_loadBookmarkFilterOperator('#report_filter_vendor_nextreview', data.filters, 'nextreview');
				_loadBookmarkFilterOperator('#report_filter_vendor_daystoreview', data.filters, 'daystoreview');
				_loadBookmarkFilter('#report_filter_vendor_noncontract', bookmark, defaults, 'noncontract');
				_loadBookmarkFilter('#report_filter_vendor_sla', bookmark, defaults, 'sla');
				_loadBookmarkFilter('#report_filter_vendor_kpi', bookmark, defaults, 'kpi');
				_loadBookmarkFilter('#report_filter_vendor_sla_aggregate', bookmark, defaults, 'sla_aggregate');
				_loadBookmarkFilter('#report_filter_vendor_kpi_aggregate', bookmark, defaults, 'kpi_aggregate');
				_loadBookmarkFilterOperator('#report_filter_vendor_createdate', data.filters, 'createdate');
				_loadBookmarkFilterOperator('#report_filter_vendor_moddate', data.filters, 'moddate');
				_loadBookmarkFilter('#report_filter_vendor_modby', bookmark, defaults, 'modby');
				_loadBookmarkFilterOperator('#report_filter_vendor_managedate', data.filters, 'managedate');
				_loadBookmarkFilterOperator('#report_filter_vendor_archivedate', data.filters, 'archivedate');
				_loadBookmarkFilter('#report_filter_vendor_reqdoc', bookmark, defaults, 'reqdoc');
				_loadBookmarkFilter('#report_filter_vendor_reqdoc_type', bookmark, defaults, 'reqdoc_type');
				_loadBookmarkFilterOperator('#report_filter_vendor_reqdoc_createdate', data.filters, 'reqdoc_createdate');
				_loadBookmarkFilterOperator('#report_filter_vendor_reqdoc_last', data.filters, 'reqdoc_last');
				_loadBookmarkFilterOperator('#report_filter_vendor_reqdoc_next', data.filters, 'reqdoc_next');
				_loadBookmarkFilterOperator('#report_filter_vendor_reqdoc_days', data.filters, 'reqdoc_days');
				_loadBookmarkFilter('#report_filter_vendor_reqdoc_exception', bookmark, defaults, 'reqdoc_exception');
				$('#report_filter_vendor_print_title').val(data.filters.printtitle);
				$('#report_filter_vendor_print_comment').val(data.filters.printcomment);
				$('#report_filter_vendor_print_charts').val(data.filters.printcharts);
				break;
			case REPORT_TYPE_CONTRACTS: // Contracts
				defaults = {
					// start with defaults & map bookmark choices over top.
					filters: {
						status: -1,
						contract_type: -1,
						vendstatus: -1,
						critical: -1,
						autorenew: -1,
						risk_req: -1,
					},
				};
				data = { ...defaults, ...bookmark };
				_loadBookmarkFilter('#report_filter_contract_service', bookmark, defaults, 'service');
				_loadBookmarkFilter('#report_filter_contract_category', bookmark, defaults, 'category');
				_loadBookmarkFilter('#report_filter_contract_status', bookmark, defaults, 'status');
				_loadBookmarkFilter('#report_filter_contract_type', bookmark, defaults, 'contract_type');
				_loadBookmarkFilter('#report_filter_contract_stage', bookmark, defaults, 'stage');
				_loadBookmarkFilter('#report_filter_contract_vendorname', bookmark, defaults, 'vendname');
				_loadBookmarkFilter('#report_filter_contract_vendcat', bookmark, defaults, 'vendcat');
				_loadBookmarkFilter('#report_filter_contract_vend_status', bookmark, defaults, 'vendstatus');
				_loadBookmarkFilterCommaSeparated('#report_filter_contract_relowners', bookmark, 'relowners');
				_loadBookmarkFilter('#report_filter_contract_critical', bookmark, defaults, 'critical');
				_loadBookmarkFilter('#report_filter_contract_vendor_state', bookmark, defaults, 'vendstate');
				_loadBookmarkFilter('#report_filter_contract_vendor_country', bookmark, defaults, 'vendcountry');
				_loadBookmarkFilter('#report_filter_contract_inherent', bookmark, defaults, 'inherent');
				_loadBookmarkFilter('#report_filter_contract_residual', bookmark, defaults, 'residual');
				_loadBookmarkFilter('#report_filter_contract_vendorvalue', bookmark, defaults, 'vendvalue');
				_loadBookmarkFilterOperator('#report_filter_contract_lastreview', data.filters, 'lastreview');
				_loadBookmarkFilterOperator('#report_filter_contract_nextreview', data.filters, 'nextreview');
				_loadBookmarkFilterOperator('#report_filter_contract_daystoreview', data.filters, 'daystoreview');
				_loadBookmarkFilterCommaSeparated('#report_filter_contract_contractowners', bookmark, 'contractowners');
				_loadBookmarkFilter('#report_filter_contract_cset', bookmark, defaults, 'cset');
				_loadBookmarkFilter('#report_filter_contract_checklist', bookmark, defaults, 'checklist');
				_loadBookmarkFilter('#report_filter_contract_risk_req', bookmark, defaults, 'risk_req');
				_loadBookmarkFilter('#report_filter_contract_risk', bookmark, defaults, 'risk');
				_loadBookmarkFilter('#report_filter_contract_sla', bookmark, defaults, 'sla');
				_loadBookmarkFilter('#report_filter_contract_kpi', bookmark, defaults, 'kpi');
				_loadBookmarkFilter('#report_filter_contract_sla_aggregate', bookmark, defaults, 'sla_aggregate');
				_loadBookmarkFilter('#report_filter_contract_kpi_aggregate', bookmark, defaults, 'kpi_aggregate');
				_loadBookmarkFilter('#report_filter_contract_importance', bookmark, defaults, 'importance');
				_loadBookmarkFilter('#report_filter_contract_costcenter', bookmark, defaults, 'costcenter');
				_loadBookmarkFilterOperator('#report_filter_contract_num_contracts', data.filters, 'num_contracts');
				_loadBookmarkFilterOperator('#report_filter_contract_annual', data.filters, 'annual');
				_loadBookmarkFilter('#report_filter_contract_autorenew', bookmark, defaults, 'autorenew');
				_loadBookmarkFilterOperator('#report_filter_contract_startdate', data.filters, 'startdate');
				_loadBookmarkFilterOperator('#report_filter_contract_enddate', data.filters, 'enddate');
				_loadBookmarkFilterOperator('#report_filter_contract_deadline', data.filters, 'deadline');
				_loadBookmarkFilterOperator('#report_filter_contract_termcancel', data.filters, 'termcancel');
				_loadBookmarkFilterOperator('#report_filter_contract_dayscancel', data.filters, 'dayscancel');
				_loadBookmarkFilterOperator('#report_filter_contract_daystermination', data.filters, 'daystermination');
				_loadBookmarkFilterOperator('#report_filter_contract_notifcanceldays', data.filters, 'notifcanceldays');
				_loadBookmarkFilterOperator('#report_filter_contract_notifcanceldate', data.filters, 'notifcanceldate');
				$('#report_filter_contract_print_title').val(data.filters.printtitle);
				$('#report_filter_contract_print_comment').val(data.filters.printcomment);
				$('#report_filter_contract_print_charts').val(data.filters.printcharts);
				if (selectedTransVersion) {
					_loadBookmarkFilterOperator('#report_filter_contract_trans_date', data.filters, 'trans_date');
					transQuestionMap.forEach((question, selectEl) => _loadBookmarkFilter(`#${selectEl.id}`, bookmark, defaults, question.name));
					transCustomColMap.forEach((col, selectEl) => {
						switch (+col.type) {
							case FIELD_NUMBER:
							case FIELD_CURRENCY:
							case FIELD_DATE:
								_loadBookmarkFilterOperator(`#${selectEl.id}`, data.filters, col.name);
								break;
							case FIELD_STATE:
							case FIELD_COUNTRY:
							case FIELD_SELECT:
								$(`#${selectEl.id}`).val(data.filters[col.name]);
								break;
						}
					});
				}
				break;
			case REPORT_TYPE_RELATIONSHIPS: // Relationships
				$('#report_filter_relations_categories').val(bookmark.filters.category);
				$('#report_filter_relations_relowners').val(bookmark.filters.relowners);
				$('#report_filter_relations_role').val(bookmark.filters.role);
				$('#report_filter_relations_print_title').val(bookmark.filters.printtitle);
				$('#report_filter_relations_print_comment').val(bookmark.filters.printcomment);
				break;
		}
		columnInfo[type].forEach(({ name, $field }) => {
			if (!$field) return;
			const isChecked = bookmark.fields.hasOwnProperty(name) ? +bookmark.fields[name] : 0;
			$field.prop('checked', isChecked);
		});
		$(`#report_form .report_tab_link[data-type="${type}"]`).tab('show');
		$('#report_form select.chosen').trigger('chosen:updated');
		$('#report_form .operator').trigger('change');
		currentColOrder[type] = bookmark.colOrder || null;
		reportPreview();
		displayNotification('Report', `"${bookmark.filters.printtitle}" Report Bookmark loaded.`, 'success');
		$('#report_bookmark_load').modal('hide');
		loadingBookmark = false;
	};

	//Export
	$('#report_form_export')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			const type = getReportType();
			if (!type) return;

			const colDataOrder = currentColOrder[type].map((colName) => columnInfoNameLookup[type][colName].data);
			const headers = currentColOrder[type].map((colName) => columnInfoNameLookup[type][colName].displayTitle || columnInfoNameLookup[type][colName].title);
			const params = {
				...reportDt[type].ajax.params(),
				colDataOrder,
				headers,
			};

			// Submit the form
			$('#report_form_export').prop('disabled', true);
			$('#report_form_spinner').show();
			post(
				'/report',
				{
					mode: DATATABLE_EXPORT,
					params: JSON.stringify(params),
				},
				'_blank'
			);

			$('#report_form_spinner').hide();
			setTimeout(() => $('#report_form_export').prop('disabled', false), 1000);
		});

	//Print
	$('#report_form_print')
		.off('click')
		.on('click', (event) => {
			event.preventDefault();

			const type = getReportType();
			if (!type) return;

			const filters = getReportFilters(type);
			const colDataOrder = currentColOrder[type].map((colName) => columnInfoNameLookup[type][colName].data);
			const headers = currentColOrder[type].map((colName) => columnInfoNameLookup[type][colName].displayTitle || columnInfoNameLookup[type][colName].title);
			const params = {
				...reportDt[type].ajax.params(),
				filters, // Needed for title to be set on-demand, basically
				filtersHtml: reportFiltersHtml({ filters, reportType: type }),
				colDataOrder,
				headers,
			};

			// Submit the form
			$('#report_form_print').prop('disabled', true);
			$('#report_form_spinner').show();

			post(
				'/printout',
				{
					params: JSON.stringify(params),
				},
				'_blank'
			);

			$('#report_form_spinner').hide();
			setTimeout(() => $('#report_form_print').prop('disabled', false), 1000);
		});

	// trigger the change to color the inputs, then reset the "reload" to false, so when we don't set it off
	$('#report_form select.chosen').trigger('chosen:updated').trigger('change');
	$('.report_filteron_set input:checkbox').each((_, element) => filterOnToggle($(element), false));
	reportReloadCheck = false;

	reportPreview();
};

if ($('#report_form').length == 1) initReporting();

//On print window
if ($('#report_printout').length == 1) {
	const params = atob($('#report_printout').data('params'));
	ajaxPromise('/report', { mode: DATATABLE_ALL, params })
		.then((res) => {
			if (res.rc !== 'OK') {
				displayNotification('Report', 'There was an error loading the data.', 'danger');
				return;
			}

			$('#spinner').show();

			if (res.results.hasOwnProperty('charts')) {
				const chartIds = ['chart-critical', 'chart-inherentrisk', 'chart-residualrisk', 'chart-slascore', 'chart-kpiscore', 'chart-vendvalue', 'chart-performance-sla', 'chart-performance-kpi'];
				const charts = (
					<div className="row">
						{chartIds.map((id) => (
							<div className="col-xs-6 col-sm-2" style="display: none;">
								<div id={id}></div>
							</div>
						))}
					</div>
				);
				$('#report_printout').append(charts);
				buildCharts(res.results.charts);
			}

			const table = (
				<table className="table table-condensed table-striped" style="width: 100%;">
					<thead>
						<tr>
							{res.results.headers.map((col) => (
								<th>{col}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{res.results.data.map((row, index) => (
							<tr className={index % 2 == 0 ? 'odd' : 'even'} style="border-bottom: 1px solid #d0d0d0;">
								{row.map((cell) => (
									<td>{cell != null ? cell : ''}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			);
			$('#report_printout').append(table);
			$('#report_printout_total').html(`${res.results.recordsFiltered} records`);

			setTimeout(() => {
				$('#spinner').hide();
				window.print();
			}, 1000);
		})
		.catch((error) => {
			logerror('report get error [ajax]', error);
		});
}
