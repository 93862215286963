import 'bootstrap';

declare global {
	// DataTables only has 3rd-party type definitions which are not 100% correct.  Fixes applied here.
	namespace DataTables {
		interface AjaxMethodModel {
			json(): any,
			params(): any,
		}
		interface ColumnDefsSettings {
			responsivePriority?: number,
		}
		interface RowMethods {
			data(): any,
		}
	}
	interface JQuery {
		/** zxcvbn Bootstrap progress bar
 		* 
		* https://github.com/martinwnet/zxcvbn-bootstrap-strength-meter/blob/master/zxcvbn-bootstrap-strength-meter.js
		* Requires zxcvbn.js and Bootstrap
		* Modified by Matthew Alan Estock
		* Further modified by Bradford Quiner
		*/
		zxcvbnProgressBar(options: zxcvbnProgressBarOptions, zxcvbn: typeof import('zxcvbn')): $;

		/** https://github.com/customd/jquery-number 
		 * 
		 * No typescript definitions available for this plugin.
		*/
		number(...args: any): $,

		doubleScroll(this: $, userOptions?: doubleScrollParams): $;
		val(): any;
	}
}

export const zxcvbnProgressBar = async ($input: $, options: zxcvbnProgressBarOptions) => {
	const {default: zxcvbn} = await import('zxcvbn');
	$input.zxcvbnProgressBar(options, zxcvbn);
};

type zxcvbnProgressBarOptions = {
	passwordInput: string;
	scoreInput: string;
	userInputs?: string[];
	ratings?: string[];
	allProgressBarClasses?: string;
	progressBarClass0?: string;
	progressBarClass1?: string;
	progressBarClass2?: string;
	progressBarClass3?: string;
	progressBarClass4?: string;
	warningTarget: string;
	suggestionsTarget: string;
};
$.fn.zxcvbnProgressBar = function (options, zxcvbn) {
	// init settings
	const settings: zxcvbnProgressBarOptions = {
		passwordInput: '#Password',
		scoreInput: false,
		userInputs: [],
		ratings: ["Very weak", "Weak", "OK", "Strong", "Very strong"],
		// all progress bar classes removed before adding score specific css class
		allProgressBarClasses: "progress-bar-danger progress-bar-warning progress-bar-success progress-bar-striped active",
		// bootstrap css classes (0-4 corresponds with zxcvbn score)
		progressBarClass0: "progress-bar-danger progress-bar-striped active",
		progressBarClass1: "progress-bar-danger progress-bar-striped active",
		progressBarClass2: "progress-bar-warning progress-bar-striped active",
		progressBarClass3: "progress-bar-success",
		progressBarClass4: "progress-bar-success",
		warningTarget: false,
		suggestionsTarget: false,
		...options,
	};

	const updateProgressBar = ($progressBar: $) => {
		const password = $(settings.passwordInput).val();

		if (!password) {
			$progressBar.css('width', '0%');
			$progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass0);
			$progressBar.html('');
			if (settings.warningTarget) {
				$(settings.warningTarget).html('');
			}
			if (settings.suggestionsTarget) {
				$(settings.suggestionsTarget).html('');
			}
			return;
		}

		const result = zxcvbn(password, settings.userInputs);

		// result.score: 0, 1, 2, 3 or 4 - if crack time is less than 10**2, 10**4, 10**6, 10**8, Infinity.
		const scorePercentage = (result.score + 1) * 20;

		$progressBar.css('width', scorePercentage + '%');

		if (result.score == 0) {
			//weak
			$progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass0);
			$progressBar.html(settings.ratings[0]);
		}
		else if (result.score == 1) {
			//normal
			$progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass1);
			$progressBar.html(settings.ratings[1]);
		}
		else if (result.score == 2) {
			//medium
			$progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass2);
			$progressBar.html(settings.ratings[2]);
		}
		else if (result.score == 3) {
			//strong
			$progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass3);
			$progressBar.html(settings.ratings[3]);
		}
		else if (result.score == 4) {
			//very strong
			$progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass4);
			$progressBar.html(settings.ratings[4]);
		}

		if (settings.scoreInput) {
			$(settings.scoreInput).val(result.score);
		}
		if (settings.warningTarget) {
			$(settings.warningTarget).html(result.feedback.warning);
		}
		if (settings.suggestionsTarget) {
			if (result.feedback.suggestions.length > 0) {
				const listItemsHtml = result.feedback.suggestions.map(suggestion => `<li>${suggestion}</li>`).join('');
				$(settings.suggestionsTarget).html(listItemsHtml);
			} else {
				$(settings.suggestionsTarget).html('');
			}
		}
	}

	return this.each(function () {
		const $progressBar = $(this);

		// Init progress bar display
		updateProgressBar($progressBar);

		// Update progress bar on each keypress of password input
		$(settings.passwordInput).on('keyup', () => updateProgressBar($progressBar));
	});
};

type doubleScrollParams = {
	$insertBefore?: $;
	contentElement?: $;
	scrollCss?: any;
	contentCss?: any;
	onlyIfScroll?: boolean;
	resetOnWindowResize?: boolean;
	timeToWaitForResize?: number;
};
$.fn.doubleScroll = function (userOptions) {
	//Merge user options with default options
	const options: doubleScrollParams = {
		$insertBefore: undefined, // Insert second scroll bar before this element, if not specified main element will be used

		contentElement: undefined, // Widest element, if not specified first child element will be used
		scrollCss: {
			'overflow-x': 'auto',
			'overflow-y': 'hidden',
			height: '20px',
		},
		contentCss: {
			'overflow-x': 'auto',
			'overflow-y': 'hidden',
		},
		onlyIfScroll: true, // top scrollbar is not shown if the bottom one is not present
		resetOnWindowResize: true, // recompute the top ScrollBar requirements when the window is resized
		timeToWaitForResize: 30, // wait for the last update event (usefull when browser fire resize event constantly during ressing)

		...userOptions,
	};

	const internalOptions = {
		topScrollBarMarkup: '<div class="doubleScroll-scroll-wrapper"><div class="doubleScroll-scroll"></div></div>',
		topScrollBarInnerSelector: '.doubleScroll-scroll',
	};

	const showScrollBar = ($self: $, $topScrollBar: $ | null = null) => {
		// add div that will act as an upper scroll only if not already added to the DOM
		if (!$topScrollBar) {
			const $insertBefore = options.$insertBefore || $self;

			// creating the scrollbar
			// added before in the DOM
			$topScrollBar = $(internalOptions.topScrollBarMarkup);
			$insertBefore.before($topScrollBar);

			// apply the css
			$topScrollBar.css(options.scrollCss);
			$(internalOptions.topScrollBarInnerSelector).css({ height: '20px' });
			$self.css(options.contentCss);

			let scrolling = false;

			// bind upper scroll to bottom scroll
			$topScrollBar.on('scroll.doubleScroll', () => {
				if (scrolling) {
					scrolling = false;
					return;
				}
				scrolling = true;
				$self.scrollLeft($topScrollBar!.scrollLeft() || 0);
			});

			// bind bottom scroll to upper scroll
			$self.on('scroll.doubleScroll', () => {
				if (scrolling) {
					scrolling = false;
					return;
				}
				scrolling = true;
				$topScrollBar!.scrollLeft($self.scrollLeft() || 0);
			});
		}

		const hideScroll = options.onlyIfScroll && $self.get(0)!.scrollWidth <= Math.round($self.width() || 0);
		if (hideScroll) $topScrollBar.hide();
		else $topScrollBar.show();

		// find the content element (should be the widest one)
		let $contentElement: $;
		if (options.contentElement !== undefined && $self.find(options.contentElement).length !== 0) {
			$contentElement = $self.find(options.contentElement);
		} else {
			$contentElement = $self.find('>:first-child');
		}

		// set the width of the wrappers
		$topScrollBar.add($topScrollBar.find(internalOptions.topScrollBarInnerSelector)).width($contentElement.outerWidth() || 0);
		$topScrollBar.width($self.width() || 0);
		$topScrollBar.scrollLeft($self.scrollLeft() || 0);

		return $topScrollBar;
	};

	return this.each((_, element) => {
		const $self = $(element);

		const $topScrollBar = showScrollBar($self);

		// bind the resize handler
		// do it once
		if (options.resetOnWindowResize) {
			let timeout: ReturnType<typeof setTimeout> | null = null;
			$(window).on('resize.doubleScroll', () => {
				// adding/removing/replacing the scrollbar might resize the window
				// so the resizing flag will avoid the infinite loop here...
				if (timeout) clearTimeout(timeout);
				timeout = setTimeout(() => showScrollBar($self, $topScrollBar), options.timeToWaitForResize);
			});
		}
	});
};

var debugtrace = true;
var debugshowerrors = true;
var debugsenderrors = true;

export const logme = (message, table: boolean = false) => {
	try {
		if (!debugtrace) return;
		if (!table && console.log) console.log(message);
		if (table && console.table) console.table(message);
	} catch (error) {}
};

export const logerror = (s, e, d = null) => {
	try {
		if (debugshowerrors) {
			if (console.error) {
				console.error(s);
				console.error(e);
				if (d) console.error(d);
			} else if (console.log) {
				console.log(s);
				console.log(e);
				if (d) console.log(d);
			}
		}
		if (debugsenderrors) {
			console.log('attempting to send');
			const postData = {
				buildnumber,
				location: window.location.href,
				s,
				e,
				d,
				useragent: navigator.userAgent,
				stack: new Error().stack,
			};
			ajaxPromise('/logerrors/record', { data: postData });
		}
	} catch (error) {
		console.error('error in logerror?');
		console.error(error);
	}
};

export const ajaxPromise = (url: string, postData?: object) => new Promise((resolve: (res: any) => void, reject) => {
	let ajaxParams: JQueryAjaxSettings = {
		type: 'POST',
		url,
		data: postData,
		dataType: 'json',
		success: (res) => resolve(res),
		error: (a, b, c) => reject(c),
	};
	if (postData instanceof FormData) {
		ajaxParams = {
			...ajaxParams,
			processData: false,
			contentType: false,
			enctype: 'multipart/form-data',
		};
	}
	$.ajax(ajaxParams);
});

export const empty = (str: string) => !str || str.trim().length === 0;

export const capitalizeFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

/** Fetch post wrapper that works with PHP $_POST and throws responses that don't have {rc: 'OK'} */
export const fetchPost = async (url: string, postData?: object | FormData) => {
	let requestBody: RequestInit['body'] = null;
	const headers: RequestInit['headers'] = {
		Accept: 'application/json',
	};
	if (postData instanceof FormData) {
		requestBody = postData;
	} else if (postData !== undefined) {
		requestBody = JSON.stringify(postData);
		headers['Content-Type'] = 'application/json';
	}
	const response = await fetch(url, {
		method: 'POST',
		headers,
		body: requestBody,
	});
	const json = await response.json();
	if (json.hasOwnProperty('rc') && json.rc !== 'OK') throw json;
	return json;
};

export const numToPaddedStr = (num: number, size: number) => {
	const numStr = num.toString();
	const sizeToAdd = size - numStr.length;
	const padding = sizeToAdd > 0 ? '0'.repeat(sizeToAdd) : '';
	return padding + numStr;
};

export const filesizePretty = (bytes: number) => {
	if (bytes > 1048576) return Math.round(bytes / 1048576) + ' MB';
	else if (bytes > 1024) return Math.round(bytes / 1024) + ' KB';
	else return bytes + ' B';
};

const monthNameShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const getMonthNameShort = (ms: number) => {
	const date = new Date(ms);
	return monthNameShort[date.getMonth()] + ' ' + date.getFullYear();
};
export const getDateShort = (dateVal: string | number | Date) => {
	let date: Date;
	if (typeof dateVal === 'string') {
		const parts = dateVal.split('-');
		date = new Date(+parts[0], +parts[1] - 1, +parts[2]);
	} else date = new Date(dateVal);
	return monthNameShort[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
};

/** Post to the provided URL with the specified parameters. */
export const post = (path: string, parameters: object | null, target: string = null) => {
	const $form = $('<form></form>');

	$form.attr('method', 'post');
	$form.attr('action', path);
	if (target != null) $form.attr('target', target);

	if (parameters !== null) {
		for (const [key, value] of Object.entries(parameters)) {
			const $input = $('<input></input>');

			$input.attr('type', 'hidden');
			$input.attr('name', key);
			$input.attr('value', value);

			$form.append($input);
		}
	}

	// The form needs to be a part of the document in
	// order for us to be able to submit it.
	$(document.body).append($form);
	$form.trigger('submit');
};

export const inputFloatMinMax = ($inputs: $, min: number, max: number) => {
	$inputs.each((_, element) => {
		const $input = $(element);
		$input.off('change').on('change', () => {
			const num = parseFloat($input.val().toString());
			if (isNaN(num)) {
				$input.val(min);
				highlight($input);
				return;
			}

			if (num < min || num > max) highlight($input);
			const wrappedNum = +Math.min(Math.max(min, num), max).toFixed(2);
			$input.val(wrappedNum);
		});
	});
};

export const inputIntMinMax = ($inputs: $, min: number, max: number, allowBlank: boolean = false) => {
	$inputs.each((_, element) => {
		const $input = $(element);
		$input.off('change').on('change', () => {
			if (allowBlank && $input.val() === '') return;
			const num = parseFloat($input.val().toString());
			if (isNaN(num)) {
				$input.val(min);
				highlight($input);
				return;
			}

			if (num < min || num > max) highlight($input);
			const wrappedNum = +Math.min(Math.max(min, num), max);
			$input.val(wrappedNum);
		});
	});
};

export const validEmail = (input: string) => {
	const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	return !!input.match(regex);
};

export const validUrl = (url: string) => {
	const regex = new RegExp(/^(http|https):\/\/[^ "]+\.[^ "]+$/);
	return regex.test(url);
};

export const setTimeoutPromise = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

type displayNotificationParams = { context: string; msg?: string; style?: BootstrapFlavor; time?: number };
export const displayNotification = (context: string | displayNotificationParams, msg: string = '', style: BootstrapFlavor = 'success', time: number = 4000) => {
	if (typeof context === 'object') {
		displayNotification(context.context, context.msg, context.style, context.time);
		return;
	}

	const $alert = $(
		<div className={`alert alert-${style} alert-dismissible`} role="alert" data-dismiss="alert">
			<strong>{context}</strong>
			<p>{msg}</p>
		</div>
	);
	if (!$('#notification_tray').length) $('body').append(<div id="notification_tray"></div>);
	$('#notification_tray').append($alert);
	$alert.delay(time - 500).fadeOut(500, () => $alert.remove());
	logme(`${context}: ${msg}`);
};

export const setCollapse = ($btn: $, $target: $, callback?: () => void) => {
	$btn.off('click').on('click', () => $target.collapse('toggle'));
	$target.off('show.bs.collapse').on('show.bs.collapse', () => {
		if (callback) callback();
	});
	$target.off('shown.bs.collapse').on('shown.bs.collapse', () => {
		$btn.find('i').addClass('fa-caret-up').removeClass('fa-caret-down');
	});
	$target.off('hidden.bs.collapse').on('hidden.bs.collapse', () => {
		$btn.find('i').addClass('fa-caret-down').removeClass('fa-caret-up');
	});
};

export const toggleSlide = ($btn: $, $target: $, callback?: () => void) => {
	const showTarget = () => {
		$target.slideDown(250).trigger('3pt.toggle.show');
		if ($btn.children('.3pt-toggle-show').length || $btn.children('.3pt-toggle-hide').length) {
			$btn.children('.3pt-toggle-show').show();
			$btn.children('.3pt-toggle-hide').hide();
		} else $btn.children('i').addClass('fa-caret-up').removeClass('fa-caret-down');
		if (callback) callback();
	};
	const hideTarget = () => {
		$target.slideUp(250).trigger('3pt.toggle.hide');
		if ($btn.children('.3pt-toggle-show').length || $btn.children('.3pt-toggle-hide').length) {
			$btn.children('.3pt-toggle-show').hide();
			$btn.children('.3pt-toggle-hide').show();
		} else $btn.children('i').addClass('fa-caret-down').removeClass('fa-caret-up');
	};
	$btn.off('click').on('click', () => {
		if ($target.css('display') === 'none') showTarget();
		else hideTarget();
	});
};

type confirmDialogParams = {
	dialogTitle: string;
	bodyText: string;
	confirmText: string;
	confirmStyle: BootstrapFlavor;
	cancelText?: string;
	showCancelButton?: boolean;
};
export const confirmDialog = ({ dialogTitle, bodyText, confirmText, confirmStyle, cancelText = 'Cancel', showCancelButton = true }: confirmDialogParams) => new Promise((resolve: (confirmed: boolean) => void) => {
	$('#confirm_dialog').find('.modal-body').html(bodyText);
	$('#confirm_dialog_label').text(dialogTitle);

	$('#confirm_dialog_actions').html('');
	const $actionBtn = $(
		<button type="button" className={`btn btn-${confirmStyle}`} disabled>{confirmText}</button>
	);
	$actionBtn.off('click').on('click', () => {
		$actionBtn.prop('disabled', true);
		resolve(true);
		$('#confirm_dialog').modal('hide');
	});
	setTimeout(() => $actionBtn.prop('disabled', false), 1500);
	$('#confirm_dialog_actions').append($actionBtn);

	$('#confirm_dialog_cancel').off('click').text('').hide();
	if (showCancelButton) $('#confirm_dialog_cancel').show().text(cancelText);
	else $('#confirm_dialog_cancel').hide();

	$('#confirm_dialog').off('hide.bs.modal').on('hide.bs.modal', () => resolve(false));

	$('#confirm_dialog').modal();
});

type optionsDialogParams = {
	dialogTitle: string;
	bodyText: string;
	buttons: {
		id: any;
		confirmText: string;
		confirmStyle: BootstrapFlavor;
	}[];
	cancelText?: string;
	showCancelButton?: boolean;
};
export const optionsDialog = ({ dialogTitle, bodyText, buttons, cancelText = 'Cancel', showCancelButton = true }: optionsDialogParams) => new Promise((resolve: (confirmed: boolean) => void) => {
	$('#confirm_dialog').find('.modal-body').text(bodyText);
	$('#confirm_dialog_label').text(dialogTitle);

	$('#confirm_dialog_actions').html('');
	buttons.forEach(({ id, confirmText, confirmStyle }) => {
		const $actionBtn = $(
			<button type="button" className={`btn btn-${confirmStyle}`} disabled>{confirmText}</button>
		);
		$actionBtn.off('click').on('click', () => {
			$actionBtn.prop('disabled', true);
			resolve(id);
			$('#confirm_dialog').modal('hide');
		});
		setTimeout(() => $actionBtn.prop('disabled', false), 1500);
		$('#confirm_dialog_actions').append($actionBtn);
	});

	$('#confirm_dialog_cancel').off('click').html('').hide();
	if (showCancelButton) $('#confirm_dialog_cancel').show().text(cancelText);
	else $('#confirm_dialog_cancel').hide();

	$('#confirm_dialog').off('hide.bs.modal').on('hide.bs.modal', () => resolve(null));

	$('#confirm_dialog').modal();
});

export const fileInput = ($file: $) => {
	const $cont = $file.parent('.file-cont');
	const $label = $cont.find('.file-label');
	const $text = $cont.find('.file-text');
	const $icon = $cont.find('.file-icon');
	const defaultText = $text.text();
	const defaultClasses = $icon.attr('class');
	$file
		.on('dragover dragenter', () => $label.addClass('file-dragover'))
		.on('dragleave dragend drop', () => {
			$label.removeClass('file-dragover');
			$file.trigger('change');
		})
		.on('change', () => {
			const file = ($file[0] as any).files[0];
			if (file) {
				$text.text(file.name);
				$icon.attr('class', 'file-icon fa fa-file');
			} else {
				$text.text(defaultText);
				$icon.attr('class', defaultClasses);
			}
		});
};

// https://stackoverflow.com/questions/19305821/multiple-modals-overlay
$(document).on('show.bs.modal', '.modal', () => {
	const $modal = $(this);
	const zIndex =
		Math.max.apply(
			null,
			Array.prototype.map.call(document.querySelectorAll('*'), (element) => +element.style.zIndex)
		) + 10;
	$modal.css('z-index', zIndex);
	setTimeout(() => {
		$('.modal-backdrop')
			.not('.modal-stack')
			.css('z-index', zIndex - 1)
			.addClass('modal-stack');
	}, 0);
});

$(document).on('hidden.bs.modal', '.modal', () => {
	if ($('.modal:visible').length) $(document.body).addClass('modal-open');
});

export const highlight = ($target: $) => {
	$target.effect({
		effect: 'highlight',
		color: '#67a0e4',
		duration: 1500,
	});
};

export const getDtRowData = (dt: DataTables.Api, element: HTMLElement): any => {
	let $tr = $(element).closest('tr');
	if ($tr.hasClass('child')) $tr = $tr.prev();
	return dt.row($tr).data();
};

export const dtColumnIndicies = (columns: DataTables.ColumnSettings[]) => columns.reduce((indicies, col, index) => ({ ...indicies, [col.data as string]: index }), {}) as Record<string, number>;

export const notUsingIe = () => {
	const ua = window.navigator.userAgent;
	const msie = ua.indexOf('MSIE');
	return msie == -1 && !ua.match(/Trident.*rv\:11\./);
};

export const triggerSubmit = ($form: $) => {
	const $submit = $('<button type="submit" style="display: none" />');
	$submit.appendTo($form).trigger('click').remove();
};

export const triggerWindowResize = () => {
	const resizeEvent = window.document.createEvent('UIEvents');
	resizeEvent.initUIEvent('resize', true, false, window, 0);
	window.dispatchEvent(resizeEvent);
};

export const disableColoredSelect = ($select: $) => {
	const bgColor = $select.css('background-color');
	$select
		.css({ cursor: 'not-allowed', 'background-color': bgColor + ' !important', opacity: 0.7 })
		.addClass('3pt-disabled')
		.attr('disabled', 'disabled');
};

export const enableColoredSelect = ($select: $) => {
	const bgColor = $select.css('background-color');
	$select
		.css({ cursor: 'not-allowed', 'background-color': bgColor + ' !important', opacity: 0.7 })
		.removeClass('3pt-disabled')
		.attr('disabled', 'disabled');
};

export const initDateFilter = (id: string) => {
	const $filter = $(id);
	$filter.on('change', () => {
		const $formGroup = $filter.closest('.form-group');
		const $firstFormGroup = $formGroup.find('.first').closest('.form-group');
		const $secondFormGroup = $formGroup.find('.second').closest('.form-group');
		switch ($filter.val()) {
			case 'less':
			case 'greater':
				$firstFormGroup.show();
				$secondFormGroup.hide();
				break;
			case 'between':
				$firstFormGroup.show();
				$secondFormGroup.show();
				break;
			default:
				$firstFormGroup.hide();
				$secondFormGroup.hide();
				break;
		}
	});
};

type dateFilterOperator = 'less' | 'greater' | 'between' | 'novalue' | 'tweek' | 'lweek' | 'tmonth' | 'lmonth' | 'tyear' | 'lyear';
type dateFilterVal = {
	operator: dateFilterOperator;
	first?: string;
	second?: string;
};

export const getDateFilter = (id: string): dateFilterVal => {
	const operator = $(id).val();
	switch (operator) {
		case 'less':
		case 'greater':
			return {
				operator,
				first: $(`${id}_first`).val(),
			};
		case 'between':
			return {
				operator,
				first: $(`${id}_first`).val(),
				second: $(`${id}_second`).val(),
			};
		case 'novalue':
		case 'tweek':
		case 'lweek':
		case 'tmonth':
		case 'lmonth':
		case 'tyear':
		case 'lyear':
			return { operator };
		default:
			return null;
	}
};

export const setDateFilter = (id: string, filter: dateFilterVal) => {
	const $filter = $(id);
	const $first = $(`${id}_first`);
	const $second = $(`${id}_second`);
	const $firstFormGroup = $first.closest('.form-group');
	const $secondFormGroup = $second.closest('.form-group');
	switch (filter ? filter.operator : null) {
		case 'less':
		case 'greater':
			$firstFormGroup.show();
			$secondFormGroup.hide();
			break;
		case 'between':
			$firstFormGroup.show();
			$secondFormGroup.show();
			break;
		default:
			$firstFormGroup.hide();
			$secondFormGroup.hide();
			break;
	}
	if (!filter || !filter.operator) {
		$filter.val('-1');
		$first.val('');
		$second.val('');
		return;
	}

	$filter.val(filter.operator);
	if (filter.hasOwnProperty('first')) $first.val(filter.first);
	else $first.val('');
	if (filter.hasOwnProperty('second')) $second.val(filter.second);
	else $second.val('');
};

export const checkChosenChanged = ($select: $) => {
	const val = $select.val();
	const $choices = $select.parent().find('.chosen-choices, .chosen-single');
	if (val != -1 && val != '') $choices.addClass('changed');
	else $choices.removeClass('changed');
};

export const arraysEqual = (a: any[], b: any[]) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;
	return a.every((val, index) => val === b[index]);
};

//For babel jsx transpiling, from: https://blog.r0b.io/post/using-jsx-without-react/
export const createElement = (tagName: string, attrs: Record<string, any> | null, ...children: (HTMLElement | string)[]) => {
	if (tagName === 'fragment') return children;
	const elem = document.createElement(tagName);
	if (attrs) {
		for (const [key, val] of Object.entries(attrs)) {
			if (key.startsWith('data-')) {
				const dataName = key.substring(5);
				elem.dataset[dataName] = val;
			} else elem[key] = val;
		}
	}
	for (const child of children) {
		if (Array.isArray(child)) elem.append(...child);
		else elem.append(child);
	}
	return elem;
};

export const createFragment = 'fragment';

export const htmlToElement = (html: string | number) => {
	if (html === null || html === undefined) return '';
	const template = document.createElement('template');
	template.innerHTML = html.toString().trim();
	return template.content;
};

export const htmlEsc = (text: string) => {
	const span = document.createElement('span');
	span.textContent = text;
	return span.innerHTML;
};

/** Convert number to letter, using excel column lingo for numbers greater than 26 (AA, AB, etc.)
 *
 * Taken from:
 * https://stackoverflow.com/questions/8240637/convert-numbers-to-letters-beyond-the-26-character-alphabet#answer-64456745
 */
export const numberToLetter = (num: number) => {
	let letters = '';
	while (num >= 0) {
		letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
		num = Math.floor(num / 26) - 1;
	}
	return letters;
};

setTimeout(() => {
	// page load snippets
	$('.overflow-x-scroll').each((_, element) => {
		$(element).scrollLeft(9999);
	});
	$('[data-toggle="popover"]').popover({
		html: true,
		trigger: 'hover focus',
	});
	$('#spinner').hide();
}, 10);
