import { ajaxPromise, displayNotification, fetchPost, fileInput, logerror, post } from "./utils";

const initSsoSetup = () => {
	$('#sso_setup_back').off('click').on('click', () => post('/ui/setup', {start: 'authentication'}));

	$('#sso_setup_download_metadata').off('click').on('click', () => {
		post('/sso/download_xml_metadata', null, '_blank');
	});

	fileInput($('#sso_setup_file'));

	$('#sso_setup_file').on('change', async () => {
		const formData = new FormData($('#sso_setup_form').get(0) as HTMLFormElement);
		try {
			await fetchPost('/sso/parse_xml', formData);
			displayNotification('SSO Setup', 'Metadata imported successfully.', 'success');
			$('#sso_setup_toggle').prop('disabled', false);
		}
		catch (error) {
			logerror('sso parse_xml error', error);
			displayNotification('SSO Setup', 'There was an error parsing the XML file.', 'danger');
		}
	});

	$('#sso_setup_save').off('click').on('click', async () => {
		$('#sso_setup_save').prop('disabled', true);
		try {
			const postData = {
				sso_enabled: $('#sso_setup_enable').is(':checked') ? 1 : 0,
				sso_only: $('#sso_setup_sso_only').is(':checked') ? 1 : 0,
			};
			const res = await ajaxPromise('/form/submit', {type: 'setup_save', data: postData});
			if (res.rc !== 'OK') throw res;
			displayNotification('SSO Setup', 'SSO settings saved successfully.', 'success');
		}
		catch (error) {
			logerror('sso save error', error);
			displayNotification('SSO Setup', 'There was an error saving the SSO settings.', 'danger');
		}
		$('#sso_setup_save').prop('disabled', false);
	});
};

if ($('#sso_setup_cont').length) initSsoSetup();