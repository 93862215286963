import { LIB_TYPE_FILE, LIB_TYPE_LINK } from './constants';
import { ajaxPromise, checkChosenChanged, confirmDialog, displayNotification, fileInput, filesizePretty, getDtRowData, highlight, htmlEsc, logerror, post, toggleSlide, validUrl } from './utils';

const initResourceLibrary = () => {

	const clearEditForm = () => {
		$('#library_form_title').val('');
		$('#library_form_type').val(LIB_TYPE_FILE).trigger('change');
		$('#library_form_description').val('');
		$('#library_form_file').val('').trigger('change');
		$('#library_form_link').val('');
		$('#library_form_file_container_both').show();
	};

	const libraryEdit = (lib = null) => {
		clearEditForm();
		const isNew = lib === null;

		setTimeout(() => {
			highlight($('#library_edit_container'));
			$(window).scrollTop($('#library_edit_container').offset().top - 400);
		}, 400);

		if (isNew) {
			$('#library_form_file').prop('required', true);
			$('#library_form_file_cancel').off('click').hide();
			$('#library_form_file_change').off('click').hide();

			$('#library_form_file_stored_container').hide();
			$('#library_form_file_container').show();
			$('#library_form_file_stored_original').html('');
			$('#library_form_file_stored_content').html('');
			$('#library_form_file_stored_size').html('');

			$('#library_form_archive').off('click').hide();
		} else {
			$('#library_form_title').val(lib.title);
			$('#library_form_description').val(lib.desc);
			$('#library_form_type').val(lib.type).trigger('change');
			$('#library_form_link').val(lib.link || '');

			$('#library_form_file').prop('required', false);

			$('#library_form_file_stored_container').show();
			$('#library_form_file_container').hide();
			$('#library_form_file_stored_original').html(htmlEsc(lib.original));
			$('#library_form_file_stored_content').html(htmlEsc(lib.content));
			$('#library_form_file_stored_size').html(filesizePretty(+lib.size));

			$('#library_form_file_change').off('click').on('click', () => {
				$('#library_form_file_container').show();
				$('#library_form_file_stored_container').hide();
			}).show();

			$('#library_form_file_cancel').off('click').on('click', () => {
				$('#library_form_file').val('').trigger('change');
				$('#library_form_file_container').hide();
				$('#library_form_file_stored_container').show();
			}).show();

			$('#library_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Resource Delete',
					bodyText: 'Are you sure you would like to delete this resource?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const res = await ajaxPromise('/form/submit', {type: 'library_archive', data: lib.id});
					if (res.rc !== 'OK') throw res;
					libDt.ajax.reload(null, false);
					displayNotification('Delete Success', 'The resource was deleted successfully.', 'success');
					$('#library_edit_container').collapse('hide');
				} catch (error) {
					switch (error.rc) {
						case 'IN_USE':
							displayNotification('Delete Error', 'This resource is currently in use by a task and cannot be deleted.', 'danger');
							break;
						default:
							displayNotification('Delete Error', 'There was an error deleting this resource.', 'danger');
							logerror('library archive', error);
					}
				}
			});
		}

		$('#library_form_type').off('change').on('change', () => {
			const type = +$('#library_form_type').val();
			if (type == LIB_TYPE_FILE) {
				$('#library_form_file_container_both').show();
				$('#library_form_link_cont').hide();
			} else if (type == LIB_TYPE_LINK) {
				$('#library_form_file_container_both').hide();
				$('#library_form_link_cont').show();
			}
		}).trigger('change');

		$('#library_edit_container').collapse('show');

		$('#library_form_cancel').off('click').on('click', () => {
			$('#library_edit_container').collapse('hide');
			return false;
		});

		$('#library_form_submit').off('click').on('click', () => {
			$('#library_form').trigger('submit');
			return false;
		});

		const save = async (formData) => {
			$('#library_form_submit').prop('disabled', true);

			try {
				const res = await ajaxPromise('/form/submit', formData);
				if (res.rc !== 'OK') throw res;
				displayNotification('Save Success', 'The resource was saved successfully.', 'success');
				libDt.ajax.reload();
				$('#library_edit_container').collapse('hide');
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this resource.', 'danger');
				logerror('library save', error);
			}

			$('#library_form_submit').prop('disabled', false);
		};

		$('#library_form').off('submit').on('submit', async (event) => {
			event.preventDefault();
			const data = lib ? JSON.parse(JSON.stringify(lib)) : {};

			data.type = +$('#library_form_type').val();
			data.title = $('#library_form_title').val();
			data.description = $('#library_form_description').val();
			data.file = $('#library_form_file').val();
			data.link = $('#library_form_link').val();
			let bad = false;

			if (data.title == null || data.title.length == 0) {
				bad = true;
				displayNotification('Save Error', 'Please fill out the title.', 'danger');
			}
			if (isNew && data.type == LIB_TYPE_FILE && (data.file == '' || data.file.length == 0)) {
				bad = true;
				displayNotification('Save Error', 'Please include a file.', 'danger');
			}
			const formFile = $('#library_form_file').get(0) as HTMLInputElement;
			if (data.file.length > 0 && formFile.files[0].size >= 26214400) {
				bad = true;
				displayNotification('Save Error', 'The file chosen exceeds the size limit of 25MB.', 'danger');
			}
			if (isNew && data.type == LIB_TYPE_LINK && data.link == '') {
				bad = true;
				displayNotification('Save Error', 'Please include a link.', 'danger');
			}

			if (data.type == LIB_TYPE_LINK && data.link !== '' && !validUrl(data.link)) {
				bad = true;
				displayNotification({
					context: 'Save Error',
					msg: 'The link given is not valid, it must start with "http://" or "https://".',
					style: 'danger',
					time: 6000,
				});
			}

			if (bad) return;

			const formData = new FormData($('#library_form').get(0) as HTMLFormElement);
			formData.set('type', 'library_save');
			formData.set('data', JSON.stringify(data));

			if (!isNew && data.file != null && data.file.length > 0) {
				const confirmed = await confirmDialog({
					dialogTitle: 'Resource Replace',
					bodyText: 'Are you sure you would like to replace the file? The original file will be overwritten.',
					confirmText: 'Replace',
					confirmStyle: 'warning',
				});
				if (!confirmed) return;
			}

			save(formData);
		});
	};

	$('#library_edit_container').on('hidden.bs.collapse', () => clearEditForm());

	fileInput($('#library_form_file'));

	const initFilters = () => {
		$('#lib_filter_container select.chosen').chosen({ width: '100%' });
		checkFiltersChosenChanged();
		$('#lib_filter_container select.chosen').on('change', ({target}) => {
			const $select = $(target);
			checkChosenChanged($select);
		});
		toggleSlide($('#lib_filter_toggle'), $('#lib_filter_container'));

		$('#lib_filter_container select').on('change', () => libDt.ajax.reload());

		const defaultFilters = getFilters();
		$('#lib_filter_reset').off('click').on('click', () => {
			setFilters(defaultFilters);
			checkFiltersChosenChanged();
			libDt.ajax.reload();
		});
	};

	const getFilters = () => ({
		type: $('#lib_filter_type').val(),
	});

	const setFilters = (filters) => {
		$('#lib_filter_type').val(filters.type);
		$('#lib_filter_container select.chosen').trigger('chosen:updated');
	};

	const checkFiltersChosenChanged = () => {
		$('#lib_filter_container select.chosen').each((_, element) => checkChosenChanged($(element)));
	};

	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/library_load',
			data: (postData) => ({
				...postData,
				filters: getFilters(),
			}),
		},
		buttons: [
			{
				text: '<i class="fa fa-plus me-2"></i> Add Resource',
				className: 'ml-2 btn btn-sm btn-success',
				action: () => libraryEdit(),
			},
			{
				text: '<i class="fa fa-envelope me-2"></i> Send',
				className: 'send ml-2 btn btn-sm btn-info',
				action: () => {
					const libs = senddableLibs();
					sendLibs(libs);
				},
			},
			{
				text: '<i class="fa fa-file-csv me-2"></i> Export',
				className: 'ml-2 btn btn-sm btn-secondary',
				action: ({target}) => {
					const filters = {
						libs: libDt.rows({ selected: true }).data().toArray().map((lib) => +lib.lib_id),
					};

					// Submit the form
					$(target).prop('disabled', true);
					post('/export', { type: 'library', data: JSON.stringify(filters) });
					setTimeout(() => $(target).prop('disabled', false), 1000);
				},
			},
		],
		columns: [
			{
				data: null,
				defaultContent: '',
				orderable: false,
				searchable: false,
				className: 'select-checkbox',
				render: null,
			},
			{
				title: 'Title',
				data: 'lib_title',
			},
			{
				title: 'Type',
				data: 'lib_type_pretty',
				searchable: false,
			},
			{
				title: 'Description',
				data: 'lib_desc',
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ targets: [-1], responsivePriority: 1, render: null },
			{ targets: '_all', render: (val) => htmlEsc(val) },
		],
		order: [[1, 'asc']],
		select: {
			style: 'os',
			selector: 'td:first-child',
		},
	};

	const libDt = $('#library_table').DataTable(dtOptions);

	const senddableLibs = () => libDt.rows({ selected: true }).data().toArray();

	libDt.on('draw', () => {
		$('#library_table').find('.select-all').removeClass('selected').off('click').on('click', ({target}) => {
			$(target).toggleClass('selected')
			if ($(target).hasClass('selected')) libDt.rows().select();
			else libDt.rows().deselect();
		});
		libDt.buttons(['.send']).disable();
	});

	libDt.on('select', () => {
		if (senddableLibs().length) libDt.buttons(['.send']).enable();
	});

	libDt.on('deselect', () => {
		if (!senddableLibs().length) libDt.buttons(['.send']).disable();
	});

	$('#header_showrelationships').on('change', () => libDt.ajax.reload());

	$('#library_table').on('click', '.library_view', ({ target }) => {
		const rowData = getDtRowData(libDt, target);
		const url = rowData.lib_type == LIB_TYPE_LINK ? new URL(rowData.lib_link) : `/resource/get/${rowData.lib_hash}`;
		window.open(url, '_blank');
	});

	//Resource send modal reset when hidden
	$('#library_send_container').on('hidden.bs.modal', () => {
		$('#library_send_input').val('');
		$('#library_send_comments').val('');
		$('#library_send_submit').off('click');
	});

	const sendLibs = (libs) => {
		//Modal 'Send' handler
		$('#library_send_submit').on('click', async (event) => {
			event.preventDefault();
			const emails = $('#library_send_input').val().toString();
			if (!emails) {
				displayNotification('Resource Send', 'Please enter an email address.', 'danger');
				return;
			}

			try {
				const postData = {
					type: 'library_send',
					data: {
						ids: libs.map((lib) => +lib.lib_id),
						emails: emails,
						comments: $('#library_send_comments').val().toString(),
					},
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;
				displayNotification('Resource Send', 'Resource sent succesfully.', 'success');
				$('#library_send_container').modal('hide');
			}
			catch (error) {
				switch (error.rc) {
					case 'BAD_EMAIL':
						displayNotification('Resource Send', `The following email address is invalid: "${error.email}".  Remember to separate multiple email addresses with commas.`, 'danger');
						break;
					default:
						displayNotification('Resource Send', 'There was an error sending the resource.', 'danger');
						logerror('library send submit', error);
						break;
				}
			}
		});

		//Show modal
		$('#library_send_container').modal('show');
	};

	$('#library_table').on('click', '.library_send', ({ target }) => {
		const lib = getDtRowData(libDt, target);
		sendLibs([lib]);
	});

	$('#library_table').on('click', '.library_edit', ({ target }) => {
		const rowData = getDtRowData(libDt, target);
		libraryEdit({
			id: rowData.lib_id,
			type: rowData.lib_type,
			title: rowData.lib_title,
			desc: rowData.lib_desc,
			link: rowData.lib_link,
			original: rowData.lib_original,
			content: rowData.lib_content,
			size: rowData.lib_size,
		});
	});

	initFilters();
}

if ($('#library_container').length == 1) initResourceLibrary();