import Cookies from 'js-cookie';
import Highcharts from 'highcharts';

import { ajaxPromise, checkChosenChanged, confirmDialog, displayNotification, dtColumnIndicies, fileInput, filesizePretty, getDateFilter, getDateShort, getDtRowData, highlight, htmlEsc, initDateFilter, inputFloatMinMax, logerror, logme, post, setCollapse, setDateFilter, setTimeoutPromise, toggleSlide, triggerWindowResize, validUrl } from './utils';
import { ACCESS_ASSESSMENT, ACCESS_CONTRACTS, ACCESS_DETAILS, ACCESS_DILIGENCE, ACCESS_DOCUMENTS, ACCESS_INHERENT, ACCESS_REVIEW, ACCESS_VALUE, ASSESSMENT_DILIGENCE, ASSESSMENT_INHERENT, ASSESSMENT_OFFBOARDING, ASSESSMENT_ONBOARDING, ASSESSMENT_REVIEW, ASSESSMENT_VENDORVALUE, NOTIFY_AUTO, NOTIFY_MANUAL, NOTIFY_MANUAL_AND_AUTO, PRIV_MANAGE, PRIV_NONE, PRIV_READ, PRIV_WRITE, RELOWNER_ANALYST, RELOWNER_NONE, RELOWNER_PRIMARY, RELOWNER_SECONDARY, SUBTASK_DD, SUBTASK_IR, SUBTASK_PR, SUBTASK_RD, SURVEY_COMPLETE, SURVEY_TYPE_DD, SURVEY_TYPE_IR, SURVEY_TYPE_PR, TASK_NOTIFY_MYSELF, VENDOR_ACTIVE, VENDOR_ARCHIVED, VENDOR_PENDING } from './constants';
import { buildCharts, contactsTableBuild, contractOverallPerformanceTooltipFormatter, contractsTableBuild, highchartsLine, loadConcentrationMap, loadTaskDescs, loadVendorEdit, printThis, assessmentsTableBuild, sparklineDefaults, vendorPerformanceTooltipFormatter, highchartsPie } from './main';
import { historyTable } from './history';

if ($('#vendor_form_container').length == 1) {
	const jsData = JSON.parse(atob($('#vendor_form_container').data('js')));

	// Vendor Edit
	let vendor: any = {
		id: jsData.id,
		category: jsData.category,
		status: VENDOR_ACTIVE,
		readonly: parseInt($('#vendor_form').data('readonly')), // NEEDED TO ENFORCE DISABLED ATTRIBUTE AFTER LOADING VENDOR DATA
	};
	const isNewVendor = vendor.id === null;
	let currentDate = jsData.date;
	let user_is_external = !!jsData.isExternal;

	let vendChartSla: Highcharts.Chart = null;
	let vendChartKpi: Highcharts.Chart = null;
	let contractChartSla: Highcharts.Chart = null;
	let contractChartKpi: Highcharts.Chart = null;
	let contractChartRisk: Highcharts.Chart = null;
	let inherentdt: DataTables.Api = null,
		diligencedt: DataTables.Api = null,
		reviewdt: DataTables.Api = null,
		vendorvaluedt: DataTables.Api = null,
		contractsdt: DataTables.Api = null,
		categoryreqdocdt: DataTables.Api = null,
		doctDt: DataTables.Api = null;

	$('select#vendor_form_businessunit').chosen({ width: '100%' });
	$('select#vendor_form_offices').chosen({ width: '100%' });
	$('#vendor_form_office_countries').chosen({ width: '100%' });

	if (!isNewVendor) {
		$('#task_add_modal').on('show.bs.modal', () => {
			$('#task_add_modal').find('#task_add_vendor').val([vendor.id]).trigger('chosen:updated');
		});
	}

	const commonCountries = $('#vendor_form_office_countries_common > option').toArray().map((option: HTMLOptionElement) => option.value);
	const setSelectedCountries = (selectedCountries) => {
		commonCountries.forEach((abbr) => {
			const $options = $('#vendor_form_office_countries').find(`option[value=${abbr}]`);
			if (selectedCountries.includes(abbr)) {
				$options.hide();
				if ($options.toArray().every((option: HTMLOptionElement) => !option.selected)) $options.eq(0).attr('selected', 'selected');
			}
			else $options.show();
		});
		selectedCountries.filter((abbr) => abbr && !commonCountries.includes(abbr)).forEach((abbr) => {
			$(`#vendor_form_office_countries option[value=${abbr}]`).attr('selected', 'selected');
		});
		$('#vendor_form_office_countries').trigger('chosen:updated');
	};
	$('#vendor_form_office_countries').on('change', () => {
		const selectedCountries = $('#vendor_form_office_countries').val() as string[];
		setSelectedCountries(selectedCountries);
	});

	const setTitle = (title: string) =>
		$('#vendor_form_title')
			.empty()
			.text(title + ' ')
			.append('<small>Vendor Edit</small>');

	const opsCenterTableBuild = (vend_id: number, mode: 'READ' | 'MANAGE') => {
		const dtOptions: DataTables.Settings = {
			ajax: {
				url: '/data/opscenter_load',
				data: (postData) => ({
					...postData,
					vend_id,
					mode,
				}),
			},
			columns: [
				{
					title: 'Name',
					data: 'opscenter_name',
				},
				{
					title: 'Description',
					data: 'opscenter_desc',
				},
				{
					title: 'City',
					data: 'opscenter_city',
				},
				{
					title: 'State',
					data: 'opscenter_state_nice',
					searchable: false,
				},
				{
					title: 'Country',
					data: 'opscenter_country_nice',
					searchable: false,
				},
			],
			columnDefs: [
				{ responsivePriority: 1, targets: 0 },
			],
		};

		if (mode === 'MANAGE') {
			dtOptions.columns.push({
				title: 'Actions',
				data: 'actions',
				orderable: false,
				searchable: false,
				width: '3em',
			});
			dtOptions.columnDefs.push({ targets: -1, render: null, responsivePriority: 2 });
		}
		dtOptions.columnDefs.push({ targets: '_all', render: (val) => htmlEsc(val) })

		const dt = $('#opscenter_table')
			.append(`<thead><tr>${dtOptions.columns.map(({ title }) => `<th>${title}</th>`)}</tr></thead>`)
			.DataTable(dtOptions);

		if (mode === 'MANAGE') {
			$('#opscenter_table').on('click', '.opscenter_edit', ({ target }) => {
				const data = getDtRowData(dt, target);
				editOptsCenter(data);
			});
		}

		const resetOptsCenterEdit = () => {
			$('#opscenter_edit_container :input').val('');
			$('#opscenter_delete').off('click');
			$('#opscenter_delete').off('click').hide();
		};
		const closeOptsCenterEdit = () => {
			$('#opscenter_edit_container').one('hidden.bs.collapse', () => resetOptsCenterEdit());
			$('#opscenter_edit_container').collapse('hide');
		};

		const editOptsCenter = (ops?) => {
			resetOptsCenterEdit();

			if (ops) {
				$('#opscenter_name').val(ops.opscenter_name);
				$('#opscenter_desc').val(ops.opscenter_desc);
				$('#opscenter_address1').val(ops.opscenter_address1);
				$('#opscenter_address2').val(ops.opscenter_address2);
				$('#opscenter_city').val(ops.opscenter_city);
				$('#opscenter_state').val(ops.opscenter_state);
				$('#opscenter_zip').val(ops.opscenter_zip);
				$('#opscenter_country').val(ops.opscenter_country);

				$('#opscenter_delete')
					.show()
					.off('click')
					.on('click', async (event) => {
						event.preventDefault();
						const $btn = $(event.target);
						try {
							$btn.prop('disabled', true);
							const postData = {
								vend_id,
								opscenter_id: ops.opscenter_id,
							};
							const res = await ajaxPromise('/form/submit', { type: 'opscenter_delete', data: postData });
							if (res.rc !== 'OK') {
								displayNotification('Operation Center Delete', 'There was an error deleting the operation center.', 'danger');
								return;
							}
							dt.ajax.reload();
							displayNotification('Operation Center Delete', 'Operation center deleted.', 'success');
							closeOptsCenterEdit();
						} catch (error) {
							logerror('operation center delete', error);
						} finally {
							$btn.prop('disabled', false);
						}
					});
			}

			$('#opscenter_save')
				.off('click')
				.on('click', async (event) => {
					event.preventDefault();
					const $btn = $(event.target);
					const postData: any = {
						vend_id: vendor.id,
						opscenter_id: ops ? ops.opscenter_id : null,
						name: $('#opscenter_name').val(),
						description: $('#opscenter_desc').val(),
						address1: $('#opscenter_address1').val(),
						address2: $('#opscenter_address2').val(),
						city: $('#opscenter_city').val(),
						state: $('#opscenter_state').val(),
						zip: $('#opscenter_zip').val(),
						country: $('#opscenter_country').val(),
					};
					if (postData.name === '') {
						displayNotification('Operation Center Save', 'Please enter a valid name.', 'danger');
						return;
					}

					try {
						$btn.prop('disabled', true);
						const res = await ajaxPromise('/form/submit', { type: 'opscenter_save', data: postData });
						if (res.rc !== 'OK') {
							displayNotification('Operation Center Save', 'There was an error saving the operation center.', 'danger');
							return;
						}
						closeOptsCenterEdit();
						displayNotification('Operation Center Save', 'Operation Center Saved.', 'success');
						dt.ajax.reload();
					} catch (error) {
						logerror('operation center save error', error);
					} finally {
						$btn.prop('disabled', false);
					}
				});

			$('#opscenter_edit_container').collapse('show');
		};

		$('#opscenter_add')
			.off('click')
			.on('click', (event) => {
				event.preventDefault();
				editOptsCenter();
			});

		$('#opscenter_cancel')
			.off('click')
			.on('click', (event) => {
				event.preventDefault();
				closeOptsCenterEdit();
			});
	};

	if ($('#vendor_form_select').length) {
		$('#vendor_form_select').chosen({ width: '300px' });
		$('#vendor_form_select')
			.off('change')
			.change(() => {
				const newVendId = $('#vendor_form_select').val();
				post('/ui/vendor_edit', { id: newVendId });
			});
		$('#vendor_form_select_container').css('display', 'inline');
		$('#vendor_form_select_container').find('.chosen-single').css('font-weight', 'bold');
	}

	if ($('#vendor_tasks_body').length) {
		setCollapse($('#vendor_tasks_collapse'), $('#vendor_tasks_body'));
		$('#vendor_tasks_all').off('change').change(loadTaskDescs);
		loadTaskDescs();
	}

	//Vendor Details
	if ($('#vendor_form').length == 1) {
		const childVendorOptions = [];
		$('#vendor_form_children option').each((_, element) => {
			const $option = $(element);
			childVendorOptions.push({ vend_id: +$option.val(), vend_name: $option.text() });
		});

		if (vendor.id) {
			$('#vendor_form_name').off('change');
			$('#vendor_form_name').on('change', function () {
				setTitle($(this).val().toString());
			});
			if ($('#vendorid').length == 1) {
				$('#vendorid').empty().text(vendor.id.toString());
			}
		}

		const setNonContract = (noncontract) => {
			if (noncontract == 0) {
				// Contracted
				$('#contract_add').show();
				$('#contract_add_hidden').hide();
				$('#contract_add').prop('disabled', false);
			} else {
				// Non-Contract
				$('#contract_add').hide();
				$('#contract_add_hidden').show();
				$('#contract_add').prop('disabled', true);
			}
		}

		$('#vendor_form_noncontract').off('change');
		$('#vendor_form_noncontract').on('change', function () {
			setNonContract($(this).is(':checked') ? 1 : 0);
		});

		let vendordesc = jsData.descriptions;

		const vendorDisplayCatDesc = (category = null) => {
			const catId = category || ($('#vendor_form_category').get(0) as HTMLSelectElement).value;
			if (vendordesc.hasOwnProperty(catId)) {
				$('#vendor_form_category_description').empty().text(vendordesc[catId]);
			} else {
				$('#vendor_form_category_description').html(<em>No Category Description given</em>);
			}
		};

		$('#vendor_form_category')
			.off('change')
			.on('change', () => vendorDisplayCatDesc());

		const vendorDisplayWebsiteLink = () => {
			const url = $('#vendor_form_website').val().toString();
			if (!url.length) {
				// no text
				$('#vendor_form_website_link').html('');
				return;
			}
			if (!validUrl(url)) {
				$('#vendor_form_website_link').html(<i className="fa fa-ellipsis-h text-danger"></i>);
				return;
			}

			// good url
			$('#vendor_form_website_link').html(
				<a href={url} title={url} target="_blank">
					Link <i className="fa fa-external-link"></i>
				</a>
			);
		};

		$('#vendor_form_website')
			.off('keyup')
			.on('keyup', () => vendorDisplayWebsiteLink());
		$('#vendor_form_website')
			.off('change')
			.on('change', () => vendorDisplayWebsiteLink());

		// Charts build
		const buildPerformanceTab = (res) => {
			if (vendChartSla != null) {
				vendChartSla.destroy();
				vendChartSla = null;
			}
			if (vendChartKpi != null) {
				vendChartKpi.destroy();
				vendChartKpi = null;
			}

			if (res.rc !== 'OK') {
				$('#panel-charts-message').html('There is no Vendor data to show.').show();
				$('#panel-charts .row > div').each((_, element) => {
					$(element).find('div').html('');
					$(element).hide();
				});
			}

			if (res.data.hasOwnProperty('vend_sla') && $('#vendor_performance_sla_chart').length == 1) {
				const chartOptions: Highcharts.Options = {
					title: {
						text: 'SLA Performance',
					},
					yAxis: {
						categories: res.thresholds.sla.map((val) => htmlEsc(val)),
						title: {
							text: null,
						},
					},
					series: [
						{
							name: 'SLA Performance',
							data: res.data.vend_sla,
							type: 'line',
						},
					],
					tooltip: {
						formatter: function () {
							return contractOverallPerformanceTooltipFormatter(this, res.thresholds.sla);
						},
					},
					legend: {
						enabled: false,
					},
				}
				vendChartSla = Highcharts.chart('vendor_performance_sla_chart', Highcharts.merge(highchartsLine, chartOptions));
			};

			if (res.data.hasOwnProperty('vend_kpi') && $('#vendor_performance_kpi_chart').length == 1) {
				const chartOptions: Highcharts.Options = {
					title: {
						text: 'KPI Performance'
					},
					yAxis: {
						categories: res.thresholds.kpi.map((val) => htmlEsc(val)),
						title: {
							text: null,
						},
					},
					series: [
						{
							name: 'KPI Performance',
							data: res.data.vend_kpi,
							type: 'line',
						},
					],
					tooltip: {
						formatter: function () {
							return contractOverallPerformanceTooltipFormatter(this, res.thresholds.kpi);
						},
					},
					legend: {
						enabled: false,
					},
				}
				vendChartKpi = Highcharts.chart('vendor_performance_kpi_chart', Highcharts.merge(highchartsLine, chartOptions));
			}

			if (res.data.hasOwnProperty('services') && $('#vendor_performance_service_chart').length == 1 && res.data.services.length > 0) {
				res.data.services.forEach((chunk, chunkIndex) => {
					const chartElements = (
						<>
							<section>
								<p>
									<span className="h3">{chunk.name} </span>
									<button className="btn-collapse btn btn-sm btn-info">
										<i className="fa fa-fw fa-caret-down"></i>
									</button>
								</p>
								<div className="collapse">
									<table className="table">
										<tr>
											<th>SLA Aggregate</th>
											<th>KPI Aggregate</th>
										</tr>
										<tr>
											<td>{chunk.aggregate[0]}</td>
											<td>{chunk.aggregate[1]}</td>
										</tr>
									</table>
									{chunk.overall.length > 0 ? (
										<div className="row">
											{chunk.overall.map((overall, index) => (
												<div className="col-sm-6">
													<h4>{index === 0 ? 'SLA' : 'KPI'} Overall</h4>
													{overall.length > 0 ? <div id={`spark_overall_${chunkIndex}_${index}`} className="performance_chart"></div> : <p>There is no {index === 0 ? 'SLA' : 'KPI'} performance data to show at this time.</p>}
												</div>
											))}
										</div>
									) : (
										<p>There is no performance data to show at this time.</p>
									)}
									<hr />
									{chunk.questions.data && chunk.questions.data.length > 0 ? (
										<div className="row">
											{chunk.questions.data.map((questions, index) => (
												<div className="col-sm-6">
													<h4>{index === 0 ? 'SLA' : 'KPI'} Questions</h4>
													{questions.length > 0 ? (
														questions.map((question) => (
															<div>
																<h5>{question.text}</h5>
																<div id={`spark_${question.id}`} className="performance_chart"></div>
																<hr />
															</div>
														))
													) : (
														<p>There is no {index === 0 ? 'SLA' : 'KPI'} performance data to show at this time.</p>
													)}
												</div>
											))}
										</div>
									) : (
										<p>There is no performance data to show at this time.</p>
									)}
								</div>
							</section>
							<hr />
						</>
					);
					$('#vendor_performance_service_chart').append(chartElements);

					$('#vendor_performance_service_chart section').each((_, element) => {
						const $section = $(element);
						setCollapse($section.find('.btn-collapse'), $section.find('> div'));
					});

					// Draw Overall
					chunk.overall.forEach((overall, overallIndex) => {
						const overallChartId = `spark_overall_${chunkIndex}_${overallIndex}`
						if (!overall.length) {
							$(`#${overallChartId}`).html(<p class="text-center">No data to display.</p>);
							return;
						}

						const isSla = overallIndex === 0;
						const categories = isSla ? res.thresholds.sla : res.thresholds.kpi;
						const chartOptions: Highcharts.Options = {
							tooltip: {
								formatter: function () {
									return contractOverallPerformanceTooltipFormatter(this, isSla ? res.thresholds.sla : res.thresholds.kpi);
								},
							},
							yAxis: {
								categories: categories.map((val) => htmlEsc(val)),
								title: {
									text: null,
								},
							},
							chart: {
								renderTo: overallChartId,
							},
							series: [{
								data: overall,
								type: 'line',
							}],
						};
						new Highcharts.Chart(overallChartId, Highcharts.merge(sparklineDefaults, chartOptions));
					});

					// Draw Questions
					if (chunk.questions.data && chunk.questions.data.length) {
						chunk.questions.data.forEach((questions, typeIndex) => {
							questions.forEach((question) => {
								if (!question.data.length) {
									$(`#spark_${question.id}`).html(<p className="text-center">No data to display.</p>);
									return;
								}

								const showAnswerDetailsModal = (answer) => {
									const transData = chunk.questions.trans_data ? chunk.questions.trans_data[answer.trow_id] : null;
									const modalBodyEl = (
										<>
											<ul>
												<li><b>{typeIndex == 0 ? 'SLA' : 'KPI'}:</b> {question.text}</li>
												<li><b>Date:</b> {getDateShort(+answer.x)}</li>
												<li><b>Value:</b> {answer.text} ({answer.value.toFixed(2)})</li>
												<li><b>Comment:</b> {answer.comment}</li>
											</ul>
											{transData ? (
												<div style="margin-top: 15px;">
													<h4>Transaction Custom Data</h4>
													<ul>
														{transData.map(({title, value}) => (
															<li><b>{title}:</b> {value}</li>
														))}
													</ul>
												</div>
											) : ''}
										</>
									);
									$('#question_details_body').html(modalBodyEl);
									$('#question_details_modal').modal('show');
								};

								const chartOptions: Highcharts.Options = {
									yAxis: {
										categories: res.data.standardanswers[typeIndex].map((val) => htmlEsc(val)),
										title: {
											text: null,
										},
									},
									tooltip: {
										formatter: function() {
											const answer = this.point as any;
											let tooltip = `
												<b>${getDateShort(+answer.x)}</b>
												<br>
												${htmlEsc(answer.text)}
												(${htmlEsc(answer.value.toFixed(2))})
											`
											if (answer.comment) tooltip += '<br>' + htmlEsc(answer.comment);
											if (chunk.questions.trans_data && chunk.questions.trans_data[answer.trow_id]) tooltip += '<br><i>Click to view details</i>';
											return tooltip;
										},
									},
									chart: {
										renderTo: `spark_${question.id}`,
									},
									plotOptions: {
										series: {
											point: {
												events: {
													click: (event) => {
														const answer = event.point.options;
														showAnswerDetailsModal(answer);
													},
												},
											},
										},
									},
									series: [{
										data: question.data,
										type: 'line',
									}],
								};
								new Highcharts.Chart(`spark_${question.id}`, Highcharts.merge(sparklineDefaults, chartOptions));
							})
						})
					}
				});
			}
		}

		// Services Charts build
		const buildServiceCharts = (d) => {
			//logme('buildServiceCharts');
			//logme(d);
			if (contractChartSla != null) {
				contractChartSla.destroy();
				contractChartSla = null;
			}
			if (contractChartKpi != null) {
				contractChartKpi.destroy();
				contractChartKpi = null;
			}
			if (contractChartRisk != null) {
				contractChartRisk.destroy();
				contractChartRisk = null;
			}
			if (d.rc == 'OK' && d.hasOwnProperty('charts') && d.charts.hasOwnProperty('range')) {
				if (d.charts.hasOwnProperty('sla') && $('#services_chart_sla').length == 1) {
					const chartOptions: Highcharts.Options = {
						title: {
							text: 'Overall SLA Performance',
						},
						xAxis: {
							min: d.charts.range[0],
							max: d.charts.range[1],
						},
						yAxis: {
							categories: d.thresholds.sla.map((val) => htmlEsc(val)),
							title: {
								text: null,
							},
						},
						series: d.charts.sla,
						tooltip: {
							formatter: function () {
								return vendorPerformanceTooltipFormatter(this, d.thresholds.sla);
							},
							shared: true,
						},
						legend: {
							enabled: true,
						},
					};
					contractChartSla = Highcharts.chart('services_chart_sla', Highcharts.merge(highchartsLine, chartOptions));
				}
				if (d.charts.hasOwnProperty('kpi') && $('#services_chart_kpi').length == 1) {
					const chartOptions: Highcharts.Options = {
						title: {
							text: 'Overall KPI Performance',
						},
						xAxis: {
							min: d.charts.range[0],
							max: d.charts.range[1],
						},
						yAxis: {
							categories: d.thresholds.kpi.map((val) => htmlEsc(val)),
							title: {
								text: null,
							},
						},
						series: d.charts.kpi,
						tooltip: {
							formatter: function () {
								return vendorPerformanceTooltipFormatter(this, d.thresholds.kpi);
							},
							shared: true,
						},
						legend: {
							enabled: true,
						},
					};
					contractChartKpi = Highcharts.chart('services_chart_kpi', Highcharts.merge(highchartsLine, chartOptions));
				}
				if (d.charts.hasOwnProperty('risk') && $('#services_chart_risk').length == 1 && d.charts.risk.length) {
					const chartOptions: Highcharts.Options = {
						title: {
							text: 'Contract Risk',
						},
						chart: {
							height: '60%',
						},
						series: [{
							data: d.charts.risk.map((val) => ({...val, name: htmlEsc(val.name)})),
							type: 'pie',
						}],
					};
					contractChartRisk = Highcharts.chart('services_chart_risk', Highcharts.merge(highchartsPie, chartOptions));
				}
			}
		};

		const vendorDisplayRelowners = (relowners) => {
			if (!relowners) return;
			let primary = 'None';
			let secondaries = [];
			let analysts = [];
			relowners.forEach(({ vrel_rank, usr_name }) => {
				if (vrel_rank == RELOWNER_PRIMARY) primary = usr_name;
				else if (vrel_rank == RELOWNER_SECONDARY) secondaries.push(usr_name);
				else if (vrel_rank == RELOWNER_ANALYST) analysts.push(usr_name);
			});
			if (!secondaries.length) secondaries = ['None'];
			if (!analysts.length) analysts = ['None'];
			$('#vendor_form_relowner_primary').text(primary);
			$('#vendor_form_relowners').text(secondaries.join(', '));
			$('#vendor_form_relowners_analyst').text(analysts.join(', '));
		};

		const vendorDisplayStart = (res) => {
			if (res.rc !== 'OK') {
				displayNotification('Vendor Load', 'There was an error loading the vendor.', 'danger');
				return;
			}

			vendor.name = res.vendor.vend_name;
			vendor.status = parseInt(res.vendor.vend_status);
			vendor.category = res.vendor.vend_category;
			vendor.address1 = res.vendor.vend_address1;
			vendor.address2 = res.vendor.vend_address2;
			vendor.city = res.vendor.vend_city;
			vendor.state = res.vendor.vend_state;
			vendor.zip = res.vendor.vend_postalcode;
			vendor.country = res.vendor.vend_country;
			vendor.phone = res.vendor.vend_phone;
			vendor.mainfax = res.vendor.vend_mainfax;
			vendor.critical = parseInt(res.vendor.vend_critical);
			vendor.website = res.vendor.vend_website;
			vendor.noncontract = parseInt(res.vendor.vend_noncontract);
			vendor.nextreview = res.vendor.vend_nextreview;
			vendor.revenue = res.vendor.vend_revenue;
			vendor.marketshare = res.vendor.vend_marketshare;
			vendor.offices = res.vendor.offices;
			vendor.office_countries = res.vendor.office_countries;
			vendor.description = res.vendor.vend_description;
			vendor.fourthparty = res.vendor.fourthparty;
			vendor.attributes = res.vendor.attributes;
			vendor.notes = res.vendor.vend_notes;
			vendor.parentid = res.vendor.vend_parentid;
			vendor.idnumber = res.vendor.vend_idnumber;
			vendor.taxid = res.vendor.vend_taxid;
			vendor.businessunits = res.vendor.businessunits;
			vendor.score_inherent = res.vendor.inherent_title ? res.vendor.inherent_title : 'N/A';
			vendor.score_residual = res.vendor.residual_title ? res.vendor.residual_title : 'N/A';
			vendor.score_sla = res.vendor.sla_aggregate_title ? res.vendor.sla_aggregate_title : 'N/A';
			vendor.score_kpi = res.vendor.kpi_aggregate_title ? res.vendor.kpi_aggregate_title : 'N/A';
			vendor.score_sla_percent = res.vendor.vend_sla_aggregate_percent ? res.vendor.vend_sla_aggregate_percent : 'N/A';
			vendor.score_kpi_percent = res.vendor.vend_kpi_aggregate_percent ? res.vendor.vend_kpi_aggregate_percent : 'N/A';
			vendor.score_vv = res.vendor.vendvalue_title ? res.vendor.vendvalue_title : 'N/A';
			vendor.score_onboarding = res.vendor.onboarding_rating;
			vendor.score_offboarding = res.vendor.offboarding_rating;

			switch (vendor.critical) {
				case 0:
					vendor.score_critical = 'No';
					break;
				case 1:
					vendor.score_critical = 'Yes';
					break;
				default:
					vendor.score_critical = 'N/A';
					break;
			}

			$('#vendor_form_name').val(vendor.name);
			setTitle(vendor.name);
			$('#vendor_form_category').val(vendor.category);
			vendorDisplayCatDesc(vendor.category);
			$('#vendor_form_addr1').val(vendor.address1);
			$('#vendor_form_addr2').val(vendor.address2);
			$('#vendor_form_city').val(vendor.city);
			$('#vendor_form_state').val(vendor.state);
			$('#vendor_form_zip').val(vendor.zip);
			$('#vendor_form_country').val(vendor.country);
			$('#vendor_form_phone').val(vendor.phone);
			$('#vendor_form_fax').val(vendor.mainfax);
			$('#vendor_form_critical').prop('checked', vendor.critical == 1).trigger('change');
			$('#vendor_form_website').val(vendor.website);
			vendorDisplayWebsiteLink();
			$('#vendor_form_noncontract').prop('checked', vendor.noncontract == 1).trigger('change');
			$('#vendor_form_nextreview').val(vendor.nextreview);
			$('#vendor_form_revenue').val(vendor.revenue / 100);
			$('#vendor_form_marketshare').val(vendor.marketshare);
			$('#vendor_form_offices').val(vendor.offices).trigger('chosen:updated');
			setSelectedCountries(vendor.office_countries);
			$('#vendor_form_description').val(vendor.description);

			if ($('#vendor_form_fourthparty').prop('disabled')) {
				// Read from JSON options list, cherry-pick correct values, unhide textarea
				let displayFourthParties = [];
				const fourthPartiesList = JSON.parse($('#vendor_form_fourthparty').val() as string);
				fourthPartiesList.forEach((fp) => {
					vendor.fourthparty.forEach((vendorFp) => {
						if (+fp.id == vendorFp.id) {
							displayFourthParties.push(fp.name);
						}
					});
				});
				$('#vendor_form_fourthparty').val(displayFourthParties.join(', '));
				$('#vendor_form_fourthparty').show();
			} else {
				$('#vendor_form_fourthparty').val(vendor.fourthparty);
				$('#vendor_form_fourthparty').trigger('chosen:updated');
			}

			if ($('#vendor_form_attributes').prop('disabled')) {
				// Read from JSON options list, cherry-pick correct values, unhide textarea
				var dispattr = [];
				var attrlist = JSON.parse($('#vendor_form_attributes').val() as string);
				//logme(attrlist);
				for (var i = 0; i < attrlist.length; i++) {
					for (var j = 0; j < vendor.attributes.length; j++) {
						if (parseInt(attrlist[i].id) == vendor.attributes[j]) {
							dispattr.push(attrlist[i].name);
							break;
						}
					}
				}
				//logme(dispattr);
				$('#vendor_form_attributes').val(dispattr.join(', '));
				$('#vendor_form_attributes').show();
			} else {
				$('#vendor_form_attributes').val(vendor.attributes);
				$('#vendor_form_attributes').trigger('chosen:updated');
			}

			if ($('#vendor_form_businessunit').prop('disabled')) {
				// Read from JSON options list, cherry-pick correct values, unhide textarea
				var dispattr = [];
				var attrlist = JSON.parse($('#vendor_form_businessunit').val() as string);
				//logme(attrlist);
				for (var i = 0; i < attrlist.length; i++) {
					for (var j = 0; j < vendor.businessunits.length; j++) {
						if (parseInt(attrlist[i].id) == vendor.businessunits[j]) {
							dispattr.push(attrlist[i].name);
							break;
						}
					}
				}
				//logme(dispattr);
				$('#vendor_form_businessunit').val(dispattr.join(', '));
				$('#vendor_form_businessunit').show();
			} else {
				$('#vendor_form_businessunit').val(vendor.businessunits);
				$('#vendor_form_businessunit').trigger('chosen:updated');
			}
			$('#vendor_form_notes').val(vendor.notes);
			$('#vendor_form_parenvendor').val(vendor.parentid).trigger('chosen:updated');
			$('#vendor_form_idnumber').val(vendor.idnumber);
			$('#vendor_form_taxid').val(vendor.taxid);
			$('#vendor_score_inherent, #vendor_perf_score_inherent').html('<strong>Inherent</strong><br>' + htmlEsc(vendor.score_inherent));
			$('#vendor_score_residual, #vendor_perf_score_residual').html('<strong>Residual</strong><br>' + htmlEsc(vendor.score_residual));
			$('#vendor_score_vendorvalue, #vendor_perf_score_vendorvalue').html('<strong>Vendor Value</strong><br>' + htmlEsc(vendor.score_vv));
			$('#vendor_score_critical, #vendor_perf_score_critical').html('<strong>Critical</strong><br>' + htmlEsc(vendor.score_critical));
			$('#vendor_score_sla, #vendor_perf_score_sla').html('<strong>SLA Aggregate</strong><br>' + htmlEsc(vendor.score_sla));
			$('#vendor_score_kpi, #vendor_perf_score_kpi').html('<strong>KPI Aggregate</strong><br>' + htmlEsc(vendor.score_kpi));
			const checklistColors: Record<string, BootstrapFlavor> = {
				'Satisfactory': 'success',
				'In Progress': 'warning',
				'Deficiency/Action Required': 'danger',
				'Not Started': 'default',
			};
			$('#vendorEditDisplayColor').off('change').on('change', ({target}) => {
				const displayColors = target.checked;
				const onboardColor = checklistColors[vendor.score_onboarding];
				$('#vendor_score_onboarding').html(
					<div className={displayColors ? `label-${onboardColor}` : ''} style="border-radius: 5px; padding: 5px;">
						<strong>Onboarding Checklist</strong>
						<br />
						<span>{vendor.score_onboarding}</span>
					</div>
				);
				const offboardColor = checklistColors[vendor.score_offboarding];
				$('#vendor_score_offboarding').html(
					<div className={displayColors ? `label-${offboardColor}` : ''} style="border-radius: 5px; padding: 5px;">
						<strong>Offboarding Checklist</strong>
						<br />
						<span>{vendor.score_offboarding}</span>
					</div>
				);
			}).trigger('change');
			$('#vendorEditPerfDisplayColor').off('change').on('change', ({target}) => {
				const displayColors = target.checked;
				const onboardColor = checklistColors[vendor.score_onboarding];
				$('#vendor_perf_score_onboarding').html(
					<div className={displayColors ? `label-${onboardColor}` : ''} style="border-radius: 5px; padding: 5px;">
						<strong>Onboarding Checklist</strong>
						<br />
						<span>{vendor.score_onboarding}</span>
					</div>
				);
				const offboardColor = checklistColors[vendor.score_offboarding];
				$('#vendor_perf_score_offboarding').html(
					<div className={displayColors ? `label-${offboardColor}` : ''} style="border-radius: 5px; padding: 5px;">
						<strong>Offboarding Checklist</strong>
						<br />
						<span>{vendor.score_offboarding}</span>
					</div>
				);
			}).trigger('change');
			$('#vendor_form_children').val(res.vendor.child_ids).trigger('chosen:updated');

			if (res.hierarchy) {
				const allIds = [vendor.parentid];
				const circularIds = [];

				// Generate chart data
				const rows = [];
				let mainVendorRowIndex = null;
				const googleRowsRecursive = ({ children, layer = 0, id = null }) => {
					children.forEach((vend) => {
						if (circularIds.includes(vend.vend_id)) return;
						else if (allIds.includes(vend.vend_id)) circularIds.push(vend.vend_id);
						else allIds.push(vend.vend_id);
						const rowIndex = rows.length;
						const isMainVendor = vend.vend_id == vendor.id;
						if (isMainVendor) mainVendorRowIndex = rowIndex;
						const statusLabel =
							{
								[VENDOR_PENDING]: '<div style="color: red; font-style: italic;">Pending</div>',
								[VENDOR_ARCHIVED]: '<div style="color: red; font-style: italic;">Archived</div>',
							}[vend.vend_status] || '';
						const link = vend.link ? `<a href="#" class="ml-1 pt-hierarchy-link" data-vend="${vend.vend_id}"><i class="fa fa-external-link"></i></a>` : '';
						const html = `<div class="pt-hierarchy-item-cont"><span class="${isMainVendor ? 'pt-hierarchy-item-main' : 'pt-hierarchy-item'}">${vend.vend_name}${link}</span>${statusLabel}</div>`;
						rows.push([{ v: vend.vend_id.toString(), f: html }, id ? id.toString() : '', '']);

						if (vend.children) googleRowsRecursive({ children: vend.children, layer: layer + 1, id: vend.vend_id });
					});
				};
				googleRowsRecursive({ children: res.hierarchy });

				// Init chart with data
				const drawChart = () => {
					const data = new google.visualization.DataTable();
					data.addColumn('string', 'Name');
					data.addColumn('string', 'Manager');
					data.addColumn('string', 'ToolTip');

					// For each orgchart box, provide the name, manager, and tooltip to show.
					data.addRows(rows);

					// Create the chart.
					const chart = new google.visualization.OrgChart(document.getElementById('vendor_form_hierarchy_google'));

					google.visualization.events.addListener(chart, 'ready', () => {
						$('#vendor_form_hierarchy_google').find('tr').css({ 'white-space': 'nowrap' });
					});

					chart.draw(data, { allowHtml: true, allowCollapse: true, size: 'medium' });

					if (mainVendorRowIndex !== null) chart.setSelection([{ row: mainVendorRowIndex, column: null }]);
				};

				google.charts.load('current', { packages: ['orgchart'] });
				google.charts.setOnLoadCallback(drawChart);

				let collapseIdsList = [];
				let allIdsList = [vendor.parentid];
				let circularIdsList = [];
				const bodyColor = $('body').css('color');
				const hierarchyHtmlRecursive = ({ children, layer = 1 }) => children.map((vend) => {
					if (circularIdsList.includes(vend.vend_id)) return;
					else if (allIdsList.includes(vend.vend_id)) circularIdsList.push(vend.vend_id);
					else allIdsList.push(vend.vend_id);

					if (vend.children) collapseIdsList.push(vend.vend_id);

					const nameWrapper = () => {
						const nameEl = (vend.vend_status == VENDOR_ACTIVE) ? vend.vend_name : <i>{vend.vend_name}</i>

						if (vend.vend_id == res.vendor.vend_id) return <span className="vendor_form_hierarchy_protagonist text-danger">{nameEl}</span>;
						else if (vend.link) return <a href="/ui/vendor_edit" data-vend={vend.vend_id} className="pt-hierarchy-link">{nameEl}</a>;
						else return <span style={`color: ${bodyColor};`}>{nameEl}</span>;
					}

					const statusLabel = () => ({
						[VENDOR_PENDING]: <span className="label label-primary" style="margin-left: 5px;">Pending</span>,
						[VENDOR_ARCHIVED]: <span className="label label-primary" style="margin-left: 5px;">Archived</span>,
					}[vend.vend_status] || '');

					const toggleIcon = () => {
						if (!vend.children) return '';
						return (
							<>
								<i className="fa fa-fw fa-chevron-down 3pt-toggle-show" style={`margin-right: 5px; ${vend.show ? '' : 'display: none;'}`}></i>
								<i className="fa fa-fw fa-chevron-right 3pt-toggle-hide" style={`margin-right: 5px; ${vend.show ? 'display: none;' : ''}`}></i>
							</>
						);
					}

					return (
						<>
							<button
								id={`vendor_form_hierarchy_item_${vend.vend_id}_collapse`}
								className="list-group-item"
								style={`padding-left: ${layer * 45 - 30}px; ${layer > 1 ? 'margin-bottom: -1px; border-radius: 0;' : ''}`}>
								{toggleIcon()}
								{nameWrapper()}
								{statusLabel()}
							</button>
							{
								vend.children ? (
									<div
										id={`vendor_form_hierarchy_item_${vend.vend_id}`}
										className="vendor_form_hierarchy_section" style={vend.show ? '' : ' style="display: none;"'}>
											{hierarchyHtmlRecursive({ children: vend.children, layer: layer + 1 })}
									</div>
								)
								: ''
							}
						</>
					);
				}).flat();
				const hierarchy = hierarchyHtmlRecursive({ children: res.hierarchy });
				$('#vendor_form_hierarchy_list').html(<div className="list-group pt-hierarchy">{hierarchy}</div>);
				collapseIdsList.forEach((id) => toggleSlide($(`#vendor_form_hierarchy_item_${id}_collapse`), $(`#vendor_form_hierarchy_item_${id}`)));

				$('#vendor_form_hierarchy_col').show();
			} else {
				$('#vendor_form_hierarchy_col').hide();
			}

			const existingParentIds = res.existing_parent_vends.map((vend) => +vend.vend_id);
			$('#vendor_form_children')
				.off('change')
				.on('change', () => {
					const val = $('#vendor_form_children').val() as string[];
					const childIds = val.map((id) => +id);
					const displayIds = childIds.filter((id) => existingParentIds.includes(id));
					if (displayIds.length) {
						const vendHtml = [];
						displayIds.forEach((id) => {
							const link = res.existing_parent_vends.find(({ vend_id }) => vend_id === id).link;
							const name = childVendorOptions.find(({ vend_id }) => vend_id === id).vend_name;
							vendHtml.push(link ? `<a href="/ui/vendor_edit" data-vend="${id}">${htmlEsc(name)}</a>` : htmlEsc(name));
						});
						const vendHtmlJoined = vendHtml.join(', ');
						const msg = vendHtml.length > 1 ? `${vendHtmlJoined} all already have a parent vendor.  Their existing parent vendors will be overwritten.` : `${vendHtmlJoined} already has a parent vendor.  Its existing parent vendor will be overwritten.`;
						$('#vendor_form_children_warning_msg').html(msg);
						$('#vendor_form_children_warning').show();
					} else {
						$('#vendor_form_children_warning').hide();
						$('#vendor_form_children_warning_msg').html('');
					}
				})
				.trigger('change');

			if (res.hasOwnProperty('charts')) {
				buildCharts(res.charts);
			}
			if (res.hasOwnProperty('servicecharts')) {
				buildServiceCharts(res.servicecharts);
			}
			if (res.hasOwnProperty('performance')) {
				buildPerformanceTab(res.performance);
			}

			setStatus(+vendor.status);
			setNonContract(vendor.noncontract);

		}

		const setStatus = (status: number) => {
			// use on existing vendors or after initial save
			// Note to self: #contact_form_body does not have a "chosen" element
			let vendorActive = false;

			const enableVendorForm = () => {
				$('#vendor_form_body :input, #contact_form_body :input').prop('disabled', false);
				$('#vendor_form_body :input.chosen').trigger('chosen:updated');
				$('#contract_add').prop('disabled', false);
			};

			const disableVendorForm = () => {
				$('#vendor_form_body :input, #contact_form_body :input').prop('disabled', true);
				$('#vendor_form_hierarchy_print').prop('disabled', false);
				$('#vendor_form_hierarchy_toggle').prop('disabled', false);
				$('#vendor_form_hierarchy_view').prop('disabled', false);
				$('#vendor_form_body :input.chosen').trigger('chosen:updated');
				$('#contract_add').prop('disabled', true);
			};
			switch (status) {
				case VENDOR_PENDING:
					$('#vendor_form_submit').show().off('click').on('click', async () => {
						const res = await vendorSave();
						if (res) vendorDisplayStart(res.data);
					});
					$('#vendor_form_pending').html('Activate').data('mode', 1).show();
					$('#vendor_form_pending').removeClass('btn-warning').addClass('btn-success');

					$('#vendor_form_archive').html('Archive').data('mode', 0).show();
					$('#vendor_form_archive').removeClass('btn-success').addClass('btn-danger');

					if (vendor.readonly == 0) enableVendorForm();
					else disableVendorForm();

					vendorActive = true;
					break;
				case VENDOR_ACTIVE:
					$('#vendor_form_submit').show().off('click').on('click', async () => {
						const res = await vendorSave();
						if (res) vendorDisplayStart(res.data);
					});

					$('#vendor_form_pending').html('Mark Pending').data('mode', 0).show();
					$('#vendor_form_pending').removeClass('btn-success').addClass('btn-warning');

					$('#vendor_form_archive').html('Archive').data('mode', 0).show();
					$('#vendor_form_archive').removeClass('btn-success').addClass('btn-danger');

					if (vendor.readonly == 0) enableVendorForm();
					else disableVendorForm();

					vendorActive = true;
					break;
				case VENDOR_ARCHIVED:
					$('#vendor_form_submit').off('click').hide();
					$('#vendor_form_submit_add').off('click').hide();

					$('#vendor_form_pending').data('mode', null).hide();

					$('#vendor_form_archive').html('Reinstate').data('mode', 1).show();
					$('#vendor_form_archive').removeClass('btn-danger').addClass('btn-success');

					disableVendorForm();
					break;
				default:
					$('#vendor_form_submit').off('click').hide();
					$('#vendor_form_submit_add').off('click').hide();
					$('#vendor_form_pending').data('mode', null).hide();
					$('#vendor_form_archive').data('mode', null).hide();
					$('#contract_add').prop('disabled', true);
					break;
			}
			const catActive = $('#vendor_form_category option:selected').data('status') != null;
			if (vendorActive && catActive) {
				$('.response_add').prop('disabled', false);
			} else {
				$('.response_add').prop('disabled', true);
			}
		};

		if (isNewVendor) {
			$('#vendor_form_title').html('Add Vendor');

			// Vendor Save (INSERT)
			$('#vendor_form_submit').off('click').on('click', async (event) => {
				event.preventDefault();
				const res = await vendorSave();
				if (res) post('/ui/vendor_edit', { id: res.id });
			});

			// Vendor Save (& NEW)
			$('#vendor_form_submit_add').off('click').on('click', async (event) => {
				event.preventDefault();
				const res = await vendorSave();
				if (res) clearform();
			});

			$('select#vendor_form_group').chosen({ width: '100%' });
		} // existing vendor
		else {
			$('#performance-charts').show();

			$('#vendor_form_submit_add').hide();

			// Vendor Get
			const vendorGet = async () => {
				try {
					const res = await ajaxPromise('/data/vendor_get', { data: vendor.id });
					if (res.rc !== 'OK') throw res;
					vendorDisplayStart(res);
				} catch (error) {
					logerror('vendor get', error);
				}
			};
			vendorGet();

			$('#vendor_form_hierarchy')
				.off('click')
				.on('click', 'a.pt-hierarchy-link', (event) => {
					event.stopPropagation();
					event.preventDefault();
					const $a = $(event.target).closest('a');
					const newVendId = $a.data('vend');
					post('/ui/vendor_edit', { id: newVendId });
				});

			$('#vendor_form_hierarchy_view')
				.off('change')
				.on('change', () => {
					const isOrgView = $('#vendor_form_hierarchy_view').is(':checked');
					if (isOrgView) {
						$('#vendor_form_hierarchy_org').show();
						$('#vendor_form_hierarchy_list').hide();
					} else {
						$('#vendor_form_hierarchy_org').hide();
						$('#vendor_form_hierarchy_list').show();
					}
				})
				.bootstrapToggle('on');

			$('#vendor_form_children_warning')
				.off('click')
				.on('click', 'a', (event) => {
					event.stopPropagation();
					event.preventDefault();
					const newVendId = $(event.target).data('vend');
					post('/ui/vendor_edit', { id: newVendId });
				});

			$('#vendor_chart_option_range, #vendor_chart_option_scope').off('change').on('change', async () => {
				try {
					const postData = {
						id: vendor.id,
						performance: {
							mode: $('#vendor_chart_option_scope').val(),
							range: $('#vendor_chart_option_range').val(),
						},
					};
					const res = await ajaxPromise('/data/vendor_performance_get', { data: postData });
					if (res.rc !== 'OK') throw res;
					buildCharts(res.charts);
				} catch (error) {
					logerror('vendor performance get', error);
				}
			});

			$('#services_chart_option_range, #services_chart_option_scope').off('change');
			$('#services_chart_option_range, #services_chart_option_scope').on('change', async () => {
				try {
					const postData = {
						id: vendor.id,
						performance: {
							mode: $('#services_chart_option_scope').val(),
							range: $('#services_chart_option_range').val(),
						},
					};
					const res = await ajaxPromise('/data/services_performance_get', { data: postData });
					if (res.rc !== 'OK') throw res;
					buildServiceCharts(res.data);
				} catch (error) {
					logerror('service performance get', error);
				}
			});

			$('#contacts_section').show();
			$('#opscenter_section').show();
			$('#related_section').show();

			$('select#vendor_form_offices').chosen({ width: '100%' });
			$('select#vendor_form_office_countries').chosen({ width: '100%' });
			$('select#vendor_form_fourthparty').chosen({ width: '100%' });
			$('select#vendor_form_attributes').chosen({ width: '100%' });
			$('select#vendor_form_businessunit').chosen({ width: '100%' });
			$('select#vendor_form_parent').chosen({ width: '100%' });
			$('select#vendor_form_children').chosen({ width: '100%' });

			$('.response_add').off('click').on('click', async ({target}) => {
				const $btn = $(target);
				$btn.prop('disabled', true);
				const type = +$btn.data('assessment-type');
				if ([ASSESSMENT_ONBOARDING, ASSESSMENT_OFFBOARDING].includes(type)) {
					try {
						const postData = {
							type: 'response_checklist_create',
							data: JSON.stringify({ vend_id: vendor.id, type }),
							json: true,
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;

						loadVendorEdit({vend_id: +vendor.id, assessType: +type});
					} catch (error) {
						const assessName = type === ASSESSMENT_ONBOARDING ? 'Onboarding' : 'Offboarding';
						switch (error.rc) {
							case 'NO_MASTER':
								displayNotification(`${assessName} Checklist Create`, `No Vendor ${assessName} Checklist template loaded.  Please contact the Vendor Management Office.`, 'danger', 5000);
							default:
								displayNotification(`${assessName} Checklist Create`, `The Vendor ${assessName} Checklist could not be created for this vendor.  Please contact the Vendor Management Office.`, 'danger', 5000);
								logerror('response checklist create', error);
						}
					}
				} else {
					post('/ui/assessment', {
						surv_guid: null,
						vend_id: vendor.id,
						cat_id: vendor.category,
						type,
					});
				}
				setTimeout(() => $btn.prop('disabled', false), 1000);
			});

			$('#contract_add').off('click');
			$('#contract_add').on('click', function () {
				if (parseInt(vendor.noncontract) == 0) {
					$(this).prop('disabled', true);
					post('/ui/contract', {
						vend_id: vendor.id,
						contract_guid: null,
					});
					setTimeout(function () {
						$(this).prop('disabled', false);
					}, 1000);
				}
			});

			const showtable_inherent = () => {
				if (inherentdt == null) {
					inherentdt = assessmentsTableBuild({$table: $('#inherentrisk_table'), vend_id: +vendor.id, type: ASSESSMENT_INHERENT});
				} else {
					inherentdt.ajax.reload();
				}
			}

			const showtable_diligence = () => {
				if (diligencedt == null) {
					diligencedt = assessmentsTableBuild({$table: $('#duediligence_table'), vend_id: +vendor.id, type: ASSESSMENT_DILIGENCE});
				} else {
					diligencedt.ajax.reload();
				}
			}

			const showtable_review = () => {
				if (reviewdt == null) {
					reviewdt = assessmentsTableBuild({$table: $('#periodicreview_table'), vend_id: +vendor.id, type: ASSESSMENT_REVIEW});
				} else {
					reviewdt.ajax.reload();
				}
			}

			const showtable_vendorvalue = () => {
				if (vendorvaluedt == null) {
					vendorvaluedt = assessmentsTableBuild({$table: $('#vendorvalue_table'), vend_id: +vendor.id, type: ASSESSMENT_VENDORVALUE});
				} else {
					vendorvaluedt.ajax.reload();
				}
			}

			if ($('#inherentrisk_table').length == 1) {
				if ($('#tab-inherent').hasClass('active')) {
					showtable_inherent();
				}
				$('a[href="#tab-inherent"]').on('show.bs.tab', function (e) {
					showtable_inherent();
				});
			}

			if ($('#duediligence_table').length == 1) {
				if ($('#tab-diligence').hasClass('active')) {
					showtable_diligence();
				}
				$('a[href="#tab-diligence"]').on('show.bs.tab', function (e) {
					showtable_diligence();
				});
			}

			if ($('#periodicreview_table').length == 1) {
				if ($('#tab-review').hasClass('active')) {
					showtable_review();
				}
				$('a[href="#tab-review"]').on('show.bs.tab', function (e) {
					showtable_review();
				});
			}

			if ($('#vendorvalue_table').length == 1) {
				if ($('#tab-vendorvalue').hasClass('active')) {
					showtable_vendorvalue();
				}
				$('a[href="#tab-vendorvalue"]').on('show.bs.tab', function (e) {
					showtable_vendorvalue();
				});
			}

			if ($('#contract_table').length) {
				const getFields = () => {
					const fields = {
						category: $('#contract_searchfields_category').is(':checked') ? 1 : 0,
						contract_type: $('#contract_searchfields_contract_type').is(':checked') ? 1 : 0,
						status: $('#contract_searchfields_status').is(':checked') ? 1 : 0,
						stage: $('#contract_searchfields_stage').is(':checked') ? 1 : 0,
						importance: $('#contract_searchfields_importance').is(':checked') ? 1 : 0,
						startdate: $('#contract_searchfields_startdate').is(':checked') ? 1 : 0,
						enddate: $('#contract_searchfields_enddate').is(':checked') ? 1 : 0,
						deadline: $('#contract_searchfields_deadline').is(':checked') ? 1 : 0,
						autorenew: $('#contract_searchfields_autorenew').is(':checked') ? 1 : 0,
						sla_latest: $('#contract_searchfields_sla').is(':checked') ? 1 : 0,
						kpi_latest: $('#contract_searchfields_kpi').is(':checked') ? 1 : 0,
						sla_aggregate: $('#contract_searchfields_sla_aggregate').is(':checked') ? 1 : 0,
						kpi_aggregate: $('#contract_searchfields_kpi_aggregate').is(':checked') ? 1 : 0,
						checklist: $('#contract_searchfields_checklist').is(':checked') ? 1 : 0,
						risk: $('#contract_searchfields_risk').is(':checked') ? 1 : 0,
					};
					Cookies.set('contracts_table_fields', JSON.stringify(fields), { expires: 7 });
					return fields;
				};

				const fields = JSON.parse(Cookies.get('contracts_table_fields') || null);
				if (fields != null) {
					$('#contract_searchfields_category').prop('checked', fields.category);
					$('#contract_searchfields_contract_type').prop('checked', fields.contract_type);
					$('#contract_searchfields_status').prop('checked', fields.status);
					$('#contract_searchfields_stage').prop('checked', fields.stage);
					$('#contract_searchfields_importance').prop('checked', fields.importance);
					$('#contract_searchfields_startdate').prop('checked', fields.startdate);
					$('#contract_searchfields_enddate').prop('checked', fields.enddate);
					$('#contract_searchfields_deadline').prop('checked', fields.deadline);
					$('#contract_searchfields_autorenew').prop('checked', fields.autorenew);
					$('#contract_searchfields_sla').prop('checked', fields.sla_latest);
					$('#contract_searchfields_kpi').prop('checked', fields.kpi_latest);
					$('#contract_searchfields_sla_aggregate').prop('checked', fields.sla_aggregate);
					$('#contract_searchfields_kpi_aggregate').prop('checked', fields.kpi_aggregate);
					$('#contract_searchfields_checklist').prop('checked', fields.checklist);
					$('#contract_searchfields_risk').prop('checked', fields.risk);
				}

				$('#contract_search_archived')
					.off('change')
					.on('change', () => {
						const archived = $('#contract_search_archived').is(':checked') ? 1 : 0;
						Cookies.set('contracts_table_filters', JSON.stringify({ archived }), { expires: 7 });
						if (contractsdt !== null) setTimeout(() => contractsdt.ajax.reload(), 500);
					});
				const filters = JSON.parse(Cookies.get('contracts_table_filters') || null);
				if (filters != null) {
					$('#contract_search_archived').prop('checked', filters.archived);
				}

				setCollapse($('#contract_searchfields_open'), $('#contract_searchfields_container'));

				$('#contract_searchfields input:checkbox')
					.off('change')
					.on('change', () => setTimeout(() => drawTable(), 100));

				$('a[href="#tab-contracts"]').on('show.bs.tab', () => {
					if (contractsdt !== null) contractsdt.ajax.reload();
				});

				const drawTable = () => {
					if (contractsdt !== null) {
						contractsdt.destroy();
						contractsdt = null;
						$('#contract_table').html('');
					}

					contractsdt = contractsTableBuild($('#contract_table'), vendor.id, getFields());
				};

				drawTable();
			}

			$('a[href="#tab-history"]').on('show.bs.tab', () => {
				if (historyTable !== null) historyTable.ajax.reload();
			});

			var contactdt = contactsTableBuild({$target: $('#contacts_table'), vend_id: +vendor.id, isEditable: true});

			$('#vendor_contact_add').off('click');
			$('#vendor_contact_add').on('click', function (e) {
				$('#vendor_contact_edit_container :input').val('');
				$('#vendor_contact_id').val(0);
				$('#vendor_contact_edit_container').collapse('show');
				$('#vendor_contact_delete').off('click');
				$('#vendor_contact_delete').hide();
				//logme('add');
				e.preventDefault();
			});

			const vendor_contact_edit_close = () => {
				$('#vendor_contact_edit_container :input').val('');
				$('#vendor_contact_id').val(0);
				$('#vendor_contact_edit_container').collapse('hide');
				$('#vendor_contact_delete').off('click');
				$('#vendor_contact_delete').hide();
			}

			$('#vendor_contact_cancel').off('click');
			$('#vendor_contact_cancel').on('click', function (e) {
				vendor_contact_edit_close();
				e.preventDefault();
			});

			$('#vendor_contact_save').off('click').on('click', async (event) => {
				event.preventDefault();
				const postData = {
					vendid: vendor.id,
					id: $('#vendor_contact_id').val(),
					name: $('#vendor_contact_name').val(),
					title: $('#vendor_contact_title').val(),
					phone: $('#vendor_contact_phone').val(),
					email: $('#vendor_contact_email').val(),
					address1: $('#vendor_contact_address1').val(),
					address2: $('#vendor_contact_address2').val(),
					city: $('#vendor_contact_city').val(),
					state: $('#vendor_contact_state').val(),
					zip: $('#vendor_contact_zip').val(),
					country: $('#vendor_contact_country').val(),
				};
				if (postData.name == '') {
					displayNotification('Contact Save', 'Please enter a Contact Name.', 'danger');
					return;
				}

				$('#vendor_contact_save').prop('disabled', true);
				try {
					const res = await ajaxPromise('/form/submit', { type: 'vendor_contact_save', data: postData });
					if (res.rc !== 'OK') throw res;
					$('#vendor_contact_edit_container :input').val('');
					$('#vendor_contact_id').val(0);
					$('#vendor_contact_edit_container').collapse('hide');
					displayNotification('Contact Save', 'Vendor contact saved.', 'success');
					contactdt.ajax.reload();
				}
				catch (error) {
					logerror('vendor_contact_save', error);
					displayNotification('Contact Save', 'There was an error saving the contact.', 'danger');
				}
				$('#vendor_contact_save').prop('disabled', false);
			});

			opsCenterTableBuild(vendor.id, 'MANAGE');

			$('#vendor_form_archive').off('click').on('click', async (event) => {
				event.preventDefault();

				const $btn = $('#vendor_form_archive');
				const mode = +$btn.data('mode');

				if (mode == null) {
					displayNotification('Vendor Status', 'Vendor status cannot be changed.', 'danger');
					return;
				}

				const language: {[mode: number]: {[key: string]: string, style: BootstrapFlavor}} = {
					1: {
						title: 'Reinstate',
						action: 'reinstated',
						style: 'success',
						msg: 'Are you sure you would like to reinstate this Vendor?',
					},
					0: {
						title: 'Archive',
						action: 'archived',
						style: 'danger',
						msg: 'Are you sure you would like to archive this Vendor? Please complete an offboarding checklist, if applicable.',
					},
				};
				const { title, action, style, msg } = language[mode];

				const confirmed = await confirmDialog({
					dialogTitle: `Vendor ${title}`,
					bodyText: msg,
					confirmText: title,
					confirmStyle: style,
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'vendor_archive',
						data: {
							id: vendor.id,
							mode,
							force: 0,
						},
					};
					let res = await ajaxPromise('/form/submit', postData);
					if (!['OK', 'OPEN_ITEMS'].includes(res.rc)) throw res;

					if (res.rc == 'OPEN_ITEMS') {
						await setTimeoutPromise(500);
						const msg = {
							CONTRACTS: 'This Vendor has open contracts. Are you still sure you would like to archive this Vendor?',
							TASKS: 'This Vendor has open tasks. Are you still sure you would like to archive this Vendor?  All open tasks will be cancelled.',
							CONTRACTS_AND_TASKS: 'This Vendor has open contracts and open tasks. Are you still sure you would like to archive this Vendor?  All open tasks will be cancelled.',
						}[res.items];
						const confirmed = await confirmDialog({
							dialogTitle: `Vendor ${title}`,
							bodyText: msg,
							confirmText: title,
							confirmStyle: style,
						});
						if (!confirmed) return;
						postData.data.force = 1;
						res = await ajaxPromise('/form/submit', postData);
						if (res.rc != 'OK') throw res;
					}
					displayNotification(`Vendor ${title}`, `Vendor ${action} successfully.`, 'success');
					setStatus(+res.status);
					if (mode === 0) loadTaskDescs();
				} catch (error) {
					displayNotification(`Vendor ${title} Error`, 'Vendor status change failed.', 'danger');
					logerror('vendor archive', error);
				}
			});

			$('#vendor_form_pending').off('click').on('click', async (event) => {
				event.preventDefault();

				const $btn = $('#vendor_form_pending');
				const mode = $btn.data('mode');

				if (mode == null) {
					displayNotification('Vendor Status', 'Vendor status cannot be changed.', 'danger');
					return;
				}

				const language: {[mode: string]: {[key: string]: string, style: BootstrapFlavor}} = {
					1: {
						title: 'Activate',
						verb: 'activate this Vendor',
						action: 'Vendor activated successfully.',
						style: 'success',
					},
					0: {
						title: 'Mark Pending',
						verb: 'mark this Vendor as pending',
						action: 'Vendor successfully marked pending.',
						style: 'warning',
					},
				};
				const { title, verb, action, style } = language[mode];

				const confirmed = await confirmDialog({
					dialogTitle: `Vendor ${title}`,
					bodyText: `Are you sure you would like to ${verb}?`,
					confirmText: title,
					confirmStyle: style,
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'vendor_pending',
						data: {
							id: vendor.id,
							mode,
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;
					displayNotification(`Vendor ${title}`, action, 'success');
					setStatus(+res.status);
				} catch (error) {
					displayNotification(`Vendor ${title} Error`, 'Vendor status change failed.', 'danger');
					logerror('vendor pending', error);
				}
			});

			$('#vendor_form_export_category')
				.show()
				.on('click', (event) => {
					post('/export/category', {
						cat_id: vendor.category,
						vend_id: vendor.id,
					});
					event.preventDefault();
				});

			const task_editor_show_assessment = (type, assessments, is_external) => {
				if (!assessments) return;
				let types = {
					[SURVEY_TYPE_IR]: 'ir',
					[SURVEY_TYPE_DD]: 'dd',
					[SURVEY_TYPE_PR]: 'pr',
				};
				let type_string = types[type];
				if (!type_string) return;
				$('#task_editor_col_' + type_string).show();
				$('#task_editor_' + type_string + '_existing').empty();
				assessments.forEach(function (a) {
					if (is_external && a.surveyresp_status == SURVEY_COMPLETE) return; //Don't allow completed assessments for external users
					let rating = a.surveyresp_status == SURVEY_COMPLETE ? a.thresh_title : 'Incomplete';
					let option = '<option value="' + a.surveyresp_guid + '">' + rating + ' ' + a.surveyresp_date + ' assessment</option>';
					$('#task_editor_' + type_string + '_existing').append(option);
				});
				$('#task_editor_' + type_string).trigger('chosen:updated');
			}

			$('#vendor_form_task').show().on('click', async (event) => {
				event.preventDefault();
				$('#task_editor').modal('show');
				$('#task_editor_user,#task_editor_email,#task_editor_duedate,#task_editor_senddate,#task_editor_notes,#task_editor_ir,#task_editor_dd,#task_editor_pr,#task_editor_rd').val('');
				$('#task_editor_notify').val([TASK_NOTIFY_MYSELF.toString()]).trigger('chosen:updated');
				$('#task_editor_external').bootstrapToggle('off');
				$('#task_editor_ir_col, #task_editor_dd_col, #task_editor_pr_col, #task_editor_dd_col').hide();
				$('#task_editor_user_relowners,#task_editor_user_admins,#task_editor_user_managers,#task_editor_ir_existing, #task_editor_dd_existing, #task_editor_pr_existing, #task_editor_rd').empty();

				$('#task_editor_ext_recip').val('');
				$('#task_editor_ext_recip_contacts').empty();

				$('#task_editor_request').off('click').on('click', async (event) => {
					event.preventDefault();
					const task: any = {
						priority: +$('#task_editor_priority').val(),
						external: $('#task_editor_external').is(':checked') ? 1 : 0,
						vend_id: +vendor.id,
						cat_id: +vendor.category,
						usr_guids: $('#task_editor_user').val(),
						email: $('#task_editor_ext_recip').val() || $('#task_editor_email').val(),
						senddate: $('#task_editor_senddate').val(),
						duedate: $('#task_editor_duedate').val(),
						notify: $('#task_editor_notify').val(),
						notes: $('#task_editor_notes').val(),
					};
					let bad = false;
					if (!task.external && !task.usr_guids.length) {
						displayNotification('Request Task', 'Please specify a recipient.', 'danger');
						bad = true;
					}
					if (task.external && !task.email) {
						displayNotification('Request Task', 'Please specify an external email address.', 'danger');
						bad = true;
					}
					if (!task.duedate) {
						displayNotification('Request Task', 'Please specify a due date.', 'danger');
						bad = true;
					}
					if (task.duedate && task.duedate < currentDate) {
						displayNotification('Request Task', 'You cannot create a task in the past.', 'danger');
						bad = true;
					}
					const subtasks = [];
					let ir_val = $('#task_editor_ir').val();
					if (ir_val) subtasks.push({ type: SUBTASK_IR, create_new: ir_val === 'NEW' ? 1 : 0, surveyresp_guid: ir_val });
					let dd_val = $('#task_editor_dd').val();
					if (dd_val) subtasks.push({ type: SUBTASK_DD, create_new: dd_val === 'NEW' ? 1 : 0, surveyresp_guid: dd_val });
					let pr_val = $('#task_editor_pr').val();
					if (pr_val) subtasks.push({ type: SUBTASK_PR, create_new: pr_val === 'NEW' ? 1 : 0, surveyresp_guid: pr_val });
					let rd_val = $('#task_editor_rd').val() as string[];
					if (rd_val.length) subtasks.push({ type: SUBTASK_RD, doct_ids: rd_val });
					task.subtasks = subtasks;
					if (!subtasks.length) {
						displayNotification('Request Task', 'Please add at least one task.', 'danger');
						bad = true;
					}
					if (bad) return;

					$('#task_editor_request').prop('disabled', true);

					try {
						const res = await ajaxPromise('/form/submit', {data: JSON.stringify(task), type: 'vendor_request_task', json: true});
						if (res.rc !== 'OK') throw res;

						displayNotification('Request Task', 'Task requested successfully.', 'success');
						loadTaskDescs();
						$('#task_editor').modal('hide');
					}
					catch (error) {
						switch (error.rc) {
							case 'INVALID_EXTERNAL_EMAIL':
								displayNotification('Request Task', 'Please enter a valid external email address.  Multiple external email addresses are not allowed.', 'danger');
								break;
							case 'NO_MASTER':
								let assessment_name = {
									[SUBTASK_IR]: 'Inherent Risk',
									[SUBTASK_DD]: 'Due Diligence',
									[SUBTASK_PR]: 'Periodic Review',
								}[error.stk_type];
								displayNotification('Request Task', `No ${assessment_name} template loaded.  Please contact the Vendor Management Office.`, 'danger');
								break;
							default:
								logerror('vendor request task', error);
								displayNotification('Request Task', 'Could not create task.', 'danger');
						}
					}

					$('#task_editor_request').prop('disabled', false);
				});

				try {
					const res = await ajaxPromise('/data/request_task_load', { data: { vend_id: vendor.id } });
					if (res.rc !== 'OK') throw res;

					let showExternalRecipients = res.contacts && res.contacts.length;

					if (res.relowners && res.relowners.length) {
						res.relowners.forEach(function (rel) {
							let fullname = rel.usr_firstname + ' ' + rel.usr_lastname;
							let option = '<option value="' + rel.usr_guid + '">' + fullname + '</option>';
							$('#task_editor_user_relowners').append(option);
						});
						$('#task_editor_user').trigger('chosen:updated');
					}
					if (res.admins && res.admins.length) {
						res.admins.forEach(function (admin) {
							let fullname = admin.usr_firstname + ' ' + admin.usr_lastname;
							let option = '<option value="' + admin.usr_guid + '">' + fullname + '</option>';
							$('#task_editor_user_admins').append(option);
						});
						$('#task_editor_user').trigger('chosen:updated');
					}
					if (res.managers && res.managers.length) {
						res.managers.forEach(function (man) {
							let fullname = man.usr_firstname + ' ' + man.usr_lastname;
							let option = '<option value="' + man.usr_guid + '">' + fullname + '</option>';
							$('#task_editor_user_managers').append(option);
						});
						$('#task_editor_user').trigger('chosen:updated');
					}
					if (res.contacts && res.contacts.length) {
						res.contacts.forEach(function (cont) {
							let option = '<option value="' + cont.vcontact_email + '">' + cont.vcontact_name + '</option>';
							$('#task_editor_ext_recip_contacts').append(option);
						});
						$('#task_editor_ext_recip').trigger('chosen:updated');
					}

					$('#task_editor_external')
						.off('change')
						.change(function (event) {
							let is_external = $(event.target).prop('checked');
							if (is_external) {
								$('#task_editor_user_group').hide();
								if (showExternalRecipients) $('#task_editor_ext_recip_group').show();
								$('#task_editor_email_group').show();
							} else {
								$('#task_editor_user_group').show();
								$('#task_editor_ext_recip_group').hide();
								$('#task_editor_email_group').hide();
							}

							task_editor_show_assessment(SURVEY_TYPE_IR, res.inherent_risk, is_external);
							task_editor_show_assessment(SURVEY_TYPE_DD, res.due_diligence, is_external);
							task_editor_show_assessment(SURVEY_TYPE_PR, res.periodic_review, is_external);
						})
						.trigger('change');

					$('#task_editor_ext_recip')
						.off('change')
						.change(function (event) {
							let is_custom = !$(event.target).val();
							if (is_custom) $('#task_editor_email_group').show();
							else $('#task_editor_email_group').hide();
						});

					if (res.reqdocs && res.reqdocs.length) {
						$('#task_editor_col_rd').show();
						res.reqdocs.forEach(function (doct) {
							let option = '<option value="' + doct.doct_id + '">' + doct.doct_name + '</option>';
							$('#task_editor_rd').append(option);
						});
						$('#task_editor_rd').trigger('chosen:updated');
					} else {
						$('#task_editor_rd').val('');
						$('#task_editor_col_rd').hide();
					}
				} catch (error) {
					displayNotification('Request Task', 'Could not load tasks.', 'danger');
					logerror('request task load', error);
				}
			});

			$('#task_editor_user').chosen({ width: '100%' });
			$('#task_editor_ext_recip').chosen({ width: '100%' });
			$('#task_editor_notify').chosen({ width: '100%' });
			$('#task_editor_ir,#task_editor_dd,#task_editor_pr,#task_editor_rd').chosen({ width: '100%' });

			$('#vendor_form_print').show();
			$('#vendor_form_print').off('click');
			$('#vendor_form_print').on('click', function () {
				// Get the Vendor name to set the title
				let $vendname = $('#vendor_form_title').clone();
				$vendname.find('small').remove();
				let title = $vendname.text() + ' - Details';
				let config = {
					title: title,
					callback: function () {
						let $target = $('#tab-details');
						let $body = $target.clone();

						$body.find('input').each(function () {
							$(this).attr('value', $(this).val().toString());
						});
						$target.find('select').each(function () {
							let $t = $body.find('#' + $(this).attr('id'));
							$t.val($(this).val());
							$t.find('option:selected').attr('selected', 'selected');
						});
						$body.find('textarea').each(function () {
							$(this).html($(this).val().toString());
						});

						$body.find('#vendor_form_print').parent().remove(); // remove the button set
						$body.find('>h3').html(title); // set the title

						$body.append($('#copyright_footer').clone().css('text-align', 'center')); //add the footer

						return $body;
					},
				};
				printThis(config);
				/*post('/printout/vendor', {
					vend_id: vendor.id
				},
				'_blank');*/
				ajaxPromise('/form/submit', { type: 'vendor_print', data: { vend_id: vendor.id } }).catch((error) => {
					logerror('vendor print', error);
				});

				return false;
			});

			$('#vendor_form_hierarchy_print')
				.off('click')
				.on('click', () => {
					const $vendname = $('#vendor_form_title').clone();
					$vendname.find('small').remove();
					const vendname = $vendname.text();
					const title = vendname + ' - Hierarchy';
					const config = {
						title: title,
						callback: () => {
							const $target = $('#tab-details');
							const $body = $target.clone();
							$body.find('#vendor_form_details_text').text(vendname);
							const $row = $body.find('#vendor_form_hierarchy_notes_row');
							$row.find('#vendor_form_notes_col').remove();
							$row.find('#vendor_form_hierarchy_toggle').remove();
							$row.find('#vendor_form_hierarchy_print').remove();
							const $hierarchy = $row.find('#vendor_form_hierarchy');
							$hierarchy.show();
							$hierarchy.find('#vendor_form_hierarchy_view_row').remove();
							$hierarchy.find('#vendor_form_hierarchy_org').remove();
							$hierarchy.find('#vendor_form_hierarchy_list').show();
							$hierarchy.find('.3pt-toggle-show').show();
							$hierarchy.find('.3pt-toggle-hide').hide();
							$hierarchy.find('.vendor_form_hierarchy_section').show();
							$hierarchy.find('.vendor_form_hierarchy_protagonist').css({ 'font-weight': 'bold' });
							$body.find('#vendor_form').empty().append($row);

							$body.find('input').each((_, element) => {
								$(element).attr('value', $(element).val().toString());
							});
							$target.find('select').each((_, element) => {
								const $t = $body.find('#' + $(element).attr('id'));
								$t.val($(element).val());
								$t.find('option:selected').attr('selected', 'selected');
							});
							$body.find('textarea').each((_, element) => {
								$(element).html($(element).val().toString());
							});

							$body.find('#vendor_form_print').parent().remove(); // remove the button set
							$body.find('>h3').html(title); // set the title

							$body.append($('#copyright_footer').clone().css('text-align', 'center')); //add the footer

							return $body;
						},
					};
					printThis(config);
					ajaxPromise('/form/submit', { type: 'vendor_hierarchy_print', data: { vend_id: vendor.id } }).catch((error) => {
						logerror('vendor hierarchy print', error);
					});
					return false;
				});

			// Vendor Save (UPDATE)
		}
		$('#vendor_form_panel').show();

		const clearform = () => {
			vendor = {
				id: '',
				status: VENDOR_ACTIVE,
				readonly: 0,
			};
			$('#vendor_form_body :input, #contact_form_body :input').val('');
			$('#vendor_form_body :input.chosen').trigger('chosen:updated');
			$('#vendor_form_status').val(VENDOR_ACTIVE);
			$('#vendor_form_category').trigger('change');
			$('#vendor_form_critical').prop('checked', false).trigger('change');
			$('#vendor_form_noncontract').prop('checked', false).trigger('change');
		}

		//inputFloatMinMax($('#vendor_form_revenue'),0,999999999999999);

		$('#vendor_form_revenue').number(true, 2);
		inputFloatMinMax($('#vendor_form_marketshare'), 0, 100);

		$('#vendor_form_cancel').off('click').on('click', () => {
			clearform();
			window.location.href = '/ui';
		});

		$('#vendor_form_website').on('blur', ({ target }) => {
			const url = $(target).val().toString();
			if (url.length > 0 && !validUrl(url)) {
				displayNotification({
					context: 'Warning',
					msg: 'The website URL given is not valid. (starts with "http://" or "https://")',
					style: 'warning',
					time: 6000,
				});
			}
		});

		toggleSlide($('#vendor_form_hierarchy_toggle'), $('#vendor_form_hierarchy'));

		if ($('#vendor_form_permissions_container').length == 1) {
			toggleSlide($('#vendor_form_permissions_toggle'), $('#vendor_form_permissions_container'));

			$('#vendor_form_permissions_toggle').find('i').addClass('fa-caret-down').removeClass('fa-caret-up');
			$('#vendor_form_permissions_container').hide();

			let permissionsDt: DataTables.Api;
			$(window).on('resize', async () => {
				if (!permissionsDt) return;
				await setTimeoutPromise(0);
				permissionsDt.fixedColumns().relayout()
			});

			$('#vendor_form_permissions_container')
				.off('3pt.toggle.show')
				.one('3pt.toggle.show', () => {
					const roleColumnsMap = {
						role_details_access: ACCESS_DETAILS,
						role_inherent_access: ACCESS_INHERENT,
						role_diligence_access: ACCESS_DILIGENCE,
						role_review_access: ACCESS_REVIEW,
						role_vendorvalue_access: ACCESS_VALUE,
						role_contracts_access: ACCESS_CONTRACTS,
						role_performance_access: ACCESS_ASSESSMENT,
						role_documents_access: ACCESS_DOCUMENTS,
					};
					const roleColumns = Object.keys(roleColumnsMap);

					const roleControl = (col, value) => `
						<select class="role-col form-control btn-success" data-col="${col}">
							<option value="${PRIV_NONE}">None</option>
							<option value="${PRIV_READ}" ${value == PRIV_READ ? 'selected' : ''}>Read</option>
							${col == 'role_documents_access' ? `<option value="${PRIV_WRITE}" ${value == PRIV_WRITE ? 'selected' : ''}>Write</option>` : ''}
							<option value="${PRIV_MANAGE}" ${value == PRIV_MANAGE ? 'selected' : ''}>Manage</option>
						</select>
					`;
					const notifyControl = (col, value) => `
						<select class="${col} form-control btn-success" data-col="${col}">
							<option value="0">No</option>
							<option value="1" ${value == 1 ? 'selected' : ''}>Yes</option>
						</select>
					`;

					// https://datatables.net/manual/data/orthogonal-data
					const dtColumns = [
						{
							title: 'User',
							data: 'usr_name',
							className: 'pt-col-divider-end',
							render: (val) => htmlEsc(val),
						},
						{
							title: 'Relationship Owner',
							data: 'vrel_rank',
							render: (value, type) => {
								if (type === 'display') {
									return `
									<select class="vrel_rank form-control btn-success" data-col="vrel_rank">
										<option value="${RELOWNER_NONE}">No</option>
										<option value="${RELOWNER_ANALYST}" ${value == RELOWNER_ANALYST ? 'selected' : ''}>Analyst</option>
										<option value="${RELOWNER_SECONDARY}" ${value == RELOWNER_SECONDARY ? 'selected' : ''}>Secondary</option>
										<option value="${RELOWNER_PRIMARY}" ${value == RELOWNER_PRIMARY ? 'selected' : ''}>Primary</option>
									</select>
								`;
								}
								if (type === 'sort') {
									const rank_sorting_values = [null, RELOWNER_NONE, RELOWNER_ANALYST, RELOWNER_SECONDARY, RELOWNER_PRIMARY];
									const sort_value = rank_sorting_values.indexOf(+value);
									return sort_value;
								}
								return value;
							},
							searchable: false,
						},
						{
							title: 'Access/Details',
							data: 'role_details_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_details_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Inherent Risk',
							data: 'role_inherent_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_inherent_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Due Diligence',
							data: 'role_diligence_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_diligence_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Periodic Review',
							data: 'role_review_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_review_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Vendor Value',
							data: 'role_vendorvalue_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_vendorvalue_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Contracts',
							data: 'role_contracts_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_contracts_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'SLA / KPI',
							data: 'role_performance_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_performance_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Documents',
							data: 'role_documents_access',
							render: (value, type) => {
								if (type === 'display') return roleControl('role_documents_access', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Notify Reviews',
							data: 'vrel_reviews',
							className: 'pt-col-divider-start',
							render: (value, type) => {
								if (type === 'display') return notifyControl('vrel_reviews', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Notify Contracts',
							data: 'vrel_contracts',
							render: (value, type) => {
								if (type === 'display') return notifyControl('vrel_contracts', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Notify Documents',
							data: 'vrel_documents',
							render: (value, type) => {
								if (type === 'display') return notifyControl('vrel_documents', value);
								return value;
							},
							searchable: false,
						},
						{
							title: 'Has Value',
							data: 'has_value',
							visible: false,
							searchable: false,
						},
					];
					const dtColIndicies = dtColumnIndicies(dtColumns);
					const dtOptions: DataTables.Settings = {
						ajax: {
							url: '/data/vendor_permissions_load',
							data: {
								vend_id: vendor.id,
							},
						},
						buttons: [
							{
								text: '<i class="fa fa-file-csv me-2"></i> Export',
								className: 'ml-2 btn btn-sm btn-secondary',
								action: ({target}) => {
									$(target).prop('disabled', true);
									post('/export', { type: 'vendor_user_permissions', data: vendor.id }, '_blank');
									setTimeout(() => $(target).prop('disabled', false), 1000);
								},
							},
						],
						columns: dtColumns,
						drawCallback: () => triggerWindowResize(),
						fixedColumns: true,
						initComplete: () => {
							const $scroll = $('#vendor_form_permissions_container').find('.dataTables_scroll');
							$scroll.find('.dataTables_scrollBody').doubleScroll({ $insertBefore: $scroll });
						},
						language: {
							info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> users",
							infoEmpty: 'No users to show',
							infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total users)",
							lengthMenu: 'Show _MENU_ users',
						},
						order: [
							[dtColIndicies.has_value, 'desc'],
							[dtColIndicies.usr_name, 'asc'],
						],
						responsive: false,
						scrollX: true,
						deferLoading: 1,
					};

					const isRoleCol = (col) => roleColumns.includes(col);

					const setColorPriv = ($select: $) => {
						$select.removeClass('btn-success btn-warning btn-info');
						const value = +$select.val();
						if (isRoleCol($select.data('col'))) {
							const classes = {
								[PRIV_NONE]: '',
								[PRIV_READ]: 'btn-warning',
								[PRIV_WRITE]: 'btn-info',
								[PRIV_MANAGE]: 'btn-success',
							};
							const classForPriv = classes[value];
							$select.addClass(classForPriv);
						} else if (value > 0) $select.addClass('btn-success');
					};

					const selectChange = async ($select: $) => {
						setColorPriv($select);

						const data = getDtRowData(permissionsDt, $select.get(0));
						const col = $select.data('col');
						const value = +$select.val();

						if (data.is_self && col === 'vrel_rank' && value === RELOWNER_NONE) {
							const confirmed = await confirmDialog({
								dialogTitle: 'Remove Own Permissions',
								bodyText: 'Are you sure you would like to remove yourself as a Relationship Owner for this vendor?',
								confirmText: 'Confirm',
								confirmStyle: 'primary',
							});
							if (!confirmed) {
								$select.val(data.vrel_rank);
								setColorPriv($select);
								return;
							}
						}

						if (data.is_self && isRoleCol(col)) {
							const title = dtColumns.find(({ data }) => data === col).title;
							const privText = {
								[PRIV_NONE]: 'None',
								[PRIV_READ]: 'Read',
								[PRIV_WRITE]: 'Write',
								[PRIV_MANAGE]: 'Manage',
							}[value];
							const confirmed = await confirmDialog({
								dialogTitle: 'Lower Own Permissions',
								bodyText: `Are you sure you would like to lower your <b>${title}</b> permissions to <b>${privText}</b> for this vendor?`,
								confirmText: 'Confirm',
								confirmStyle: 'primary',
							});
							if (!confirmed) {
								$select.val(data[col]);
								setColorPriv($select);
								return;
							}
						}


						$('#spinner').show();

						if (isRoleCol(col)) {
							const postData = {
								vend_id: vendor.id,
								usr_id: data.usr_id,
								role_id: data.role_id,
								privilege_key: roleColumnsMap[col],
								privilege_value: value,
								from_vendor_edit: 1,
								update_all: 0,
							};

							try {
								const res = await ajaxPromise('/form/submit', {
									type: 'user_permissions_save',
									data: postData,
								});
								if (res.rc !== 'OK') throw res;

								if (res.reload) {
									if (res.vend_priv == PRIV_NONE) window.location.replace('/ui');
									else loadVendorEdit({vend_id: vendor.id});
								}

								displayNotification('Permissions Success', 'Permission changed successfully.', 'success');
								permissionsDt.ajax.reload();
							} catch (error) {
								displayNotification('Permissions Error', 'There was an error changing the permission.', 'danger');
								logerror('vendor permissions submit', error);
							}
						} else {
							const postData = {
								vendor_id: vendor.id,
								user_id: data.usr_id,
								[col]: value,
								from_vendor_edit: 1,
							};

							try {
								const res = await ajaxPromise('/form/submit', {
									type: 'user_vendor_add',
									data: postData,
								});
								if (res.rc !== 'OK') throw res;

								if (res.reload) {
									if (res.vend_priv == PRIV_NONE) window.location.replace('/ui');
									else loadVendorEdit({vend_id: vendor.id});
								}

								permissionsDt.ajax.reload();
								if (res.relowners) vendorDisplayRelowners(res.relowners);
								if (col === 'vrel_rank' && value == RELOWNER_PRIMARY && res.old_primary_usr_name) {
									displayNotification({
										context: 'Relationship Owners',
										msg: `Primary Relationship Owner moved from <b>${res.old_primary_usr_name}</b> to <b>${data.usr_name}</b>.`,
										style: 'success',
										time: 10000,
									});
								} else displayNotification('Permissions Success', 'Permission changed successfully.', 'success');
							} catch (error) {
								displayNotification('Permissions Error', 'There was an error changing the permission.', 'danger');
								logerror('vendor permissions submit', error);
							}
						}

						$('#spinner').hide();
					};

					const selectSetAllChange = async ($select: $) => {
						setColorPriv($select);

						if ($select.val() === '') return;

						const col = $select.data('col');
						const value = +$select.val();

						const resetSelect = () => {
							$select.val('');
							setColorPriv($select);
						};

						if (isRoleCol(col)) {
							const postData = {
								vend_id: vendor.id,
								role_id: null,
								usr_id: null,
								privilege_key: roleColumnsMap[col],
								privilege_value: value,
								from_vendor_edit: 1,
								update_all: 'vendor',
							};

							const title = dtColumns.find(({ data }) => data === col).title;
							const allPrivTexts = {
								[PRIV_NONE]: 'None',
								[PRIV_READ]: 'Read',
								[PRIV_WRITE]: 'Write',
								[PRIV_MANAGE]: 'Manage',
							};
							const privText = allPrivTexts[value];

							const confirmed = await confirmDialog({
								dialogTitle: `Change ${title} Permissions`,
								bodyText: `Are you sure you would like to change all <b>${title}</b> permissions to <b>${privText}</b>?`,
								confirmText: 'Save',
								confirmStyle: 'success',
							});
							if (!confirmed) {
								resetSelect();
								return;
							}

							try {
								const res = await ajaxPromise('/form/submit', {
									type: 'user_permissions_save',
									data: postData,
								});
								if (res.rc !== 'OK') throw res;

								if (res.reload) {
									if (res.vend_priv == PRIV_NONE) window.location.replace('/ui');
									else loadVendorEdit({vend_id: vendor.id});
								}

								displayNotification('Permissions Success', 'All permissions for this vendor have been changed successfully.', 'success');
								permissionsDt.ajax.reload();
							} catch (error) {
								resetSelect();
								displayNotification('Permissions Error', 'There was an error changing the permissions.', 'danger');
								logerror('vendor set all permissions submit', error);
							}
						} else {
							const postData = {
								user_id: 'all',
								vendor_id: vendor.id,
								from_vendor_edit: 1,
								[col]: value,
							};

							const action = value ? 'add' : 'remove';
							const actionCapitalized = action.charAt(0).toUpperCase() + action.slice(1);
							const actionClass = value ? 'success' : 'danger';

							if (col == 'vrel_rank') {
								const confirmed = await confirmDialog({
									dialogTitle: `${actionCapitalized} Relationship Owner`,
									bodyText: `Are you sure you would like to ${action} these ownerships?`,
									confirmText: actionCapitalized,
									confirmStyle: actionClass,
								});
								if (!confirmed) {
									resetSelect();
									return;
								}
							} else {
								const confirmed = await confirmDialog({
									dialogTitle: `${actionCapitalized} Notifications`,
									bodyText: `Are you sure you would like to ${action} these notifications?`,
									confirmText: actionCapitalized,
									confirmStyle: actionClass,
								});
								if (!confirmed) {
									resetSelect();
									return;
								}
							}

							try {
								const res = await ajaxPromise('/form/submit', {
									type: 'user_vendor_add',
									data: postData,
								});
								if (res.rc !== 'OK') throw res;

								if (res.reload) {
									if (res.vend_priv == PRIV_NONE) window.location.replace('/ui');
									else loadVendorEdit({vend_id: vendor.id});
								}

								displayNotification('Permissions Success', 'All permissions for this vendor have been changed successfully.', 'success');
								if (res.relowners) vendorDisplayRelowners(res.relowners);
								permissionsDt.ajax.reload();
							} catch (error) {
								resetSelect();
								displayNotification('Permissions Error', 'There was an error changing the permissions.', 'danger');
								logerror('vendor set all permissions submit', error);
							}
						}
					};

					if (permissionsDt) permissionsDt.destroy();
					permissionsDt = $('#vendor_form_permissions_table').DataTable(dtOptions);

					const lockSelect = (_, element: HTMLSelectElement) => {
						const $select = $(element);
						const bgColor = $select.css('background-color');
						$select
							.css({ cursor: 'not-allowed', 'background-color': `${bgColor} !important`, opacity: 0.7 })
							.addClass('perm_locked')
							.attr('disabled', 'disabled');
					};
					$('#vendor_form_permissions_table').off('draw.dt').on('draw.dt', () => {
						$('#vendor_form_permissions_table select, #vendor_form_permissions_table_select_all_row select').each((_, element) => setColorPriv($(element)));
						$('#vendor_form_permissions_table_select_all_row')
							.find('select')
							.off('change')
							.on('change', ({ target }) => selectSetAllChange($(target)));
						permissionsDt
							.rows()
							.eq(0)
							.each((index) => {
								const row = permissionsDt.row(index);
								const data = row.data();
								if (data.vrel_rank && data.vrel_rank != RELOWNER_NONE) {
									const $row = $(row.node());
									$row.find('select.role-col').each(lockSelect);
								}
							});
						const ownRoles = permissionsDt.ajax.json().own_roles;
						if (ownRoles) {
							roleColumns.forEach((roleCol) => {
								const role = ownRoles[roleCol];
								if (role == PRIV_MANAGE) return;
								$('#vendor_form_permissions_table_select_all_row').find(`select[data-col="${roleCol}"]`).off('change').attr('disabled', 'disabled');
								const dtCol = permissionsDt.column((col) => permissionsDt.column(col).dataSrc() === roleCol);
								dtCol.nodes().to$().find('select').each(lockSelect);
							});
						}
						const ownRelowner = permissionsDt.ajax.json().own_relowner;
						if (ownRelowner !== undefined && ownRelowner == RELOWNER_NONE) {
							$('#vendor_form_permissions_table_select_all_row').find('select[data-col="vrel_rank"]').off('change').attr('disabled', 'disabled');
							const dtCol = permissionsDt.column((col) => permissionsDt.column(col).dataSrc() === 'vrel_rank');
							dtCol.nodes().to$().find('select').each(lockSelect);
						}
						$('#vendor_form_permissions_table')
							.find('select')
							.not('.perm_locked')
							.off('change')
							.on('change', ({target}) => selectChange($(target)));
					});
				});
		}

		$('#vendor_form_fourthparty_add')
			.off('click')
			.on('click', (event) => {
				$('#fourthparty_add_container').modal('show');
				event.stopPropagation();
			});

		if ($('#fourthparty_add_container').length == 1) {
			const closeFourthPartyModal = () => {
				$('#fourthparty_add_name').val('');
				$('#fourthparty_add_container').modal('hide');
			};
			$('#fourthparty_add_cancel')
				.off('click')
				.on('click', (event) => {
					closeFourthPartyModal();
					event.preventDefault();
				});

			$('#fourthparty_add_submit').off('click').on('click', async (event) => {
				event.preventDefault();

				const name = $('#fourthparty_add_name').val().toString();
				if (name == '') {
					displayNotification('Fourth Party', 'Please enter a valid fourth party.', 'danger');
					return;
				}

				$('#fourthparty_add_submit').prop('disabled', true);

				try {
					const postData = {
						vend_id: vendor.id,
						id: 0,
						name: $('#fourthparty_add_name').val(),
					};
					const res = await ajaxPromise('/form/submit', { type: 'fourthparty_save', data: postData });
					if (res.rc !== 'OK') throw res;

					displayNotification('Fourth Party', 'The fourth party was saved successfully.', 'success');
					$('#vendor_form_fourthparty').prepend(`<option value="${res.id}" selected>${res.name}</option>`).trigger('chosen:updated');
					closeFourthPartyModal();
				} catch (error) {
					switch (error.rc) {
						case 'DUPLICATE_EXISTS':
							displayNotification('Fourth Party', 'That fourth party already exists.', 'danger');
							break;
						default:
							displayNotification('Fourth Party', 'There was an error saving this fourth party.', 'danger');
							logerror('fourthparty add submit', error);
							break;
					}
				}

				$('#fourthparty_add_submit').prop('disabled', false)
			});
		}

		const vendorSave = () => new Promise(async (resolve: (data: any) => void) => {
			let bad = false;
			const data: any = {
				id: +vendor.id || null,
				name: $('#vendor_form_name').val().toString().trim(),
				category: +($('#vendor_form_category')[0] as HTMLSelectElement).value || null,
				description: $('#vendor_form_description').val().toString().trim(),
				idnumber: $('#vendor_form_idnumber').val().toString().trim(),
				address1: $('#vendor_form_addr1').val().toString().trim(),
				address2: $('#vendor_form_addr2').val().toString().trim(),
				city: $('#vendor_form_city').val().toString().trim(),
				state: $('#vendor_form_state').val(),
				zip: $('#vendor_form_zip').val().toString().trim(),
				country: $('#vendor_form_country').val(),
				phone: $('#vendor_form_phone').val().toString().trim(),
				mainfax: $('#vendor_form_fax').val().toString().trim(),
				critical: $('#vendor_form_critical').is(':checked') ? 1 : 0,
				noncontract: $('#vendor_form_noncontract').is(':checked') ? 1 : 0,
				website: $('#vendor_form_website').val().toString().trim(),
				revenue: +($('#vendor_form_revenue').val() * 100),
				marketshare: +$('#vendor_form_marketshare').val(),
				notes: $('#vendor_form_notes').val().toString().trim(),
				taxid: $('#vendor_form_taxid').val().toString().trim(),
				offices: $('#vendor_form_offices').val(),
				office_countries: $('#vendor_form_office_countries').val(),
				attributes: $('#vendor_form_attributes').val().map((attr) => +attr),
				parentid: +$('#vendor_form_parent').val() || null,
				child_ids: $('#vendor_form_children').val().map((child) => +child),
				businessunits: $('#vendor_form_businessunit').val().map((bu) => +bu),
				fourthparty: $('#vendor_form_fourthparty').val().map((fp) => +fp),
			};

			if (isNewVendor) {
				data.vend_status = +$('#vendor_form_status').val();
				data.group = $('#vendor_form_group').val();
			}

			if (data.name.length == 0) {
				bad = true;
				displayNotification('Save Error', 'Please fill out the vendor name.', 'danger');
			}
			if (data.category === null) {
				bad = true;
				displayNotification('Save Error', 'Please choose a vendor category.', 'danger');
			}

			if (bad) return;

			if (!isNewVendor && vendor.hasOwnProperty('category') && data.category != vendor.category) {
				$('#category_change_notice').modal();
				$('#category_change_notice_confirm').off('click').on('click', async () => {
					$('#category_change_notice').modal('hide');

					const res = await vendorSaveSend(data);
					if (!res) return;
					if (categoryreqdocdt !== null) {
						categoryreqdocdt.ajax.reload();
					}
					if (doctDt !== null) {
						doctDt.ajax.reload();
						loadTaskDescs();
					}
					resolve(res);
				});
			}

			const res = await vendorSaveSend(data);
			if (res) resolve(res);
		});

		const vendorSaveSend = async (data) => {
			$('#vendor_form_submit').prop('disabled', true);
			$('#vendor_form_spinner').show();

			try {
				const postData = {
					type: 'vendor_save',
					data: JSON.stringify(data),
					json: true,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;
				displayNotification('Vendor Save', 'Vendor info saved.', 'success');
				vendor = {...vendor, ...data};
				return res;
			} catch (error) {
				switch (error.rc) {
					case 'IDNUMBER_REQUIRED':
						displayNotification('Vendor Save', 'Please enter a Vendor ID Number.', 'danger');
						break;
					case 'DUPLICATE_IDNUMBER':
						displayNotification('Vendor Save', 'Please enter a Vendor ID Number not already in use.', 'danger');
						break;
					case 'CIRCULAR_REFERENCES':
						displayNotification('Vendor Save', 'Parent vendor and child vendors changes result in a circular relationship.', 'danger');
						break;
					default:
						displayNotification('Vendor Save', 'There was an error saving vendor info.', 'danger');
						logerror('vendor submit', error);
						break;
				}
			} finally {
				$('#vendor_form_submit').prop('disabled', false);
				$('#vendor_form_spinner').hide();
			}
		};

	} else {
		//Select first tab
		$('#vendor_form_tabs').children().first().find('a').trigger('click');
	}

	// Watch List
	if ($('#tab-watch-list').length == 1) {
		let watchDt: DataTables.Api = null;

		const loadWatchDt = () => {

			const clearWatchForm = () => {
				$('#watch_form').trigger('reset');
				$('#watch_form_notify').trigger('chosen:updated').trigger('change');
				$('#watch_form_delete').off('click').hide();
			};

			const closeWatchForm = () => {
				clearWatchForm();
				$('#watch_form_cont').slideUp(350);
			};

			const watchForm = ({type, target_guid = null, watch = null}: {type: number, target_guid?: string, watch?: any}) => {
				clearWatchForm();
				$('#watch_form_cont').slideDown(350);
				setTimeout(() => {
					highlight($('#watch_form_cont'));
					$(window).scrollTop($('#watch_form_cont').offset().top - 400);
				}, 400);

				$('#watch_form_reason').find('option').hide();
				$('#watch_form_reason').find(`option[data-type="${type}"]`).show().first().prop('selected', true);
				$('#watch_form_reason_label').text(type == 0 ? 'Reason' : 'Reason for removel');

				if (watch) {
					$('#watch_form_reason').val(watch.reason_guid);
					$('#watch_form_priority').val(watch.wprior_id);
					$('#watch_form_description').val(watch.watch_desc);
					$('#watch_form_notify').val(watch.watch_notify.split(',')).trigger('chosen:updated').trigger('change');
					$('#watch_form_notify_custom').val(watch.watch_notify_custom);

					if (type == 1) {
						$('#watch_form_delete').show().on('click', async () => {
							const confirmed = await confirmDialog({
								dialogTitle: 'Remove Watch List Entry',
								bodyText: 'Are you sure you want to remove this watch list entry?',
								confirmText: 'Remove',
								confirmStyle: 'danger',
							});
							if (!confirmed) return;

							try {
								const postData = {
									type: 'watch_delete',
									data: watch.watch_guid,
								};
								const res = await ajaxPromise('/form/submit', postData);
								if (res.rc !== 'OK') throw res;
								displayNotification('Watch List', 'The watch list entry was removed successfully.', 'success');
								closeWatchForm();
								getWatchStatus();
								watchDt.ajax.reload();
							} catch (error) {
								displayNotification('Watch List', 'There was an error removing the watch list entry.', 'danger');
								logerror('watch form delete', error);
							}
						});	
					}
				}

				$('#watch_form').off('submit').on('submit', async (event) => {
					event.preventDefault();

					$('#watch_form_submit').prop('disabled', true);

					try {
						const data = {
							vend_id: +vendor.id,
							watch_guid: watch ? watch.watch_guid : null,
							target_guid,
							type,
							reason_guid: $('#watch_form_reason').val(),
							wprior_id: +$('#watch_form_priority').val(),
							desc: $('#watch_form_description').val(),
							notify: $('#watch_form_notify').val(),
							notify_custom: $('#watch_form_notify_custom').val(),
						};
						const postData = {
							type: 'watch_save',
							data: JSON.stringify(data),
							json: true,
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;
						displayNotification('Watch List', 'The watch list update was saved successfully.', 'success');
						closeWatchForm();
						getWatchStatus();
						watchDt.ajax.reload();

					} catch (error) {
						switch (error.rc) {
							case 'BAD_EMAIL':
								displayNotification('Watch List', `The following email address is invalid: "${error.email}".  Remember to separate multiple email addresses with commas.`, 'danger');
								break;
							default:
								displayNotification('Watch List', 'There was an error submitting the watch list update.', 'danger');
								logerror('watch form submit', error);
								break;
						}
					}

					$('#watch_form_submit').prop('disabled', false);
				});
			};

			$('#watch_form_notify').chosen({ width: '100%' }).on('change', () => {
				const notify = $('#watch_form_notify').val();
				if (notify.includes('other')) $('#watch_form_notify_custom_cont').show();
				else $('#watch_form_notify_custom_cont').hide();
			});

			$('#watch_form_cancel').off('click').on('click', () => closeWatchForm());

			const watchDtOptions: DataTables.Settings = {
				ajax: {
					url: '/data/watch_list_load',
					data: (postData) => ({
						...postData,
						vend_id: vendor.id,
					}),
				},
				buttons: vendor.readonly ? [] : [
					{
						text: '<i class="fa fa-plus me-2"></i> Add Vendor to Watch List',
						className: 'ml-2 btn btn-sm btn-success',
						action: () => {
							watchForm({type: 0});
						},
					},
				],
				columns: [
					{
						title: 'Type',
						data: 'watch_type_pretty',
					},
					{
						title: 'Reason',
						data: 'reason_title',
					},
					{
						title: 'Priority',
						data: 'wprior_name',
					},
					{
						title: 'Done By',
						data: 'usr_name',
					},
					{
						title: 'Date',
						data: 'watch_date_pretty',
					},
					{
						title: 'Actions',
						data: 'actions',
						className: 'text-right',
						visible: !vendor.readonly,
						orderable: false,
						searchable: false,
						render: null,
					},
				],
				columnDefs: [
					{ targets: '_all', render: (val) => htmlEsc(val) },
				],
				order: [[4, 'desc']],
			};

			if (watchDt) watchDt.destroy();
			watchDt = $('#watch_table').DataTable(watchDtOptions);

			$('#watch_table').on('click', '.watch_remove', ({ target }) => {
				const row = getDtRowData(watchDt, target);
				watchForm({type: 1, target_guid: row.watch_guid});
			});

			$('#watch_table').on('click', '.watch_edit', ({ target }) => {
				const row = getDtRowData(watchDt, target);
				watchForm({type: +row.watch_type, watch: row});
			});
		};

		const getWatchStatus = async () => {
			if (isNewVendor) return;
			try {
				const res = await ajaxPromise('/data/vendor_watch_list_status', {data: vendor.id});
				if (res.rc !== 'OK') throw res;

				const style = {
					active: 'bg-danger',
					removed: 'bg-success',
					none: '',
				};
				const allClasses = Object.values(style);
				const currentClass = style[res.status];
				$('#tab-link-watch-list').removeClass(allClasses).addClass(currentClass);
			} catch (error) {
				logerror('watch list status', error);
			}
		};

		$('#tab-link-watch-list').on('show.bs.tab', () => {
			if (!watchDt) loadWatchDt();
			else watchDt.ajax.reload();
		});

		getWatchStatus();
	}

	// Transactions
	if ($('#tab-transactions').length == 1) {
		let transDt: DataTables.Api = null;
		const $tableTemplate = $('#trans_table').remove();

		const loadTransDt = () => {
			const transVersion = jsData.transVersions.find((vers) => vers.id === $('#trans_filter_version').val());
			const transDtCols: DataTables.ColumnSettings[] = transVersion.cols.map((col) => {
				const name = `_cc${col.id}`;
				const displayName = col.name;

				return {
					name,
					title: displayName,
					data: name,
					searchable: true,
				};
			});

			const transDtOptions: DataTables.Settings = {
				ajax: {
					url: '/data/trans_load',
					data: (postData) => ({
						...postData,
						vend_id: vendor.id,
						filters: getFilters(),
					}),
				},
				columns: [
					{
						title: 'Date',
						data: 'trans_date_nice',
					},
					...transDtCols,
				],
				order: [[0, 'desc']],
			};

			if (transDt) transDt.destroy();
			$('#trans_table_container').empty().append($tableTemplate.clone());
			transDt = $('#trans_table').DataTable(transDtOptions);
		};

		const getFilters = () => ({
			tvers_id: $('#trans_filter_version').val(),
			trans_date: getDateFilter('#trans_filter_date'),
		});

		const setFilters = (filters) => {
			$('#trans_filter_version').val(filters.tvers_id);
			setDateFilter('#trans_filter_date', filters.trans_date);
			$('#trans_filter_container select.chosen').trigger('chosen:updated');
			checkChosenChanged($('#trans_filter_date'));
		};

		const initFilters = () => {
			$('#trans_filter_container select.chosen').chosen({ width: '100%' });

			toggleSlide($('#trans_filter_toggle'), $('#trans_filter_container'));

			initDateFilter('#trans_filter_date');

			let defaultFilters = getFilters();
			$('#trans_filter_reset').on('click', () => {
				setFilters(defaultFilters);
				loadTransDt();
			});

			$('#trans_filter_version').on('change', (event) => {
				loadTransDt();
				event.stopPropagation();
			});
			$('#trans_filter_date').on('change', () => checkChosenChanged($('#trans_filter_date')));
			$('#trans_filter_container').on('change', 'input, select', () => transDt.ajax.reload());
		}

		initFilters();

		$('#tab-link-transactions').on('show.bs.tab', () => {
			if (!transDt) loadTransDt();
			else transDt.ajax.reload();
		});
	}

	if ($('#tab-concentration').length == 1) {

		const loadOpsCenters = async () => {
			const { opscenters } = await ajaxPromise('/data/concentration_risk', {data: JSON.stringify({vend_ids: [+vendor.id]})});
			if (!opscenters.length) {
				$('#concentration_none').show();
				$('#concentration_cont').hide();
				return;
			}
			loadConcentrationMap({opscenters, isVendorEdit: true});
		};

		$('a[href="#tab-concentration"]').on('show.bs.tab', () => loadOpsCenters());

		$('#concentration_refresh').on('click', () => {
			$('#concentration_distance_apart').val(null);
			$('#concentration_cluster').prop('checked', false).trigger('change');
			loadOpsCenters();
		});
	}

	// Documents
	if ($('#tab-documents').length == 1) {
		// Start Required Documents
		const categoryReqDtOptions: DataTables.Settings = {
			ajax: {
				url: '/data/categories_reqdocs_load',
				data: (postData) => ({
					...postData,
					mode: 1,
					params: {
						vendid: vendor.id,
						categoryid: vendor.category,
					},
				}),
			},
			columns: [
				{
					title: 'Document Type',
					data: 'doct_info',
					render: null,
				},
				{
					title: 'Requirement',
					data: 'requirement',
					searchable: false,
				},
				{
					title: 'Days to Expiration',
					data: 'nextdue',
					searchable: false,
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					render: null,
					orderable: false,
					searchable: false,
				},
			],
			columnDefs: [
				{targets: '_all', render: (val) => htmlEsc(val) },
			],
			language: {
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> document types",
				infoEmpty: 'No document types to show',
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total document types)",
				lengthMenu: 'Show _MENU_ document types',
			},
			order: [[0, 'asc']],
			responsive: false,
		};

		categoryreqdocdt = $('#category_form_reqdocs')
			.on('init.dt, draw.dt', function () {
				if (user_is_external && !categoryreqdocdt.data().length) window.location.reload();

				$(this).find('i[data-toggle="popover"]').popover('destroy');
				$(this).find('i[data-toggle="popover"]').popover({
					placement: 'top',
					trigger: 'hover focus',
				});

				$('.document_add_type').off('click');
				$('.document_add_type').on('click', function (e) {
					var data = JSON.parse(JSON.stringify(categoryreqdocdt.row($(this).closest('tr')).data()));
					vendorDocumentEdit({
						id: 0,
						type: data.doct_id,
						doct_name: data.doct_name,
						doct_desc: data.doct_desc,
					});
					e.preventDefault();
				});

				categoryreqdocdt
					.rows()
					.eq(0)
					.each(function (index) {
						let row = categoryreqdocdt.row(index);
						if (row.data().uncompleted_task) $(row.node()).css({ 'background-color': '#f39c12' });
					});
			})
			.DataTable(categoryReqDtOptions);

		const getFilters = () => ({
			vendid: vendor.id,
			type: $('#document_filter_doctype').val(),
			required: $('#document_filter_required').val(),
		});
		const setFilters = (filters) => {
			$('#document_filter_doctype').val(filters.type).trigger('chosen:updated');
			$('#document_filter_required').val(filters.required).trigger('chosen:updated');
			$('#document_filter_container select.chosen').each((_, el) => checkChosenChanged($(el)));
			doctDt.ajax.reload();
		};
		const defaultFilters = getFilters();
		const resetFilters = () => setFilters(defaultFilters);

		$('#document_filter_container select.chosen').on('change', ({target}) => checkChosenChanged($(target)));
		$('#document_filter_reset').on('click', () => resetFilters());

		const doctDtOptions: DataTables.Settings = {
			ajax: {
				url: '/data/documents_load',
				data: (postData) => ({
					...postData,
					filters: getFilters(),
					all_documents: 0,
				}),
			},
			buttons: [
				{
					text: '<i class="fa fa-envelope me-2"></i> Send',
					className: 'send ml-2 btn btn-sm btn-info',
					action: () => {
						const docs = senddableDocs();
						sendDocuments(docs);
					},
				},
			],
			columns: [
				{
					data: null,
					defaultContent: '',
					orderable: false,
					searchable: false,
					className: 'select-checkbox',
				},
				{
					title: 'Title',
					data: 'doc_title',
				},
				{
					title: 'Type',
					data: 'doct_name',
				},
				{
					title: 'Required Document',
					data: 'reqdoc_active',
				},
				{
					title: 'Effective Date',
					data: 'doc_effectivedate',
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
			columnDefs: [
				{ responsivePriority: 1, targets: 0 },
				{ responsivePriority: 2, targets: -1 },
			],
			order: [[4, 'desc']],
			select: {
				style: 'os',
				selector: 'td:first-child',
			},
		};

		doctDt = $('#documents_table')
			.on('init.dt, draw.dt', function () {})
			.DataTable(doctDtOptions);

		const senddableDocs = () => doctDt.rows({ selected: true }).data().toArray().filter((doc) => +doc.doc_external === 0);

		doctDt.on('draw', () => {
			$('#documents_table').find('.select-all').removeClass('selected').off('click').on('click', ({target}) => {
				$(target).toggleClass('selected')
				if ($(target).hasClass('selected')) doctDt.rows().select();
				else doctDt.rows().deselect();
			});
			doctDt.buttons(['.send']).disable();
		});

		doctDt.on('select', () => {
			if (senddableDocs().length) doctDt.buttons(['.send']).enable();
		});

		doctDt.on('deselect', () => {
			if (!senddableDocs().length) doctDt.buttons(['.send']).disable();
		});

		const sendDocuments = (docs) => {
			//Modal 'Send' handler
			$('#document_send_submit').on('click', async (event) => {
				event.preventDefault();

				const emails = $('#document_send_input').val().toString();
				if (!emails) {
					displayNotification('Document Send', 'Please enter an email address.', 'danger');
					return;
				}

				try {
					const postData = {
						type: 'document_send',
						data: {
							ids: docs.map((doc) => +doc.doc_id),
							emails,
							comments: $('#document_send_comments').val().toString(),
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Document Send', 'Document sent succesfully.', 'success');
					$('#document_send_container').modal('hide');
				} catch (error) {
					switch (error.rc) {
						case 'BAD_EMAIL':
							displayNotification('Document Send', `The following email address is invalid: "${error.email}".  Remember to separate multiple email addresses with commas.`, 'danger');
							break;
						default:
							displayNotification('Document Send', 'There was an error sending the document.', 'danger');
							logerror('document send submit', error);
							break;
					}
				}
			});

			//Show modal
			$('#document_send_container').modal('show');
		};

		//Document send modal reset when hidden
		$('#document_send_container').on('hidden.bs.modal', () => {
			$('#document_send_input').val('');
			$('#document_send_comments').val('');
			$('#document_send_submit').off('click');
		});

		$('#documents_table').on('click', '.document_view', ({ target }) => {
			const data = getDtRowData(doctDt, target);
			const $tr = $(event.target).closest('tr');
			window.open(`/document/get/${data.doc_hash}`, '_blank');
		});

		$('#documents_table').on('click', '.document_send', ({ target }) => {
			const doc = getDtRowData(doctDt, target);
			sendDocuments([doc]);
		});

		$('#documents_table').on('click', '.document_edit', ({ target }) => {
			const data = getDtRowData(doctDt, target);
			vendorDocumentEdit({
				id: data.doc_id,
				title: data.doc_title,
				type: data.doc_doctype,
				issuedate: data.doc_issuedate,
				description: data.doc_description,
				external: parseInt(data.doc_external),
				exception: parseInt(data.doc_exception),
				original: data.doc_original,
				content: data.doc_content,
				size: data.doc_size,
			});
		});

		$('#document_filter_container select.chosen').chosen({ width: '100%' });
		toggleSlide($('#document_filter_open'), $('#document_filter_container'));

		$('#document_filter_container select').on('change', function () {
			doctDt.ajax.reload();
		});

		$('#document_add').off('click').on('click', (event) => {
			event.preventDefault();
			vendorDocumentEdit({id: 0});
		});

		$('#document_list_export')
			.off('click')
			.on('click', (event) => {
				event.preventDefault();
				const filters = {
					...getFilters(),
					doc: doctDt.rows({ selected: true }).data().toArray().map((doc) => +doc.doc_id),
					all_documents: 0,
				};

				// Submit the form
				$('#document_list_export').prop('disabled', true);
				post('/export', { type: 'documents', data: JSON.stringify(filters) });
				setTimeout(() => $('#document_list_export').prop('disabled', false), 1000);
			});

		/*
		// This was set up outside of document_edit because the 'change' event cannot be unbound!
		// If I unbind/rebind it inside of document_edit, it would lose the toggle button bind.
		*/
		$('#document_form_file_external').on('change', function () {
			let checked = $(this).is(':checked');
			if (checked) {
				$('#document_form_file_size').css({ visibility: 'hidden' });
				$('#document_form_file').val('').trigger('change').parent().hide();
			} else {
				$('#document_form_file_size').css({ visibility: 'visible' });
				$('#document_form_file').parent().show();
			}
			//logme('external change?');
			checkNotify($('#document_form').data('mode'), !checked, $('#document_form_type').val());
		});

		fileInput($('#document_form_file'));

		$('#document_form_exception').on('change', function () {
			let checked = $(this).is(':checked');
			if (checked) {
				$('#document_form_file_container_both').hide();
			} else {
				$('#document_form_file_container_both').show();
			}
		});

		let reqdocs = user_is_external ? null : JSON.parse(atob($('#document_edit_container').data('reqdocs')));
		let doctypedesc = user_is_external ? null : JSON.parse(atob($('#document_form_type').data('descriptions')));

		const checkNotify = (mode, disp, doct) => {
			// Workflow Email Notification Setting
			let cont = $('#document_form_file_container').is(':visible');
			let cati = ($('#vendor_form_category').get(0) as HTMLSelectElement).value;

			//logme('checkNotify: ' + cont + ', ' +disp+ ', ' +cati+ ', '+doct);
			// Unset all first.
			$('#document_form_email_manual_notice').hide();
			$('#document_form_email_manual_input').val('');

			$('#document_form_email_auto_notice').hide();

			$('#document_form_email_both_notice').hide();
			$('#document_form_email_both_notice_list').html('');
			$('#document_form_email_both_input').val('');

			if (cont && disp && reqdocs.hasOwnProperty(cati) && reqdocs[cati].hasOwnProperty(doct)) {
				//logme(reqdocs[cati][doct].reqdoc_notify);
				switch (parseInt(reqdocs[cati][doct].reqdoc_notify)) {
					case NOTIFY_MANUAL:
						$('#document_form_email_manual_notice').show();
						break;
					case NOTIFY_AUTO:
						if (reqdocs[cati][doct].reqdoc_emails !== null) {
							$('#document_form_email_auto_notice').show();
						}
						break;
					case NOTIFY_MANUAL_AND_AUTO:
						if (reqdocs[cati][doct].reqdoc_emails !== null) {
							const emails = reqdocs[cati][doct].reqdoc_emails.split(',');
							const emailList = (
								<ul>
									{emails.map((email) => <li>{email}</li>)}
								</ul>
							);
							$('#document_form_email_both_notice_list').html(emailList);
						} else {
							$('#document_form_email_both_notice_list').html(<ul><li>No email addresses were defined.</li></ul>);
						}
						$('#document_form_email_both_notice').show();
						break;
				}
			}
		}

		const vendorDocumentEdit = (document) => {
			//logme('document_edit:');
			//logme(document);
			let mode = document.id === 0 ? 0 : 1;
			$('#document_form').data('mode', mode);

			var required = false;

			setTimeout(function () {
				highlight($('#document_edit_container'));
				$(window).scrollTop($('#document_edit_container').offset().top - 400);
			}, 400);

			if (mode === 0) {
				// add
				$('#document_form_notify').off('click').hide();
				$('#document_form_submit').html('Save');
				$('#document_form_title').val('');
				if (user_is_external) {
					$('#document_form_type').text(document.doct_name);
				} else {
					if (document.hasOwnProperty('type')) {
						$('#document_form_type').val(document.type);
						setTimeout(function () {
							checkNotify(0, true, document.type);
						}, 1);
					} else {
						$('#document_form_type').prop('selectedIndex', 0);
					}
				}
				$('#document_form_issuedate').val('');
				$('#document_form_description').val('');

				$('#document_form_file').val('').trigger('change');
				$('#document_form_file_external').bootstrapToggle('off');
				$('#document_form_exception').bootstrapToggle('off');
				$('#document_form_file').prop('required', true);
				$('#document_form_file_cancel').off('click').hide();
				$('#document_form_file_change').off('click').hide();

				$('#document_form_file_stored_container').hide();
				$('#document_form_file_container').show();
				$('#document_form_file_stored_original').html('');
				$('#document_form_file_stored_content').html('');
				$('#document_form_file_stored_size').html('');

				$('#document_form_archive').off('click').hide();

				if (user_is_external) {
					$('#document_form_type_description').html(htmlEsc(document.doct_description) || '<i>No Document Type Description given</i>');
				} else {
					doctype_display_description();
					checkReqDoc();

					$('#document_form_type').off('change');
					$('#document_form_type').on('change', function () {
						doctype_display_description();
						checkReqDoc();
						checkNotify(0, !$('#document_form_file_external').is(':checked'), $(this).val());
					});
				}
			} // edit
			else {
				$('#document_form_submit').html('Update');
				$('#document_form_title').val(document.title);
				$('#document_form_type').val(document.type);
				$('#document_form_issuedate').val(document.issuedate);
				$('#document_form_description').val(document.description);

				document.cati = ($('#vendor_form_category').get(0) as HTMLSelectElement).value;
				let allow_notify = reqdocs.hasOwnProperty(document.cati) && reqdocs[document.cati].hasOwnProperty(document.type) && [1, 3].includes(parseInt(reqdocs[document.cati][document.type].reqdoc_notify));
				logme({ allow_notify, document, reqdocs, cati: document.cati, type: document.type });

				$('#document_form_type').off('change');
				$('#document_form_type').on('change', function () {
					doctype_display_description();
					checkReqDoc();
					checkNotify(1, !$('#document_form_file_external').is(':checked'), $(this).val());
				});

				let isException = document.exception === 1;
				let isExternal = document.external === 1;
				$('#document_form_exception').bootstrapToggle(isException ? 'on' : 'off');
				$('#document_form_file_external').bootstrapToggle(isExternal ? 'on' : 'off');

				setTimeout(function () {
					checkNotify(1, !isExternal, document.type);
				}, 1);

				$('#document_form_file').val('').trigger('change');
				$('#document_form_file').prop('required', false);

				if (isExternal) {
					// is external
					$('#document_form_file_container').show();
					$('#document_form_file_stored_container').hide();
					$('#document_form_file_cancel').off('click').hide();
					$('#document_form_notify').off('click').hide();
				} else {
					// is internal / file is in system
					$('#document_form_file_stored_container').show();
					$('#document_form_file_container').hide();
					$('#document_form_file_stored_original').html(htmlEsc(document.original));
					$('#document_form_file_stored_content').html(htmlEsc(document.content));
					$('#document_form_file_stored_size').html(filesizePretty(+document.size));

					$('#document_form_file_change').off('click');
					$('#document_form_file_change')
						.on('click', function () {
							$('#document_form_file_container').show();
							$('#document_form_file_stored_container').hide();
							checkNotify(1, !$('#document_form_file_external').is(':checked'), $('#document_form_type').val());
							$('#document_form_notify').hide();
						})
						.show();

					$('#document_form_file_cancel').off('click');
					$('#document_form_file_cancel')
						.on('click', function () {
							$('#document_form_file').val('').trigger('change');
							$('#document_form_file_container').hide();
							$('#document_form_file_stored_container').show();
							$('#document_form_file_external').bootstrapToggle(isExternal ? 'on' : 'off');
							checkNotify(1, !$('#document_form_file_external').is(':checked'), $('#document_form_type').val());
							if (allow_notify) {
								// If Manual or Both
								$('#document_form_notify').show();
							}
						})
						.show();

					if (allow_notify) {
						// If Manual or Both
						// modal clear
						$('#document_notify_cancel').off('click');
						$('#document_notify_cancel').on('click', function () {
							$('#document_notify_container').modal('hide');
						});
						$('#document_notify_container').off('hidden.bs.modal');
						$('#document_notify_container').on('hidden.bs.modal', function () {
							$('#document_notify_input').val('');
						});

						$('#document_form_notify').show().off('click').on('click', (event) => {
							event.preventDefault();
							$('#document_notify_target').html(htmlEsc(document.title));
							$('#document_notify_container').modal({ backdrop: 'static' });

							$('#document_notify_submit').off('click').on('click', async (event) => {
								event.preventDefault();

								const $btn = $(event.target);
								$btn.prop('disabled', true);
								$('#spinner').show();

								try {
									const postData = {
										type: 'document_notify',
										data: {
											id: document.id,
											emails: $('#document_notify_input').val(),
										},
									};
									const res = await ajaxPromise('/form/submit', postData);
									if (res.rc !== 'OK') throw res;
									displayNotification('Notification Send', 'The notification has been sent successfully.', 'success');
									$('#document_notify_container').modal('hide');
								} catch (error) {
									displayNotification('Notification Send Error', 'There was an error preparing the notification.', 'danger');
									logerror('document notify submit', error);
								}

								$btn.prop('disabled', false);
								$('#spinner').hide();
							});
						});
					} else {
						$('#document_form_notify').off('click').hide();
					}
				}

				if (isException) {
					//logme('hide file input');
					$('#document_form_file_container_both').hide();
				}

				$('#document_form_archive').show().off('click').on('click', async (event) => {
					event.preventDefault();

					const confirmed = await confirmDialog({
						dialogTitle: 'Document Delete',
						bodyText: 'Are you sure you would like to delete this document?',
						confirmText: 'Delete',
						confirmStyle: 'danger',
					});
					if (!confirmed) return;

					try {
						const postData = {
							type: 'document_archive',
							data: document.id,
						};
						const res = await ajaxPromise('/form/submit', postData);
						if (res.rc !== 'OK') throw res;

						displayNotification('Delete Success', 'The document was deleted successfully.', 'success');
						doctDt.ajax.reload();
						loadTaskDescs();
						closeform();
					} catch (error) {
						displayNotification('Delete Error', 'There was an error deleting this Document.', 'danger');
						logerror('document archive', error);
					}
				});

				doctype_display_description();
				checkReqDoc();
			}
			delete document.doct_name;
			delete document.doct_description;
			$('#document_edit_container').collapse('show');
			$('#document_add').parent().hide();

			function checkReqDoc() {
				// different than the all documents counterpart!
				var doct = $('#document_form_type').val().toString();
				$('#document_form_reqdoc_notice').hide();
				required = false;
				if (reqdocs.hasOwnProperty(vendor.category) && reqdocs[vendor.category].hasOwnProperty(doct) && parseInt(reqdocs[vendor.category][doct].reqdoc_active) > 0) {
					$('#document_form_reqdoc_notice').show();
					required = true;
				}
			}

			function doctype_display_description() {
				let val = $('#document_form_type').val().toString();
				if (doctypedesc.hasOwnProperty(val)) {
					$('#document_form_type_description').html(htmlEsc(doctypedesc[val]));
				} else {
					$('#document_form_type_description').html('<i>No Document Type Description given</i>');
				}
			}

			function pullEmailNotify() {
				let cont = $('#document_form_file_container').is(':visible'),
					cati = ($('#vendor_form_category').get(0) as HTMLSelectElement).value,
					doct = $('#document_form_type').val().toString();

				//logme('pullEmailNotify ('+cont+','+cati+','+doct+')');
				if (cont && reqdocs.hasOwnProperty(cati) && reqdocs[cati].hasOwnProperty(doct)) {
					let notify = parseInt(reqdocs[cati][doct].reqdoc_notify);
					switch (notify) {
						case NOTIFY_MANUAL: // manual
							return $('#document_form_email_manual_input').val();
						case NOTIFY_AUTO: // auto
							return '';
						case NOTIFY_MANUAL_AND_AUTO: // both
							return $('#document_form_email_both_input').val();
						default:
							return '';
					}
				}
				return '';
			}

			function closeform() {
				$('#document_form_title').val('');
				$('#document_form_type').prop('selectedIndex', 0);
				$('#document_form_file').val('').trigger('change');
				$('#document_form_email_manual_input').val('');
				$('#document_form_email_both_input').val('');
				$('#document_edit_container').collapse('hide');
				$('#document_add').parent().show();
				$('#document_form_notify').off('click').hide();
				categoryreqdocdt.ajax.reload();
			}

			$('#document_form_cancel').off('click');
			$('#document_form_cancel').on('click', function () {
				closeform();
				return false;
			});

			$('#document_form_submit')
				.off('click')
				.on('click', () => $('#document_form').trigger('submit'));

			$('#document_form').off('submit').on('submit', async (event) => {
				event.preventDefault();

				const data = structuredClone(document);
				data.title = $('#document_form_title').val();
				if (!user_is_external) data.type = $('#document_form_type').val();
				data.vendid = vendor.id;
				data.issuedate = $('#document_form_issuedate').val();
				data.description = $('#document_form_description').val();
				if (!user_is_external) {
					data.external = $('#document_form_file_external').is(':checked') ? 1 : 0;
					data.exception = $('#document_form_exception').is(':checked') ? 1 : 0;
				} else {
					data.external = 0;
					data.exception = 0;
				}
				data.file = $('#document_form_file').val();
				data.emails = user_is_external ? '' : pullEmailNotify();
				let bad = false;

				if (data.title == null || data.title.length == 0) {
					bad = true;
					displayNotification('Save Error', 'Please fill out the document title.', 'danger');
				}
				if (data.type == null || data.type.length == 0) {
					bad = true;
					displayNotification('Save Error', 'Please choose a document type.', 'danger');
				}
				if (data.issuedate == null || data.issuedate.length == 0) {
					if (required) {
						bad = true;
						displayNotification('Save Error', 'Document is required; Please enter an Effective Date.', 'danger');
					} else if (data.exception) {
						bad = true;
						displayNotification('Save Error', 'Document is an exception; Please enter an Effective Date.', 'danger');
					}
				}
				if (data.exception === 1) {
					data.external = 1;
					data.file = '';
				}
				if (data.external === 0) {
					if ((data.id === 0 || document.external === 1) && (data.file == '' || data.file.length == 0)) {
						bad = true;
						displayNotification('Save Error', 'Please include a file.', 'danger');
					}
					const formFile = $('#document_form_file').get(0) as HTMLInputElement;
					if (data.file.length > 0 && formFile.files[0].size >= 26214400) {
						bad = true;
						displayNotification('Save Error', 'The file chosen exceeds the size limit of 25MB.', 'danger');
					}
				} else {
					data.file = '';
				}

				if (bad) return;

				const formData = new FormData($('#document_form').get(0) as HTMLFormElement);
				formData.set('type', 'document_save');
				formData.set('data', JSON.stringify(data));

				if (data.id !== 0 && data.external === 0 && data.file != null && data.file.length > 0) {
					const confirmed = await confirmDialog({
						dialogTitle: 'Document Replace',
						bodyText: 'Are you sure you would like to replace the file? The original file will be overwritten.',
						confirmText: 'Replace',
						confirmStyle: 'warning',
					});
					if (!confirmed) return;
				} else if (data.id !== 0 && document.external === 0 && data.external === 1) {
					const confirmed = await confirmDialog({
						dialogTitle: 'Document Replace',
						bodyText: 'Are you sure you would like to remove the file? The stored file will be removed and this document will be labeled external.',
						confirmText: 'Replace',
						confirmStyle: 'warning',
					});
					if (!confirmed) return;
				}

				$('#document_form_submit').prop('disabled', true);
				$('#spinner').show();

				try {
					const res = await ajaxPromise('/form/submit', formData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Save Success', 'The document was saved successfully.', 'success');
					doctDt.ajax.reload();
					loadTaskDescs();
					closeform();
				} catch (error) {
					displayNotification('Save Error', 'There was an error saving this document.', 'danger');
					logerror('document submit', error);
				}

				$('#document_form_submit').prop('disabled', false);
				$('#spinner').hide();
			});
		}
	}
}
